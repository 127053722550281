<template>
  <div>
    <img
      class="ml-3.5 appleImg"
      src="@/assets/images/apple-login.png"
      alt="Apple login"
      @click="openAppleSignInPopup"
    />
  </div>
</template>

<script>
/*global AppleID*/
import { defineComponent } from 'vue';
import { AuthorizationApi } from '@/service/AuthorizationApi';

export default defineComponent({
  emits: ["success"],
  methods: {
    async openAppleSignInPopup() {
      AppleID.auth.init({
        clientId: 'com.taykus.marketplace.signin', // Replace with your Apple Client ID
        scope: 'name email', // Define the scopes you need
        redirectURI: window.location.origin, // Replace with your redirect URI
        usePopup: true 
      });

      try {
        const response = await AppleID.auth.signIn();

        const idToken = response.authorization.id_token;
        const code = response.authorization.code;
        let fullname = null;
        if(response.user){
          fullname = response.user.name.firstName + " " + response.user.name.lastName;
        }

        const authenticated = await AuthorizationApi.authenticateWithApple(idToken, code, fullname);
        const isNew = authenticated.data.new;
        this.$emit("success", isNew);
      } catch (error) {
        console.error('Apple Authentication Error:', error);
      }
    },
  }
});
</script>
<style scoped>
  .appleImg {
    cursor: pointer; 
    width: 56px; 
    height: 56px;
  }
</style>

