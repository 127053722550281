import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-56892116"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-light-container backdrop-blur-lg" }
const _hoisted_2 = { class: "modal-light-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["modal-light-popup relative", _ctx.customClass])
    }, [
      (_ctx.showClose)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: "close",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closed($event)))
          }, "×"))
        : _createCommentVNode("", true),
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("main", null, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ])
        ])
      ])
    ], 2)
  ]))
}