<template>
  <div class="custom-select mr-4" @click="toggleDropdown">
    <div class="selected-option">
      <img
        v-if="selectedCountry"
        :src="selectedCountry.flag"
        :alt="selectedCountry.name"
        class="flag-icon"
      />
      <p v-if="selectedCountry">{{ selectedCountry.prefix }}</p>
    </div>
    <ul v-if="isDropdownOpen" class="dropdown-options">
      <li
        v-for="country in countries"
        :key="country.code"
        @click="selectCountry(country)"
      >
        <img :src="country.flag" :alt="country.name" class="flag-icon" />
      </li>
    </ul>
  </div>
</template>

<script>
import countryData from "@/assets/data/countryData.json";
import axios from "axios";
//import { generateAndDownloadFile, getCountryData } from "@/helpers/dataHelper"; //uncomment to regenerate country file

export default {
  name: "PhonePrefix",
  data() {
    return {
      selectedValue: this.value,
      isDropdownOpen: false,
      countries: {},
    };
  },
  methods: {
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    async selectCountry(country) {
      this.selectedValue = country.code;
      this.isDropdownOpen = false;
      this.$emit("selectedPrefix", country.prefix.replace("+", ""));
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    /*
    getCountryCodeFromIP() { //TODO: get spain as default, 
      axios
      .get('http://ip-api.com/json')
      .then((response) => {
        this.selectedValue = response.data.countryCode;
          const preselectedCountry = this.countries[this.selectedValue];

          if (preselectedCountry) {
            this.$emit('selectedPrefix', preselectedCountry.prefix.replace('+', ''));
          } else {
            return '';//User select from blank preselection
          }
        
      })*/ /*
      .catch((error) => {
        console.error('Error fetching IP information', error);
      });*/ //silenced error
    //},
    getDefaultSPAIN() {
      this.selectedValue = "ES";
      const preselectedCountry = this.countries[this.selectedValue];
      this.$emit("selectedPrefix", preselectedCountry.prefix.replace("+", ""));
    },
  },
  computed: {
    selectedCountry() {
      return this.countries[this.selectedValue];
    },
  },
  async mounted() {
    try {
      this.countries = countryData;
      const countryArray = Object.entries(this.countries); // Convert object to array of key-value pairs
      countryArray.sort((a, b) => a[1].name.localeCompare(b[1].name));
      this.countries = Object.fromEntries(countryArray); //Convert the sorted array back to an object

      //generateAndDownloadFile(getCountryData()); //to regenerate countryData file, go to downloaded file and copy all in countryData.json
    } catch (error) {
      console.error("Error fetching country data:", error);
    }
    //this.getCountryCodeFromIP();
    this.getDefaultSPAIN();
  },
};
</script>

<style scoped>
.custom-select {
  position: relative;
  cursor: pointer;
  align-items: center;
}

.selected-option {
  display: flex;
  align-items: center;
  -webkit-backdrop-filter: blur(16px);
  backdrop-filter: blur(16px);
  height: 44px;
  padding: 10px;
  font-size: 14px;
  background: rgba(255, 255, 255, 0.02);
  border: 1px solid;
  border-radius: 24px;
  box-shadow: inset 0px 0px 12px rgba(255, 255, 255, 0.15);
  border-bottom-color: #264b67;
  border-left-color: #264b67;
  border-right-color: #076ae3;
  border-top-color: #264b67;
}

.flag-icon {
  width: 24px;
  height: 18px;
  margin-right: 5px;
}
.dropdown-options {
  max-height: 200px;
  width: fit-content;
  overflow-y: auto;
  -webkit-backdrop-filter: blur(16px);
  backdrop-filter: blur(16px);
  padding-left: 6px;
  font-size: 14px;
  background: rgba(255, 255, 255, 0.02);
  border: 1px solid;
  border-radius: 24px;
  box-shadow: inset 0px 0px 12px rgba(255, 255, 255, 0.15);
  border-bottom-color: #264b67;
  border-left-color: #264b67;
  border-right-color: #076ae3;
  border-top-color: #264b67;
}

.dropdown-options li {
  padding: 6px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dropdown-options img {
  width: 24px;
  height: 18px;
  margin-right: 6px;
}

.dropdown-options li:hover {
  background-color: #6f91da;
}
</style>