<template>
  <main>
    <div class="relative w-full input-component justify-center">
      <SvgContainer name="homeCalendarIcon" v-if="!hiddenInput" />
      <input
        class="w-full input-rounded"
        type="text"
        id="datepicker"
        ref="datepicker"
        :placeholder="$t('date')"
        :hidden="hiddenInput"
      />
    </div>
    <div v-if="!createInClub" class="flex flex-wrap justify-center mt-4">
      <div class="mr-3 sm:w-full font-bold">
        <TimeSelector :initialHour="time" @timeSelected="handleTimeSelected" />
      </div>
      <div class="mt-2">
        <input
          type="checkbox"
          class="cursor-pointer mr-2"
          v-model="isChecked"
          @click="handleFlexibleSelected()"
          :class="{ selected: isChecked }"
        />
        <label
          class="mr-3 text-sm"
          @click="isChecked = !isChecked"
          style="cursor: pointer"
        >
          {{ flexibleStr }}
        </label>
      </div>
    </div>
    <div class="mt-2">
      <input
        class="search-btn sm:box-border sm:w-full sm:flex sm:flex-row sm:justify-center sm:items-center sm:p-0 sm:h-12"
        type="button"
        :value="$t('select')"
        @click="selectDatepick"
      />
    </div>
  </main>
</template>

<script>
import TimeSelector from "@/components/form/inputTimeSelector.vue";
import SvgContainer from "@/views/SvgContainer.vue";
import { formatDateWithLocale } from "@/helpers/dateHelper";
import { setTimeToDate } from "@/helpers/dateHelper";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";
// try more themes at - https://flatpickr.js.org/themes/
import "flatpickr/dist/themes/dark.css";
// Must import to work :S
import { es } from "flatpickr/dist/l10n/es.js";
import { cat } from "flatpickr/dist/l10n/cat.js";
import { FLEXIBLE_HOURS } from "@/enum/constants";

export default {
  name: "DatePicker",
  components: {
    SvgContainer,
    TimeSelector,
  },
  props: {
    initialDate: {},
    initialTime: {},
    hiddenInput: {
      type: Boolean,
      required: false,
    },
    createInClub:{
      type: Boolean, 
      required: false, 
      default: false 
    }
  },
  data() {
    return {
      isChecked: true,
      datepickerInstance: null,
      date: null,
      time: "",
      flexibleStr: null
    };
  },
  emits: ["selectionChanged"],
  created() {
    this.date = this.initialDate;
    this.time = this.initialTime;
  },
  mounted() {
    this.flexibleStr = this.$t("im_flexible") + FLEXIBLE_HOURS + ' ' + this.$t("hours")
    const i18nLocale = this.$store.state.i18nLocale;
    this.datepickerInstance = flatpickr("#datepicker", {
      enableTime: false,
      inline: this.hiddenInput,
      locale: i18nLocale,
      minDate: "today",
      dateFormat: formatDateWithLocale(i18nLocale),
      defaultDate: this.date,
      onChange: this.handleDateSelect,
    });
  },
  methods: {
    handleFlexibleSelected() {
      this.isChecked = !this.isChecked;
      this.selectionChanged();
    },
    handleTimeSelected(time) {
      this.time = time;
      this.selectionChanged();
    },
    handleDateSelect(date) {
      this.date = date[0];
      this.selectionChanged();
    },
    selectionChanged() {
      const dateAndHour = setTimeToDate(this.date, this.time);
      this.$emit("selectionChanged", {
        date: dateAndHour,
        flexible: this.isChecked,
      });
    },
    selectDatepick() {
      this.selectionChanged();
      this.$emit("close");
    },
  },
};
</script>
