import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { SaleApiAxiosParamCreator } from '../../apis/sale-api';
import { BaseApi } from './BaseApi';
import { AuthorizeSaleRequest } from 'models';

export class SaleApi extends BaseApi {

  /**
  * 
  * @summary Authorize the payment of a sale
  * @param {AuthorizeSaleRequest} body 
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
  public static async authorize(body: AuthorizeSaleRequest, options?: AxiosRequestConfig): Promise<AxiosResponse<any>>{
    const axiosArgs = await SaleApiAxiosParamCreator().authorizeSale(body, options);
    
    return this.getAuthorizedAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }

  /**
  * 
  * @summary Get all the customer sales
  * @param {number} start Zero-based page index
  * @param {number} size The page size
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
  public static async findByCustomer(start: number, size: number, options?: AxiosRequestConfig): Promise<AxiosResponse<any>>{
    const axiosArgs = await SaleApiAxiosParamCreator().findSaleByCustomer(start, size, options);
    
    return this.getAuthorizedAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }
  /**
  * 
  * @summary Get a sale by id
  * @param {string} id The sale id
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
  public static async findByHash(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<any>>{
    const axiosArgs = await SaleApiAxiosParamCreator().findSaleById(id, options);
    
    return this.getAuthorizedAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }
  /**
  * 
  * @summary Get all the customer sales
  * @param {number} start Zero-based page index
  * @param {number} size The page size
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
  public static async findSaleByCustomer(start: number, size: number, options?: AxiosRequestConfig): Promise<AxiosResponse<any>>{
    const axiosArgs = await SaleApiAxiosParamCreator().findSaleByCustomer(start, size, options);
    
    return this.getAuthorizedAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }

  /**
  * 
  * @summary Update sale to be retried
  * @param {string} id The sale id
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
  public static async retrySale(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<any>>{
    const axiosArgs = await SaleApiAxiosParamCreator().retrySale(id, options);
      
    return this.getAuthorizedAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }

  /** @summary Get the payment 3DS data
  * @param {string} id 
  * @param {{ [key: string]: string; }} params 
  * @param {string} [userAgent] 
  * @param {string} [accept] 
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
  public static async authorizeSaleWithAuthorization(id: string,  params: { [key: string]: string; }, userAgent?: string, accept?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<any>>{
    const axiosArgs = await SaleApiAxiosParamCreator().authorizeSaleWithAuthorization(params, id, userAgent, accept, options);
      
    return this.getAuthorizedAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }
}
