<template>
    <div class="wrapper-scroll-js" ref="wrapper">
      <div class="container-scroll-js" ref="container">
        <div class="content-scroll-js flex" ref="content">
          <div class="container-hours flex mt-2">
            <div
              class="cursor-pointer"
              @click="setActiveOption(Sport.PADEL)"
              :class="{ label_seleccionado: isActiveOption(Sport.PADEL) }"
            >
              <SvgContainer name="scrollSnippedPadelIcon" /><h1>{{ $t("padel") }}</h1>
            </div>
            <div
              class="cursor-pointer"
              @click="setActiveOption(Sport.TENIS)"
              :class="{ label_seleccionado: isActiveOption(Sport.TENIS) }"
            >
              <SvgContainer name="scrollSnippedTennisIcon" /><h1>{{ $t("tennis") }}</h1>
            </div>
            <div
              class="cursor-pointer"
              @click="setActiveOption(Sport.PICKLEBALL)"
              :class="{ label_seleccionado: isActiveOption(Sport.PICKLEBALL) }"
            >
              <SvgContainer name="scrollSnippedPickerBallIcon" /><h1>{{ $t("pickleball") }}</h1>
            </div>
            <div
              class="cursor-pointer"
              @click="setActiveOption(Sport.SOCCER)"
              :class="{ label_seleccionado: isActiveOption(Sport.SOCCER) }"
            >
                <div class="align-middle w-48 h-48">
                    <img class="icon" draggable="false" src="@/assets/images/onboardingScrollImages/ic_soccer.svg"/>
                </div>
                <h1>{{ $t("soccer") }}</h1>
            </div>
            <div
              class="cursor-pointer"
              @click="setActiveOption(Sport.PADBOL)"
              :class="{ label_seleccionado: isActiveOption(Sport.PADBOL) }"
            >
                <div class="align-middle w-48 h-48">
                    <img class="icon filter_invert" draggable="false" src="@/assets/images/onboardingScrollImages/padbolIcon.svg"/>
                </div>
                <h1>{{ $t("padbol") }}</h1>
            </div>
            <div
              class="cursor-pointer"
              @click="setActiveOption(Sport.RACQUETBALL)"
              :class="{ label_seleccionado: isActiveOption(Sport.RACQUETBALL) }"
            >
                <div class="align-middle w-48 h-48">
                    <img class="icon filter_invert" draggable="false" src="@/assets/images/onboardingScrollImages/racquetballIcon.svg"/>
                </div>
                <h1>{{ $t("racquetball") }}</h1>
            </div>
            <div
              class="cursor-pointer"
              @click="setActiveOption(Sport.SQUASH)"
              :class="{ label_seleccionado: isActiveOption(Sport.SQUASH) }"
            >
                <div class="align-middle w-48 h-48">
                    <img class="icon filter_invert" draggable="false" src="@/assets/images/onboardingScrollImages/squashIcon.svg"/>
                </div>
                <h1>{{ $t("squash") }}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, watch, onMounted } from 'vue';
  import { useStore } from 'vuex';
  import { Sport } from "../../enum/constants";
  import SvgContainer from '@/views/SvgContainer.vue';
  
  export default {
    components: {
      SvgContainer,
    },
    setup() {
      const store = useStore();
      const selected = ref(Sport.PADEL);
  
      const setActiveOption = (optionIndex) => {
        selected.value = optionIndex;
        store.commit('setPreferencesSport', optionIndex);
      };
  
      const isActiveOption = (optionIndex) => {
        return selected.value === optionIndex;
      };
  
      // Watch the store state changes and update the component variables accordingly
      watch(() => store.state.preferences.sport, (newVal) => {
        selected.value = newVal;
      });
  
      // Initialize the component variables with the store state on component mount
      onMounted(() => {
        const sport = store.state.preferences.sport
          ? store.state.preferences.sport
          : Sport.PADEL;
        selected.value = sport;
        store.commit('setPreferencesSport', sport);
      });
  
      return {
        setActiveOption,
        isActiveOption,
      };
    },
    mounted() {
      this.installScrollShadow();
      this.installDragScrollX();
    },
    data() {
      return {
        Sport: Sport,
      };
    },
    methods: {
      installScrollShadow() {
        const wrapper = this.$refs.wrapper;
        const container = this.$refs.container;
        const content = this.$refs.content;
  
        const scrollShadowHorizontal = () => {
          const containerSize = Math.round(container.offsetWidth);
          const contentSize = Math.round(content.offsetWidth);
          const scrollLeft = Math.round(container.scrollLeft + containerSize);
  
          if (container.scrollLeft > 6) {
            wrapper.classList.add('scroll-left');
          } else {
            wrapper.classList.remove('scroll-left');
          }
  
          if (scrollLeft >= contentSize || contentSize <= containerSize) {
            wrapper.classList.remove('scroll-right');
          } else {
            wrapper.classList.add('scroll-right');
          }
        };
  
        container.addEventListener('scroll', scrollShadowHorizontal);
        window.addEventListener('resize', scrollShadowHorizontal);
  
        scrollShadowHorizontal();
      },
      installDragScrollX() {
        const container = this.$refs.container;
  
        let mouseDown = false;
        let startX;
        let scrollLeft;
  
        const startDragging = (e) => {
          mouseDown = true;
          startX = e.pageX - container.offsetLeft;
          scrollLeft = container.scrollLeft;
        };
  
        const stopDragging = () => {
          mouseDown = false;
        };
  
        container.addEventListener('mousemove', (e) => {
          e.preventDefault();
          if (!mouseDown) return;
  
          const x = e.pageX - container.offsetLeft;
          const scroll = x - startX;
          container.scrollLeft = scrollLeft - scroll;
        });
  
        container.addEventListener('mousedown', startDragging, false);
        container.addEventListener('mouseup', stopDragging, false);
        container.addEventListener('mouseleave', stopDragging, false);
      },
    },
  };
  </script>
  
<style scoped>
  .filter_invert{
    filter: invert(1);
  }
  .icon {
    width: 70px;
    height: auto;
    margin: 0px 60px 0 60px;
    padding-top: 49px
  }
  h1 {
    font-size: 21px;
    font-family: 'Montserrat', sans-serif;
  }
  
  .container-scroll-js {
    overflow-x: hidden; /* Ocultar la barra de desplazamiento por defecto */
    cursor: grab; /* Cambiar el cursor al arrastrar */
  }
  
  .container-scroll-js::-webkit-scrollbar {
    width: 1px !important; /* Ancho de la barra de desplazamiento */
    height: 0px !important;
  }
  
  .container-scroll-js::-webkit-scrollbar-track {
    background-color: transparent; /* Color de fondo del riel */
  }
  
  .container-scroll-js::-webkit-scrollbar-thumb {
    background-color: var(--color1); /* Color del pulgar */
  }
  </style>
  