<template>
  <header class="header flex flex-nowrap justify-end">
    <Login @refresh="handleRefresh" />
    <Register />
    <div class="flex flex-nowrap items-center gap-3">
      <span
        v-if="computedUser === null"
        @click="register()"
        :style="{ cursor: computedUser ? 'default' : 'pointer' }"
        >{{ $t("register") }}</span
      >

      <div
        class="menu-icon"
        v-if="computedUser === null"
        :style="{ cursor: computedUser ? 'default' : 'pointer' }"
        @click="login()"
      >
        <div class="user-icon inline-block relative">
          <SvgContainer name="headerRegisterIcon" />
        </div>
      </div>

      <div
        class="menu-icon cursor-pointer"
        v-if="computedUser !== null && !isWebView"
        @click="openMenuProfile">
        <div class="user-icon inline-block relative">
          <img class="rounded-full" :alt="computedUser.nickName" :src="computedUser.image" />
        </div>
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import store from "../../store";
import SvgContainer from "@/views/SvgContainer.vue";
import { useStore } from "vuex";
import Login from "@/components/login/login.vue";
import Register from "@/components/register/register.vue";
import {AppsHelper} from "@/helpers/appsHelper";

export default defineComponent({
  name: "HeaderComponent",
  components: {
    SvgContainer,
    Register,
    Login,
  },

  data() {
    return {
      showRegister: true,
      //refreshMenu: 1,
      isWebView: false,
    };
  },
  mounted() {
    this.isWebView = AppsHelper.isWebView() as boolean;
  },
  emits: ["refreshed"],
  computed: {
    computedUser() {
      const store = useStore();
      store.commit("setUser", JSON.parse(localStorage.getItem("user")!));
      const user = store.state.user;
      return user;
    },
  },

  methods: {
    login() {
      store.commit("openLogin", { allowClose: true });
    },
    register() {
      store.commit("openRegister", { allowClose: true });
    },
    handleRefresh() {
      this.$emit("refreshed");
    },
    openMenuProfile(){
        this.$router.push('/menu-profile')
    }
  },
});
</script>

<style scoped>
.header {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-size: 14px;
  color: #fff;
  width: 90%;
  margin: 0 auto;
  margin-top: 38px;
  /* border-top: 0.5px solid rgba(255, 255, 255, 0.2);
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.2); */
  padding: 16px 0px;
  position: relative;
  z-index: 2;
  @media (max-width: 767px) {
    border: none;
  }
}
.border {
  border: 0.5px solid rgba(255, 255, 255, 0.4);
}
.menu-icon {
  cursor: pointer;
  width: 48px;
  height: 48px;
}
.image-icon {
  cursor: default !important;
  height: 42px !important;
  margin-left: 8px;
}
.club {
  margin-left: 20px;
  font-weight: 600;
  margin-top: 14px;
  color: var(--main-color);
}
.logo {
  margin-top: 14px;
}
.user-icon {
  cursor: pointer;
  width: 48px;
  height: 48px;
}
</style>
