<template>
  <div>
    <span v-if="!onlyOneSport()" class="flex justify-center items-center m-5"
      >
      <div>{{ $t("sport") }}</div>
      <br />
      <div class="flex">
        <SportSelector
          v-model="activeSport"
          :sports="tenantInfo.tenant.sports"
          :showLabels="false"
          :titleTop="false"
          @sportSelected="setActiveSport"
        />
      </div>
    </span>
    <div class="flex flex-wrap items-center justify-center m-auto overflow-y-auto mt-4 max-h-[560px]">
      <ActivityAvailability v-if="!errorMsg"
        :activities="activitiesDataSource"
      />
      <p v-else>{{ errorMsg }}</p>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import ActivityAvailability from "@/components/search/activityAvailability.vue";
import {Category, SearchType, Sport} from "@/enum/constants";
import { formatDate, setTimeToDate } from "@/helpers/dateHelper";
import { AppsHelper } from "@/helpers/appsHelper";
import { BookingHelper } from "@/helpers/bookingHelper";
import { Format } from "@/helpers/formatHelper";
import SportSelector from "@/components/form/sportSelector.vue";

export default defineComponent({
  name: "bookActivities",
  props: ["tenantInfo"],
  components: {
    ActivityAvailability,
    SportSelector
  },
  data() {
    return {
      activitiesDataSource:[],
      errorMsg: '' as string | null,
      activeDate: new Date() as Date,
      activeHour: '' as string,
      sport: Sport, // to use in html
      activeSport: Sport.PADEL,
    };
  },
  async created(){
    this.activeHour = this.$route.query.hour as string;
    this.activeSport =  Number(this.$route.query.sport);
    if (this.activeHour === undefined) {
      this.activeHour = "0";
    }
    this.activeDate = setTimeToDate(new Date(this.$route.query.date as string),this.activeHour);
    this.loadActivities();
  },
  methods: {
    async setActiveSport(sport: number) {
      this.activeSport = +sport;
      await this.loadActivities();
    },
    async loadActivities() {
      this.errorMsg = null;
      const request = {
        tenantIds: [this.$route.query.id],
        date: formatDate(this.activeDate),
        sport: this.activeSport,
        category: Category.ACTIVITY,
        searchType: SearchType.FULL,
      };
      const message = await BookingHelper.messageAvailability(request);
      if (Format.IsNull(message.activities) || message.activities.length === 0) {
        this.errorMsg = this.$t("there_are_no_activities_for_the_selected_date_try_another");
        return;
      }
      this.activitiesDataSource = message.activities;
      //this.fixIphoneHeight();
    },
    async parentCallGetAvailability(date: any){
      const currentQuery = { ...this.$route.query };
      currentQuery.date = formatDate(date);
      this.$router.replace({ query: currentQuery }); //replace query date
      this.activeDate = date;
      await this.loadActivities();
    },
    fixIphoneHeight() {
      if (AppsHelper.isSafariOrIosWebView()) {
        const resultsContainer = document.querySelector("#ResultsContainer");
        if (resultsContainer) {
          resultsContainer.classList.add('pb-100');
        }
      }
    }
  },
});
</script>