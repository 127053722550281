<template>
    <div class="flex flex-col items-center mt-5">
        <div>{{ $t("games_played") }}</div>
            <div class="text-xs text-gray-600">{{ totalMatch }} {{ $t("matches") }}</div>
        </div>
        <div class="flex countContainer mt-4 items-center">
            <ResultBox 
            :firstValue = "results.victories.toString()"
            :secondValue = "win"
            />
            <ResultBox 
            :firstValue = "results.ties.toString()"
            :secondValue = "tie"
            />
            <ResultBox 
            :firstValue = "results.defeats.toString()"
            :secondValue = "lose"
        />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ResultBox from "@/components/form/resultBox.vue";

export default defineComponent({
    name: "WinRate",
    props: {
        results: {
            type: Object,
            required: true,
        }
    },
    components: {
        ResultBox,
    },
    data() {
      return {
        win: this.$t("win"),
        lose: this.$t("lose"),
        tie: this.$t("tie"),
      };
    },
    computed: {
        totalMatch() {
            return this.results.victories + this.results.ties + this.results.defeats;
        }
    },
    
});
</script>

<style scoped>
.countContainer {
  gap: 16px;
}
</style>