import { Format } from "./formatHelper";
import { ActivityApi } from "@/service/ActivityApi";
import { AxiosResponse } from "axios";

export class ActivityHelper {

  static async addToActivity(id: string) {
    const response = await ActivityApi.addToActivity(id)
    return this.checkResponse(response);
  }

  static async findByHash(id: string) {
    const response = await ActivityApi.findByHash(id)
    return this.checkResponse(response);
  }

  static checkResponse(response:AxiosResponse) {
    if(!Format.IsNull(response.data)){
      return response.data
    }
    return null;
  }

}

