import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchCalendar = _resolveComponent("SearchCalendar")!
  const _component_PopUpLight = _resolveComponent("PopUpLight")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showCalendar = true)),
      class: "active optionButton md:p-4 sm:p-4 relative cursor-pointer m0-auto"
    }, [
      _createElementVNode("div", null, _toDisplayString(_ctx.dateStr), 1)
    ]),
    _createVNode(_Transition, null, {
      default: _withCtx(() => [
        (_ctx.showCalendar)
          ? (_openBlock(), _createBlock(_component_PopUpLight, {
              key: 0,
              onClosed: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showCalendar = false))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_SearchCalendar, {
                  defaultDate: _ctx.rawDate,
                  onDateChanged: _ctx.setActiveDate
                }, null, 8, ["defaultDate", "onDateChanged"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}