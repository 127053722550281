import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { RegisterApiAxiosParamCreator } from '../../apis/register-api';
import { BaseApi } from './BaseApi';
import { USER_STATUS } from '@/enum/constants';

export class RegisterApi extends BaseApi{
  /**
   * 
   * @summary Service to register a new user
   * @param {string} username The username used to be identified
   * @param {string} password The user password
   * @param {string} fullName The user password
   * @param {string} phone The user password
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  public static async initRegister(username: string, password: string, fullName: string, prefix: string, phone: string, options?: AxiosRequestConfig): Promise<AxiosResponse<any>>{
    const axiosArgs = await RegisterApiAxiosParamCreator().initRegister(username, password, fullName, prefix, phone, options);

    return this.getAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }

  /**
   * 
   * @summary Service to finalize a register process
   * @param {string} token The token received to finalize registration process
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  public static async endRegister(token: string, options?: AxiosRequestConfig) {
    const axiosArgs = await RegisterApiAxiosParamCreator().endRegister(token, options);

    const response = this.getAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
    const jwtToken = (await response).data.value;
    localStorage.setItem('t', JSON.stringify(jwtToken))
    localStorage.setItem('auth', USER_STATUS.LOG_IN);
  }
}
