<template>
  <div v-if="loaded">
    <table>
      <thead>
        <tr>
          <th>{{ $t("Test") }}</th>
          <th>{{ $t("Evidence") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="value in validation" :key="value.orderId">
          <td>{{ value.operationName }}</td>
          <td>{{ value.orderId }}</td>
        </tr>
      </tbody>
    </table>

  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { PaymentApi } from "@/service/PaymentApi";

export default defineComponent({
  name: "TransbankValidator",
  data() {
    return {
      loaded: false,
      validation: [] as any,
    };
  },
  async mounted() {    
    this.validation = (await PaymentApi.validate()).data;
    this.loaded = true;
  },
});
</script>