<template>
  <div class="mt-8"></div>
</template>
<script lang="ts">
import {defineComponent} from "vue";
import {decodeString, encodeString} from "@/helpers/dataHelper";
import {GlobalErrors, PaymentProvider} from "@/enum/constants";
import {callWebviewPaymentCompleteMethod} from "@/helpers/appsHelper";
import {PaymentResponse} from "@/custom-models/payment-response";
import {TransbankPaymentQuery} from "@/custom-models/transbank-payment-response";
import { AuthorizeSaleRequest } from "models/authorize-sale-request";
import store from "@/store";
import { SaleApi } from "@/service/SaleApi";


export default defineComponent({
  name: "PaymentRedirect",
  data() {
    return {
      payload: "" as string,
      transbankPaymentQuery: {} as TransbankPaymentQuery,
      saleId: "" as string,
      errorMsg: "" as string,
      paymentProvider: "" as string
    };
  },
  async mounted() {
    this.paymentProvider = this.$route.params.paymentProvider as string;
    let result: PaymentResponse;
    let errorMsg: string;
    let token: string;
    let data: AuthorizeSaleRequest;

    switch (this.paymentProvider) {
      case PaymentProvider.AZUL:
        // Azul payment
        // Decode result
        result = JSON.parse(decodeString(this.$route.params.payload! as string)) as PaymentResponse;
        // Get error message (got null if status is ok/200)
        errorMsg = this.getErrorDescription(result.status);
        // Send message to apps
        callWebviewPaymentCompleteMethod(result.success, encodeString(errorMsg));
        // Redirect to checkout ok page
        if (result.success) {
          return this.$router.push("/checkout-ok/" + result.saleId);
        }
        // Redirect to checkout error page
        return this.$router.push(
            "/pay-error/" + result.saleId + "/" + encodeString(result.cancellationLimitDate!) +"/" + encodeString(errorMsg)
        );
      case PaymentProvider.TRANSBANK:
        // Transbank payment
        this.transbankPaymentQuery = this.$route.query as TransbankPaymentQuery;
        this.saleId = this.$route.params.payload as string;
        if(this.transbankPaymentQuery.TBK_TOKEN){
          token = this.transbankPaymentQuery.TBK_TOKEN;
          data = {
            saleId: this.saleId,
            operationId: token
          }
          if (
            this.transbankPaymentQuery.TBK_ID_SESION &&
            this.transbankPaymentQuery.TBK_ORDEN_COMPRA
          ) {
            this.sendError(this.saleId, "", this.$t("payment_platform_operation_cannot_be_completed"));
          }
          await this.initPayment(data);
        }
        break;
      default:
        this.sendError(this.saleId, "", this.$t("payment_platform_operation_cannot_be_completed"));
    }
  },
  methods: {
    getErrorDescription(status: number) : string{
      if (status == undefined) {
        return this.$t("payment_platform_operation_cannot_be_completed");
      }
      switch (Number(status)) {
        case GlobalErrors.ERROR_401:
          return this.$t("pay_declined");
        case GlobalErrors.ERROR_404:
          return this.$t("payment_platform_operation_cannot_be_completed");
        case GlobalErrors.ERROR_499:
          return this.$t("pay_cancelled");
      }
      return this.$t("payment_platform_operation_cannot_be_completed");
    },
    async initPayment(data: AuthorizeSaleRequest){
      try {
        store.commit("setLoading", true);
        await SaleApi.authorize(data);
        this.paymentExecuted({ success: true });
      } catch (error: any) {
        this.paymentExecuted(error.request);
      }
    },
    paymentExecuted(result: any) {
      store.commit("setLoading", false);
      if (result.success === true) {
        callWebviewPaymentCompleteMethod(result.success, "");
        return this.$router.push("/checkout-ok/" + this.saleId);
      }
      this.errorMsg = this.getErrorDescription(Number(result.status));
      this.sendError(this.saleId, result.cancellationLimitDate, this.errorMsg);
    },
    sendError(saleId: string, cancellationLimitDate: string, errorMsg: string){
      callWebviewPaymentCompleteMethod(false, encodeString(errorMsg));
      // Redirect to checkout error page
      return this.$router.push(
          "/pay-error/" + saleId + "/" + encodeString(cancellationLimitDate) +"/" + encodeString(errorMsg)
      );
    }
  }
})

</script>

