import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SvgContainer = _resolveComponent("SvgContainer")!

  return (!_ctx.AppsHelper.isAndroidWebView())
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goBack && _ctx.goBack(...args))),
        class: "walletButton back flex items-center justify-center"
      }, [
        _createVNode(_component_SvgContainer, { name: "profileBackArrow" })
      ]))
    : _createCommentVNode("", true)
}