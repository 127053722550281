import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-64748ab3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "min-h-600 min-h-responsive"
}
const _hoisted_3 = {
  key: 1,
  class: "min-h-600 min-h-responsive"
}
const _hoisted_4 = {
  key: 2,
  class: "min-h-600 min-h-responsive"
}
const _hoisted_5 = {
  key: 3,
  class: "min-h-600 min-h-responsive"
}
const _hoisted_6 = {
  key: 4,
  class: "min-h-600 min-h-responsive"
}
const _hoisted_7 = {
  key: 5,
  class: "min-h-600 min-h-responsive"
}
const _hoisted_8 = {
  key: 6,
  class: "min-h-600 min-h-responsive"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PadelForm = _resolveComponent("PadelForm")!
  const _component_TenisForm = _resolveComponent("TenisForm")!
  const _component_PickleForm = _resolveComponent("PickleForm")!
  const _component_SoccerForm = _resolveComponent("SoccerForm")!
  const _component_PadbolForm = _resolveComponent("PadbolForm")!
  const _component_RacquetballForm = _resolveComponent("RacquetballForm")!
  const _component_SquashForm = _resolveComponent("SquashForm")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ whiteTheme: _ctx.isWhiteTheme })
  }, [
    (!_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.customSport === _ctx.Sport.PADEL)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_PadelForm, { onUserData: _ctx.handleUserData }, null, 8, ["onUserData"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.customSport === _ctx.Sport.TENIS)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_TenisForm, { onUserData: _ctx.handleUserData }, null, 8, ["onUserData"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.customSport === _ctx.Sport.PICKLEBALL)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_PickleForm, { onUserData: _ctx.handleUserData }, null, 8, ["onUserData"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.customSport === _ctx.Sport.SOCCER)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_SoccerForm, { onUserData: _ctx.handleUserData }, null, 8, ["onUserData"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.customSport === _ctx.Sport.PADBOL)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(_component_PadbolForm, { onUserData: _ctx.handleUserData }, null, 8, ["onUserData"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.customSport === _ctx.Sport.RACQUETBALL)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createVNode(_component_RacquetballForm, { onUserData: _ctx.handleUserData }, null, 8, ["onUserData"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.customSport === _ctx.Sport.SQUASH)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createVNode(_component_SquashForm, { onUserData: _ctx.handleUserData }, null, 8, ["onUserData"])
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}