<template>
  <div>
    <div
      class="flex button items-center cursor-pointer"
      @click="toggleActivity"
    >
      <img :src="items[optionSelected].icon" class="icon ml-3" />
      <p class="text mt p-3">{{ items[optionSelected].text }}</p>
    </div>
    <Transition>
      <PopUpLight v-if="showActivities" @closed="closeActivities">
        <ActivityBar
          :optionDefault="optionSelected"
          @optionSelected="handleActivitySelected"
        />
      </PopUpLight>
    </Transition>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import ActivityBar from "@/components/booking/activityBar.vue";
import PopUpLight from "@/components/popup/popupLight.vue";
import bookingIconSelected from "@/assets/images/activityBar/bookingIconSelected.svg";
import matchIconSelected from "@/assets/images/activityBar/matchIconSelected.svg";
import activityIconSelected from "@/assets/images/activityBar/activityIconSelected.svg";

export default defineComponent({
  name: "CategorySelector",
  props: {
    optionDefault: {
      type: Number,
      default: 0,
      required: false,
    },
  },
  emits: ["optionSelected"],
  components: {
    ActivityBar,
    PopUpLight,
  },
  data() {
    return {
      showActivities: false,
      optionSelected: this.optionDefault as number,
      items: [
        { icon: bookingIconSelected, text: this.$t("booking") },
        { icon: matchIconSelected, text: this.$t("matches") },
        { icon: activityIconSelected, text: this.$t("activities") },
      ],
    };
  },
  methods: {
    toggleActivity(): void {
      this.showActivities = true;
    },
    closeActivities(): void {
      this.showActivities = false;
    },
    handleActivitySelected(activitySelected: number): void {
      this.optionSelected = activitySelected;
      this.$emit("optionSelected", this.optionSelected);
      this.closeActivities();
    },
  },
});
</script>
<style scoped>
.icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.text {
  color: white;
}

.button :hover .icon,
.button :hover .text {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%)
    hue-rotate(0deg) brightness(100%) contrast(100%);
}

.button {
  -webkit-backdrop-filter: blur(16px);
  backdrop-filter: blur(16px);
  height: 44px;
  font-size: 14px;
  background: rgba(255, 255, 255, 0.02);
  border: 1px solid;
  border-radius: 24px;
  box-shadow: inset 0px 0px 12px rgba(255, 255, 255, 0.15);
  border-bottom-color: #264b67;
  border-left-color: #264b67;
  border-right-color: #076ae3;
  border-top-color: #264b67;
}
</style>
  