<template>
  <span ref="top">
    <HeaderView></HeaderView>
    <div class="fixed top-16 left-[10%] z-[100]"><HistoryBack /></div>
    <div class="paymentWrapper">
      <div class="flex items-center mt-8 mb-6 sm:text-sm">
        <span class="ml-4 mr-4"> </span>
        <a href="" class="">{{ $t("add_players") }}</a>
      </div>

      <AddPlayers
        :mode="mode.CREATION"
        v-if="queryData !== ''"
        :bookingData="queryData"
        @setUpCompleted="goToPay"
      ></AddPlayers>
    </div>
  </span>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import HeaderView from "@/components/header/header.vue";
import AddPlayers from "@/components/booking/addPlayers.vue";
import { customGlobalError } from "@/helpers/errorHelper";
import { PlayersMode } from "@/enum/constants";
import store from "../store";
import HistoryBack from "@/components/form/historyBack.vue";
import { encodeString, decodeString } from "@/helpers/dataHelper"

export default defineComponent({
  name: "bookingCreationView",
  components: {
    HeaderView,
    AddPlayers,
    HistoryBack
  },
  data() {
    return {
      selectedPlayer: 0,
      queryData: "",
      mode: PlayersMode,
    };
  },
  created() {
    const user = localStorage.getItem("user");
    if (user === null || user === "") {
      store.commit("openLogin", { goToUrl: this.$route.fullPath });
      return;
    }
  },
  mounted() {
    if (window.location.href.includes("players")) {
      document.body.style.overflowY = "scroll";
    }
    const targetElement: any = this.$refs.top;
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }

    document.body.style.background = "none";

    const query = this.$route.query.pl;
    if (query === undefined || query === null || query === "") {
      customGlobalError(this.$t("wrong_url"), "/");
      return;
    }

    let utf8Query = null;
    try {
      utf8Query = decodeString(query.toString())
    } catch (error) {
      customGlobalError(this.$t("wrong_url"), "/");
      return;
    }

    try {
      this.queryData = JSON.parse(utf8Query);
    } catch (error) {
      customGlobalError(this.$t("wrong_url"), "/");
      return;
    }
  },
  methods: {
    goToPay(query: string) {
      this.$router.push({
        path: "checkout",
        query: { pl: encodeString(JSON.stringify(query)) },
      });
    },
  },
});
</script>
<style scoped>
.paymentWrapper {
  width: 90%;
  margin: 0 auto;
}
</style>