import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-54b610e7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-col items-center mt-5" }
const _hoisted_2 = { class: "text-xs text-gray-600" }
const _hoisted_3 = { class: "flex countContainer mt-4 items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ResultBox = _resolveComponent("ResultBox")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, _toDisplayString(_ctx.$t("games_played")), 1),
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.totalMatch) + " " + _toDisplayString(_ctx.$t("matches")), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_ResultBox, {
        firstValue: _ctx.results.victories.toString(),
        secondValue: _ctx.win
      }, null, 8, ["firstValue", "secondValue"]),
      _createVNode(_component_ResultBox, {
        firstValue: _ctx.results.ties.toString(),
        secondValue: _ctx.tie
      }, null, 8, ["firstValue", "secondValue"]),
      _createVNode(_component_ResultBox, {
        firstValue: _ctx.results.defeats.toString(),
        secondValue: _ctx.lose
      }, null, 8, ["firstValue", "secondValue"])
    ])
  ], 64))
}