<template>
  <Transition>
    <Popup
      v-if="openLogin || $store.state.loginIsOpen"
      :showOkButton="false"
      @closed="closeLogin()"
    >
      <div class="modal-headerr">
        <h4 class="modal-title text-center">
          <header>
            <!--<img class="inline-block logo" alt="Taykus logo" :src="clubData.logo || '../../assets/images/taykus-header.png'" />-->
            <img
              class="inline-block logo"
              alt="Taykus logo"
              src="../../assets/images/header-popup.png"
            />
          </header>
        </h4>
      </div>
      <h2>{{ $t("login") }}</h2>
      <p>{{ $t("discover_your_places_in_the_world") }}</p>
      <form @submit.prevent="submitForm">
        <InputEmail v-model="email" />
        <InputPassword @passwordValidated="handlePasswordValidated" />
        <div class="flex justify-center w-full mt-8 input-component">
          <input
            type="submit"
            value="Login"
            :disabled="hasErrors"
            :class="{ 'button-disabled': hasErrors, '': true }"
          />
        </div>
      </form>
      <div id="loginError" class="flex justify-center w-full mt-4">
        <p class="form-error">{{ loginError }}</p>
      </div>
      <div class="relative w-full account-text cursor-pointer forgot">
        <span @click="openRecoverPsw">{{ $t("forgot_password") }}</span>
      </div>
      <div class="flex justify-center w-full mt-6">
        <div class="relative w-full account-text">
          <SvgContainer name="loginLeftBar" />
          <span>{{ $t("or_create_account_with") }}</span>
          <SvgContainer name="loginRightBar" />
        </div>
      </div>
      <div class="flex justify-center w-full mt-8">
        <div class="relative w-3/6 float-right">
          <CustomGoogleLogin @success="handleAppLoginSuccess" />
        </div>
        <div class="relative w-3/6">
          <CustomAppleLogin @success="handleAppLoginSuccess" />
        </div>
      </div>
      <div
        @click="showRegister()"
        class="walletButton mt-6 p-4 text-center cursor-pointer sm:text-sm"
      >
      {{ $t("register") }}
      </div>
    </Popup>
  </Transition>
  <PopupLight v-if="openRecover" @closed="handleCloseRecover()">
    <RecoverPassword
      :phase="phase"
      :token="token"
      @closeRecover="handleCloseRecover()"
    />
  </PopupLight>
</template>



<script lang="ts">
import { defineComponent, ref } from "vue";
import Popup from "../../components/popup/popup.vue";
import InputPassword from "../../components/form/inputPassword.vue";
import InputEmail from "../../components/form/inputEmail.vue";
import store from "../../store";
import SvgContainer from "@/views/SvgContainer.vue";
import { AuthorizationApi } from "../../service/AuthorizationApi";
import { CustomerApi } from "../../service/CustomerApi";
import CustomGoogleLogin from "@/components/google/customGoogleLogin.vue";
import PopupLight from "@/components/popup/popupLight.vue";
import RecoverPassword from "@/components/login/recoverPassword.vue";
import { Format } from "@/helpers/formatHelper";
import CustomAppleLogin from "@/components/apple/customAppleLogin.vue";

export default defineComponent({
  name: "LoginPopUp",
  emits: ["refresh"],
  components: {
    Popup,
    PopupLight,
    SvgContainer,
    InputEmail,
    InputPassword,
    RecoverPassword,
    CustomGoogleLogin,
    CustomAppleLogin
  },
  props: {
    open: { type: Boolean, default: false },
    returnUrl: { type: String },
  },
  computed: {
    hasErrors(): boolean {
      return this.password == null || this.email == null;
    },
  },
  setup() {
    const isActive = ref(false);
    return {
      isActive,
    };
  },
  async created() {
    this.token = Array.isArray(this.$route.query.recoveryMailToken) ? this.$route.query.recoveryMailToken[0] : this.$route.query.recoveryMailToken;
    if (this.token) {
      this.phase = 1;
      this.openRecoverPsw();
      const queryParams = { ...this.$route.query };
      // Remove the 'recoveryMailToken'
      delete queryParams.recoveryMailToken;
      this.$router.replace({ query: queryParams });
      //clear url
      const url = new URL(window.location.href);
      url.search = "";
      window.history.replaceState({}, document.title, url.href);
    }
  },
  data() {
    return {
      openLogin: false,
      openRecover: false,
      phase: 0,
      error: "",
      isLogin: true,
      email: "",
      password: "",
      loginError: "",
      user: null,
      isEmailTouched: false,
      isPasswordTouched: false,
      token: null as string | null,
    };
  },
  mounted() {
    this.openLogin = this.open;
    this.loginError = "";
  },

  methods: {
    async authenticate(data: any) {
      try {
        await AuthorizationApi.authenticate(data.username, data.password);
        await this.getUser();
        this.refreshParent();
        this.closeLogin();
        if (!Format.IsNull(store.state.loginRedirect)) {
          location.href = store.state.loginRedirect;
        }
      } catch (error: any) {
        this.loginError = error.response.data.messages[0];
      }
    },
    async getUser() {
      await CustomerApi.get();
    },
    handlePasswordValidated(password: any) {
      this.password = password;
    },
    async handleAppLoginSuccess(isNew: boolean) {
      await this.getUser();
      if (isNew) {
        this.Onboarding();
      }
      this.closeLogin();
      if (!Format.IsNull(store.state.loginRedirect)) {
          location.href = store.state.loginRedirect;
      }
      this.$emit("refresh");
    },
    closeLogin() {
      this.openLogin = false;
      store.commit("closeLogin");
    },
    openRecoverPsw() {
      this.openRecover = true;
    },
    handleCloseRecover() {
      this.openRecover = false;
      this.token = null;
    },
    submitForm() {
      const data = {
        username: this.email,
        password: this.password,
      };
      this.authenticate(data);
    },
    showRegister() {
      store.commit("closeLogin");
      store.commit("openRegister");
    },
    Onboarding() {
      store.commit("openOnboarding");
    },
    refreshParent() {
      this.$emit("refresh");
    },
  },
});
</script>
<style>
.form-error {
  color: #f472b6 !important;
}
.is-invalid {
  border: 1px solid #f472b6 !important;
}
.button-disabled {
  opacity: 0.5 !important;
  cursor: default !important;
}
.forgot {
  text-align: right !important;
}
</style>