<template>
    <TransbankValidator></TransbankValidator>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import TransbankValidator from "@/components/paymentGateways/transbankValidator.vue";
export default defineComponent({
  name: "transbankValidationView",
  components: { TransbankValidator },
});
</script>