<template>
  <div class="mt-8"></div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { decodeString } from "@/helpers/dataHelper";


export default defineComponent({
  name: "IframeRedirect",
  data() {
    return {
      payload: "" as string,
    };
  },
  components: {},
  mounted() {
    if(this.$route.params.payload){
      const decodedPayload = decodeString(this.$route.params.payload! as string)
      window.parent.postMessage(JSON.parse(decodedPayload), process.env.VUE_APP_URL);
      return;
    }
  },
});
</script>

@/custom-models/transbank-payment-response