<template>
    <div class="hidden"></div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { clearURL } from "@/helpers/dataHelper"
import CustomerHelper from "@/helpers/customerHelper"
import { customGlobalError } from "@/helpers/errorHelper";
import { AxiosResponse } from "axios";

export default defineComponent({
    name: "VerifyPhone",
    data() {
        return {
            verifyPhoneToken: null as string | null,
        };
    },
    methods: {
        async verifyPhone() {
            this.verifyPhoneToken = Array.isArray(this.$route.query.verifyPhoneToken) ? this.$route.query.verifyPhoneToken[0] : this.$route.query.verifyPhoneToken;
            clearURL();
            if (this.verifyPhoneToken) {
                const response = await CustomerHelper.verifyPhone(this.verifyPhoneToken) as AxiosResponse;
                if(response){
                    const statusCode = response.request.status;
                    if (statusCode === 200) {
                        customGlobalError(this.$t("your_phone_has_been_verified"));
                    }
                }
            }
        },
    },
    created() {
        this.verifyPhone();
    },
});
</script>
  