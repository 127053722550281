<template>
  <div class="mt-8">
    <div class="flex items-center justify-center"><p>{{ $t("my_payments") }}</p></div>
    <div v-if="result && result.length > 0" class="mt-20 br16 altBackground p-4 overflow-y-auto fix-height">
      <ul>
        <li v-for="pay in result" :key="pay.sale?.id">
          <div class="component justify-between">
            <div class="component-info flex justify-center items-center">
              <h3>{{ getBeautifyDate(pay.date) }}</h3>
            </div>
            <div class="component-info flex justify-center items-center">
              <h3>{{ capitalizeFirstLetter(pay.sale?.tenant?.name ?? '') }}</h3>
            </div>
            <div class="component-info flex justify-center items-center">
              <h3>
                {{
                  getFormatedCurrency(
                    pay.amountData?.grossAmount ?? 0,
                    pay.amountData?.currencyCode ?? "",
                    pay.amountData?.locale ?? ""
                  )
                }}
              </h3>
            </div>
          </div>
          <div class="linearSpace"></div>
        </li>
      </ul>
      <!--
      <ListPagination 
        :start="start" 
        :size="size" 
        @getData="handleGetData" 
      />-->
    </div>
    <div v-else class="flex items-center justify-center mt-20 br16 altBackground p-4"><p>{{ $t("dont_have_any_pay") }}</p></div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { beautifyDate } from "@/helpers/dateHelper";
import ListPagination from "@/components/form/listPagination.vue";
import PaymentHelper from "@/helpers/paymentHelper";
import { Format } from "@/helpers/formatHelper";
import { Payment } from "models";
import store from '@/store';
import { capitalizeFirstLetter } from "@/helpers/dateHelper";

export default defineComponent({
  name: "MyPays",
  data() {
    return {
      start: 0,
      size: 50,
      result: [] as Payment[],
    };
  },
  components: {
    //ListPagination,
  },
  async mounted() {
    this.loadData(this.start, this.size);
  },
  methods: {
    capitalizeFirstLetter,
    async handleGetData(start: number, size: number) {
      this.loadData(start, size);
    },
    async loadData(start: number, size: number) {
      store.commit("setLoading", true);
      this.result = await PaymentHelper.findPaymentByCustomer(start, size);
      store.commit("setLoading", false);
    },
    getBeautifyDate(date: any) {
      return beautifyDate(date, true, false);
    },
    getFormatedCurrency(
      grossAmount: number,
      currencyCode: string,
      locale: string
    ) {
      return Format.formatCurrency(grossAmount, currencyCode, locale);
    },
  },
});
</script>

<style scoped>
.fix-height {
  max-height: calc(100vh - 150px);
}
.linearSpace {
  background: linear-gradient(
    270deg,
    #023b70 3.65%,
    #406297 19.27%,
    #4668a0 83.33%,
    #033768 100%
  );
  height: 1px;
}
.component {
  display: flex;
  margin-bottom: 10px;
  margin-top: 10px;
}
.component-info {
  width: 33%;
  min-height: 80px;
}
</style>

