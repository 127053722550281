<template>
  <div>
    <label @click="isEditing = true" class="flex items-end cursor-pointer"
      >{{ getGenderLabel() }}
      <div class="mt-auto">
        <SvgContainer name="pencilProfile" />
      </div>
    </label>

    <Transition>
      <PopUpLight v-if="isEditing" @closed="isEditing = false">
        <div class="mt-8">
          <UserGenderSelector @selectionChanged="saveChanges" />
        </div>
      </PopUpLight>
    </Transition>
  </div>
</template>
  
  <script>
import CustomerHelper from "@/helpers/customerHelper";
import SvgContainer from "@/views/SvgContainer.vue";
import UserGenderSelector from "@/components/form/userGenderSelector.vue";
import PopUpLight from "@/components/popup/popupLight.vue";
import { Format } from "@/helpers/formatHelper";
import { UserGender } from "@/enum/constants";

export default {
  name: "ProfileGender",
  data() {
    return {
      isEditing: false,
      label: "",
      originalLabel: "",
      gender: UserGender.MAN,
    };
  },
  components: {
    SvgContainer,
    PopUpLight,
    UserGenderSelector,
  },
  async mounted() {
    const gender = await CustomerHelper.getUserGender();
    if (Format.IsNull(gender) || Format.IsNull(gender.code)) {
      this.gender = -1;
      return;
    }
    this.gender = gender.code;
  },

  methods: {
    getGenderLabel() {
      if (Format.IsNull(this.gender)) {
        return this.$t("select_your_gender");
      }

      var label = "";
      switch (this.gender) {
        case UserGender.MAN:
          label = this.$t("man");
          break;
        case UserGender.WOMAN:
          label = this.$t("woman");
          break;
        case UserGender.OTHERS:
          label = this.$t("others");
          break;
        default:
          label = this.$t("select_your_gender");
      }
      return Format.titleCase(label);
    },
    async saveChanges(gender) {
      this.isEditing = false;
      const user = await CustomerHelper.updateGender(gender);
      if (!Format.IsNull(user)) {
        CustomerHelper.updateStorageUser(user);
      }
      this.gender = gender;
    },
  },
};
</script>
  
  <style scoped>
.input {
  background-color: black;
  color: white;
}
</style>
  