<template>
  <div class="flex flex-wrap paddig-responsive">
    <div class="flex w-full text-left center-responsive">
      <h2>{{ $t("actual_condition_question") }}</h2>
    </div>
    <div class="flex w-full mt-8">
      <div class="flex flex-col">
        <template v-for="option in options" :key="option.id">
          <label class="flex items-center mb-2">
            <input type="radio" :value="option.value" v-model="condition" @change="showValue()" class="mr-2">
            <span>{{ option.label }}</span>
          </label>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { SelectOption } from "@/custom-models/levelingSelector";
  
export default defineComponent({
  name: 'ConditionSelector',
  props: {
    propCondition:{ 
      type: Number,
      default: 0.0 
    }
  },
  created (){
    this.condition = this.propCondition;
  },
  data() {
    return {
      condition: 0.4,
      options: [
        { id: 1, label: this.$t("excellent"), value: 0.4 },
        { id: 2, label: this.$t("good"), value: 0.3 },
        { id: 3, label: this.$t("normal"), value: 0.2 },
        { id: 4, label: this.$t("low"), value: 0.1 },
        { id: 5, label: this.$t("very_low"), value: 0.0 },
      ] as SelectOption[]
    };
  },
  methods: {
    showValue() {
      this.$emit("selectedCondition", this.condition);
    }
  }
});
</script>