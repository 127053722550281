import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { ChatApiAxiosParamCreator } from '../../apis/chat-api';
import { BaseApi } from './BaseApi';

export class ChatApi extends BaseApi {

  /**
  * 
  * @summary Add customer to chat
  * @param {string} id The booking id
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
  public static async createUser(bookingId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<any>>{
    const axiosArgs = await ChatApiAxiosParamCreator().addUserToChat(bookingId, options);
    
    return this.getAuthorizedAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }
}
