import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { PaymentApiAxiosParamCreator } from '../../apis/payment-api';
import { BaseApi } from './BaseApi';
import { CreateStripePaymentIntentRequest, StartTransbankRequest } from 'models';

export class PaymentApi extends BaseApi {

  /**
  * 
  * @summary Get the payments of a customer
  * @param {number} start Zero-based page index
  * @param {number} size The page size
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
  public static async findByCustomer(start: number, size: number, options?: AxiosRequestConfig): Promise<AxiosResponse<any>>{
    const axiosArgs = await PaymentApiAxiosParamCreator().findPaymentByCustomer(start, size, options);
    
    return this.getAuthorizedAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }

  /**
  * 
  * @summary Get the payments of a customer
  * @param {number} start Zero-based page index
  * @param {number} size The page size
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
  public static async findPaymentByCustomer(start: number, size: number, options?: AxiosRequestConfig): Promise<AxiosResponse<any>>{
    const axiosArgs = await PaymentApiAxiosParamCreator().findPaymentByCustomer(start, size, options);
    
    return this.getAuthorizedAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }
  
  /**
  * @param {CreateStripePaymentIntentRequest} body 
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
  public static async createStripePaymentIntent(body: CreateStripePaymentIntentRequest, options?: AxiosRequestConfig): Promise<AxiosResponse<any>>{
    const axiosArgs = await PaymentApiAxiosParamCreator().createStripePaymentIntent(body, options);
    
    return this.getAuthorizedAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }

  /**
   * 
   * @summary Checks the status of a Stripe payment intent
   * @param {string} The payment intent id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  public static async getStripePaymentIntentStatus(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<any>>{
    const axiosArgs = await PaymentApiAxiosParamCreator().getStripePaymentIntentStatus(id, options);
    
    return this.getAuthorizedAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }

    /**
    * 
    * @param {StartTransbankRequest} body 
    * @param {*} [options] Override http request option.
    * @throws {RequiredError}
    */
    public static async startTransaction(body: StartTransbankRequest, options?: AxiosRequestConfig): Promise<AxiosResponse<any>>{
      const axiosArgs = await PaymentApiAxiosParamCreator().startTransaction(body, options);
      
      return this.getAuthorizedAxios().request({
        url: axiosArgs.url,
        method: axiosArgs.options.method,
        headers: axiosArgs.options.headers,
        params: axiosArgs.options.params,
        data: axiosArgs.options.data,
      });
    }

    public static async validate(options?: AxiosRequestConfig): Promise<AxiosResponse<any>>{
      const axiosArgs = await PaymentApiAxiosParamCreator().validate(options);
      
      return this.getAuthorizedAxios().request({
        url: axiosArgs.url,
        method: axiosArgs.options.method,
        headers: axiosArgs.options.headers,
        params: axiosArgs.options.params,
        data: axiosArgs.options.data,
      });
    }
}
