<template>
    <div class="flex items-center">
        <label for="fileInput" class="flex items-end cursor-pointer">
            <img
                :src="imgSrc"
                alt="Click to upload"
                @click="openFileInput"
                class="image br100"
                @click.prevent
            />
            <div class="pencil">
                <SvgContainer name="pencilProfile" />
            </div>
        </label>
        <input
            type="file"
            id="fileInput"
            ref="fileInput"
            style="display: none;"
            @change="uploadImage"
            accept="image/*"
        />
    </div>
  </template>
  
<script>
import CustomerHelper from "@/helpers/customerHelper";
import { customGlobalError } from "@/helpers/errorHelper";
import { Format } from "@/helpers/formatHelper";
import SvgContainer from "@/views/SvgContainer.vue";

export default {
    name: "ProfileImage",
    data() {
        return {
            imgSrc: ""
        };
    },
    components: {
        SvgContainer,
    },
    async mounted() {
        this.imgSrc = await CustomerHelper.getUserImage();

        if(Format.IsNull(this.imgSrc)){
            this.imgSrc = require("@/assets/images/Face.png");
        }
    },
    methods: {
        openFileInput() {
            this.$refs.fileInput.click();
        },
        async uploadImage(event) {
            const selectedFile = event.target.files[0];

            //validate file and type
            if (Format.IsNull(selectedFile)) {
                customGlobalError(this.$t("please_select_an_image_file"));
                return;
            }

            if (!selectedFile.type.startsWith('image/')) {
                customGlobalError(this.$t("please_select_an_image_file"));
                return;
            }

            this.imgSrc = await CustomerHelper.uploadImageForm(selectedFile)
        },
    },
};
</script>

<style scoped>
.image {
    width:68px;
    height:68px;
}
.pencil{
    margin-top: auto;
    margin-left: -20px;
}
.br100 {
    border-radius: 50%;
}
</style>
