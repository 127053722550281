import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { TenantApiAxiosParamCreator } from '../../apis/tenant-api';
import { BaseApi } from './BaseApi';

export class TenantApi extends BaseApi {

  /**
   * 
   * @summary Get all tenants
   * @param {number} start 
   * @param {number} size 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  public static async findAll(start: number, size: number,  options?: AxiosRequestConfig): Promise<AxiosResponse<any>>{
    const axiosArgs = await TenantApiAxiosParamCreator().findAllTenants(start, size, options);
    return this.getAuthorizedAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }

  /**
   * 
   * @summary Get the tenant data by id
   * @param {number} id The tenant id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  public static async findById(id: number, options?: AxiosRequestConfig) {
    const axiosArgs = await TenantApiAxiosParamCreator().findTenantById(id, options);
    return this.getAuthorizedAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }

  /**
   * 
   * @summary Get all tenants filtering by name or a place
   * @param {string} name The tenant or place name
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  public static async findByNameAndPlaceContaining(name: string, options?: AxiosRequestConfig) {
    const axiosArgs = await TenantApiAxiosParamCreator().findTenantByNameAndPlaceContaining(name, options);
    return this.getAuthorizedAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }

  /**
   * 
   * @summary Get all tenants filtering by name or a place
   * @param {string} id The tenant id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  public static async getPlaceDetails(id: string, options?: AxiosRequestConfig) {
    const axiosArgs = await TenantApiAxiosParamCreator().getPlaceDetails(id, options);
    return this.getAuthorizedAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }

  /**
  * 
  * @summary Get the tenant data from Pandora
  * @param {string} id The pandora tenant id
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
  public static async findTenantByIdFromPandora(id: string, options?: AxiosRequestConfig) {
    const axiosArgs = await TenantApiAxiosParamCreator().findTenantByIdFromPandora(id, options);
    return this.getAuthorizedAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }

}
