/* tslint:disable */
/* eslint-disable */
/**
 * Marketplace Api
 * Marketplace Api description
 *
 * OpenAPI spec version: 1.0
 * Contact: develop@taykus.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { PandoraTenantConfiguration } from '../models';
import { Place } from '../models';
import { Places } from '../models';
import { Tenant } from '../models';
/**
 * TenantApi - axios parameter creator
 * @export
 */
export const TenantApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all tenants
         * @param {number} start Zero-based page index
         * @param {number} size The page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllTenants: async (start: number, size: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'start' is not null or undefined
            if (start === null || start === undefined) {
                throw new RequiredError('start','Required parameter start was null or undefined when calling findAllTenants.');
            }
            // verify required parameter 'size' is not null or undefined
            if (size === null || size === undefined) {
                throw new RequiredError('size','Required parameter size was null or undefined when calling findAllTenants.');
            }
            const localVarPath = `/api/tenant`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the tenant data by id
         * @param {number} id The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTenantById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling findTenantById.');
            }
            const localVarPath = `/api/tenant/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the tenant data from Pandora
         * @param {string} id The pandora tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTenantByIdFromPandora: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling findTenantByIdFromPandora.');
            }
            const localVarPath = `/api/tenant/configuration/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all tenants filtering by name or a place
         * @param {string} name The tenant or place name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTenantByNameAndPlaceContaining: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling findTenantByNameAndPlaceContaining.');
            }
            const localVarPath = `/api/tenant/place`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all tenants filtering by name
         * @param {string} name The tenant name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTenantByNameContaining: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling findTenantByNameContaining.');
            }
            const localVarPath = `/api/tenant/name`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the tenant data by pandora id
         * @param {string} id The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTenantByPandoraId: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling findTenantByPandoraId.');
            }
            const localVarPath = `/api/tenant/pandora/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the tenant data by crm tenant id
         * @param {string} id The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTenantByTenantId: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling findTenantByTenantId.');
            }
            const localVarPath = `/api/tenant/crm/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get data about a place
         * @param {string} id The place id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaceDetails: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getPlaceDetails.');
            }
            const localVarPath = `/api/tenant/place/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TenantApi - functional programming interface
 * @export
 */
export const TenantApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all tenants
         * @param {number} start Zero-based page index
         * @param {number} size The page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllTenants(start: number, size: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<Tenant>>>> {
            const localVarAxiosArgs = await TenantApiAxiosParamCreator(configuration).findAllTenants(start, size, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the tenant data by id
         * @param {number} id The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findTenantById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Tenant>>> {
            const localVarAxiosArgs = await TenantApiAxiosParamCreator(configuration).findTenantById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the tenant data from Pandora
         * @param {string} id The pandora tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findTenantByIdFromPandora(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<PandoraTenantConfiguration>>> {
            const localVarAxiosArgs = await TenantApiAxiosParamCreator(configuration).findTenantByIdFromPandora(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get all tenants filtering by name or a place
         * @param {string} name The tenant or place name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findTenantByNameAndPlaceContaining(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Places>>> {
            const localVarAxiosArgs = await TenantApiAxiosParamCreator(configuration).findTenantByNameAndPlaceContaining(name, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get all tenants filtering by name
         * @param {string} name The tenant name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findTenantByNameContaining(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<Tenant>>>> {
            const localVarAxiosArgs = await TenantApiAxiosParamCreator(configuration).findTenantByNameContaining(name, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the tenant data by pandora id
         * @param {string} id The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findTenantByPandoraId(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Tenant>>> {
            const localVarAxiosArgs = await TenantApiAxiosParamCreator(configuration).findTenantByPandoraId(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the tenant data by crm tenant id
         * @param {string} id The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findTenantByTenantId(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Tenant>>> {
            const localVarAxiosArgs = await TenantApiAxiosParamCreator(configuration).findTenantByTenantId(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get data about a place
         * @param {string} id The place id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlaceDetails(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Place>>> {
            const localVarAxiosArgs = await TenantApiAxiosParamCreator(configuration).getPlaceDetails(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TenantApi - factory interface
 * @export
 */
export const TenantApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get all tenants
         * @param {number} start Zero-based page index
         * @param {number} size The page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllTenants(start: number, size: number, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<Tenant>>> {
            return TenantApiFp(configuration).findAllTenants(start, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the tenant data by id
         * @param {number} id The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findTenantById(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<Tenant>> {
            return TenantApiFp(configuration).findTenantById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the tenant data from Pandora
         * @param {string} id The pandora tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findTenantByIdFromPandora(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<PandoraTenantConfiguration>> {
            return TenantApiFp(configuration).findTenantByIdFromPandora(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all tenants filtering by name or a place
         * @param {string} name The tenant or place name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findTenantByNameAndPlaceContaining(name: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Places>> {
            return TenantApiFp(configuration).findTenantByNameAndPlaceContaining(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all tenants filtering by name
         * @param {string} name The tenant name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findTenantByNameContaining(name: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<Tenant>>> {
            return TenantApiFp(configuration).findTenantByNameContaining(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the tenant data by pandora id
         * @param {string} id The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findTenantByPandoraId(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Tenant>> {
            return TenantApiFp(configuration).findTenantByPandoraId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the tenant data by crm tenant id
         * @param {string} id The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findTenantByTenantId(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Tenant>> {
            return TenantApiFp(configuration).findTenantByTenantId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get data about a place
         * @param {string} id The place id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlaceDetails(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Place>> {
            return TenantApiFp(configuration).getPlaceDetails(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TenantApi - object-oriented interface
 * @export
 * @class TenantApi
 * @extends {BaseAPI}
 */
export class TenantApi extends BaseAPI {
    /**
     * 
     * @summary Get all tenants
     * @param {number} start Zero-based page index
     * @param {number} size The page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public async findAllTenants(start: number, size: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<Tenant>>> {
        return TenantApiFp(this.configuration).findAllTenants(start, size, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get the tenant data by id
     * @param {number} id The tenant id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public async findTenantById(id: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<Tenant>> {
        return TenantApiFp(this.configuration).findTenantById(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get the tenant data from Pandora
     * @param {string} id The pandora tenant id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public async findTenantByIdFromPandora(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<PandoraTenantConfiguration>> {
        return TenantApiFp(this.configuration).findTenantByIdFromPandora(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get all tenants filtering by name or a place
     * @param {string} name The tenant or place name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public async findTenantByNameAndPlaceContaining(name: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Places>> {
        return TenantApiFp(this.configuration).findTenantByNameAndPlaceContaining(name, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get all tenants filtering by name
     * @param {string} name The tenant name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public async findTenantByNameContaining(name: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<Tenant>>> {
        return TenantApiFp(this.configuration).findTenantByNameContaining(name, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get the tenant data by pandora id
     * @param {string} id The tenant id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public async findTenantByPandoraId(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Tenant>> {
        return TenantApiFp(this.configuration).findTenantByPandoraId(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get the tenant data by crm tenant id
     * @param {string} id The tenant id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public async findTenantByTenantId(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Tenant>> {
        return TenantApiFp(this.configuration).findTenantByTenantId(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get data about a place
     * @param {string} id The place id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public async getPlaceDetails(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Place>> {
        return TenantApiFp(this.configuration).getPlaceDetails(id, options).then((request) => request(this.axios, this.basePath));
    }
}
