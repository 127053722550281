<template>
  <main class="playDayWrapper">
  <h3 class="title-onboarding not-italic font-medium text-3xl text-center text-white">
      {{ $t('when_do_you_usually_play') }}
  </h3>
  <span class="subtitle-onboarding not-italic font-normal text-sm text-center block">
      {{ $t('select_your_favorite_day') }}
  </span>
  <div>
    <div clas="table-wrapper">
      <div class="row-table-days flex mt-4 mb-4">
        <span class="block w-2/5 text-xs"></span>
        <div class="input-table flex w-3/5 items-center">
          <SvgContainer name="playDayMorningIcon" />
          <SvgContainer name="playDayNoonIcon" />
          <SvgContainer name="playDayAfternoonIcon" />
        </div>
      </div>
      <div class="row-table-days items-center flex" 
        :class="getRowClass(index)"
        v-for="(day, index) in days" :key="index">
          <span class="block w-2/5 text-xs">{{ day }}</span>
          <div class="input-table flex w-3/5 items-center">
            <input
              class="cursor-pointer"
              type="checkbox"
              v-for="(isChecked, checkboxIndex) in checkboxes[index]"
              :key="checkboxIndex"
              :class="{ seleccionado: isChecked }"
              @click="toggleCheckbox(index, checkboxIndex)"
            />
          </div>
      </div>
    </div>
  </div>
</main>

</template>

<script>
import { ref, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import SvgContainer from '@/views/SvgContainer.vue';
import { useI18n } from 'vue-i18n';

export default {
  components: {
    SvgContainer
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const days = [//TODO INFO: https://www.ub.edu/llibre-estil/abreviacions.php?taula=DS&idioma=1 abreviaturas dias en catalán para poner en el fichero de traducciones
      t('mon'),
      t('tue'),
      t('wed'),
      t('thu'),
      t('fri'),
      t('sat'),
      t('sun')
    ];
    const checkboxes = ref([
      [false, false, false],
      [false, false, false],
      [false, false, false],
      [false, false, false],
      [false, false, false],
      [false, false, false],
      [false, false, false],
    ]);

    const toggleCheckbox = (dayIndex, checkboxIndex) => {
      checkboxes.value[dayIndex][checkboxIndex] = !checkboxes.value[dayIndex][checkboxIndex];
      store.commit('setPlayDayTimes', checkboxes);
    };
  
    //watch the store state changes and update the component variables accordingly
    watch(() => store.state.preferences.days, (newVal) => {
    checkboxes.value = newVal;
    });
      
    //Initialize the component variables with the store state on component mount
    onMounted(() => {
      if(store.state.preferences.days.length !== 0){
        checkboxes.value = store.state.preferences.days;
      }
    });

    const getRowClass = (index) => {
      return index % 2 !== 0 ? '' : 'table-row-blue';
    };

    return {
      days,
      checkboxes,
      toggleCheckbox,
      getRowClass,
    };
  },
  data() {
    return {
      error: ''
    };
  },
  methods: {
    clear() {
      this.error = '';
    }
  }
};
</script>

<style>
@media (max-width:767px) {
  .playDayWrapper {
    max-height: 400px;
    overflow: scroll;
    overflow-x: hidden;
  }
}

.playDayWrapper::-webkit-scrollbar {
  width: 1px; /* Ancho de la barra de desplazamiento */
}

/* Estilo para el riel de la barra de desplazamiento */
.playDayWrapper::-webkit-scrollbar-track {
  background-color: transparent; /* Color de fondo del riel */
}

/* Estilo para el pulgar de la barra de desplazamiento */
.playDayWrapper::-webkit-scrollbar-thumb {
  background-color: var(--color1); /* Color del pulgar */
}
</style>
