import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SvgContainer = _resolveComponent("SvgContainer")!

  return (_ctx.userlogged)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "absolute fav-icon p-2 cursor-pointer",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
      }, [
        (_ctx.isLiked)
          ? (_openBlock(), _createBlock(_component_SvgContainer, {
              key: 0,
              name: "imageSelectButtonRed"
            }))
          : (_openBlock(), _createBlock(_component_SvgContainer, {
              key: 1,
              name: "imageSelectButton"
            }))
      ]))
    : _createCommentVNode("", true)
}