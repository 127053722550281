<template>
    <div class="fixed top-16 left-[10%] z-[100]">
      <HistoryBack/>
    </div>
    <div class="flex content-center flex-col items-center justify-center text-sm p-5 br16 mt-24">
        <div
          @click="goUser"
          class="items-center cursor-pointer flex p-2 w-60 xxsm:w-36 m-center"
        >
          <div class="mr-2">
            <img src="../assets/images/User.svg" alt="" />
          </div>
          <div>{{ $t("my_profile") }}</div>
        </div>
        <div
          @click="goMatches"
          class="items-center cursor-pointer flex p-2 w-60 xxsm:w-36 m-center"
        >
          <div class="mr-2 profileIcon">
            <img :src="matchIconSelected"/>
          </div>
          <div>{{ $t("my_matches") }}</div>
        </div>
        <div
          @click="goPays"
          class="items-center cursor-pointer flex p-2 w-60 xxsm:w-36 m-center"
        >
          <div class="mr-2">
            <SvgContainer name="myPayIcon" />
          </div>
          <div>{{ $t("my_payments") }}</div>
        </div>
        <div
          @click="showOnBoarding"
          class="items-center cursor-pointer flex p-2 w-60 xxsm:w-36 m-center"
        >
        <div class="mr-2">
            <SvgContainer name="normalCircleIcon" />
          </div>
          <div>{{ $t("my_preferences") }}</div>
        </div>
        <div
          @click="goLikes"
          class="items-center cursor-pointer flex p-2 w-60 xxsm:w-36 m-center"
        >
          <div class="mr-2">
            <SvgContainer name="myFavIcon" />
          </div>
          <div>{{ $t("my_favorites") }}</div>
        </div>
        <div
          @click="goPrivacy"
          class="items-center cursor-pointer flex p-2 w-60 xxsm:w-36 m-center"
        >
          <div class="mr-2">
            <SvgContainer name="circleIcon" />
          </div>
          <div>{{ $t("my_privacy") }}</div>
        </div>
        <div
          @click="goClubs"
          class="items-center cursor-pointer flex p-2 w-60 xxsm:w-36 m-center"
        >
          <div class="mr-2">
            <SvgContainer name="myClubIcon" />
          </div>
          <div>{{ $t("my_linked_clubs") }}</div>
        </div>

        <div
          @click="$router.push('/remove-account')"
          class="items-center cursor-pointer flex p-2 w-60 xxsm:w-36 m-center"
        >
          <div class="mr-2">
            <SvgContainer name="circleIcon" />
          </div>
          <div>{{ $t("remove_account") }}</div>
        </div>
        <div
          @click="logout"
          class="items-center cursor-pointer flex p-2 w-60 xxsm:w-36 m-center"
        >
          <div class="mr-2 profileIcon">
            <img src="../assets/images/LogOut.svg" alt="" />
          </div>
          <div>{{ $t("logout") }}</div>
        </div>
        <Onboarding @refresh="handleRefresh" />
      </div>
  </template>

<script lang="ts">
import { defineComponent } from "vue";
import store from "../store";
import CustomerHelper from "@/helpers/customerHelper";
import SvgContainer from "@/views/SvgContainer.vue";
import { Sport, MIN_LEVEL, MAX_LEVEL } from "@/enum/constants";
import HistoryBack from "@/components/form/historyBack.vue";
import Onboarding from "./onboarding/onboarding.vue";
import { AppsHelper } from "@/helpers/appsHelper";
import matchIconSelected from "@/assets/images/activityBar/matchIconSelected.svg";

export default defineComponent({
  name:"MenuProfile",
  data() {
    return {
      open: false,
      showGeneralProfile: false,
      results: null,
      sport: Sport.PADEL,
      sportsArray: [],
      mounted: false,
      customerId: "",
      email: "",
      MIN_LEVEL:MIN_LEVEL,
      MAX_LEVEL:MAX_LEVEL,
      isLoading: false,
      matchIconSelected:matchIconSelected
      
    };
  },
  props: ["isOpen"],
  emits: ["refresh"],
  components: {
    SvgContainer,
    HistoryBack,
    Onboarding,
  },
  mounted() {
    this.open = this.isOpen;
  },
  methods: {
    logout() {
      CustomerHelper.logout(store);
      this.open = false;
      this.$emit("refresh");
    },
    goUser(){
      this.$router.push("/user");
    },
    goMatches(){
      this.$router.push("/my-matches");
    },
    goPays(){
      this.$router.push("/my-pays");
    },
    goLikes(){
      this.$router.push("/my-likes");
    },
    goClubs(){
      this.$router.push("/my-clubs");
    },
    goPrivacy(){
      this.$router.push("/my-privacy");
    },
    async handleRefreshPrivacy(privacy: any) {
      CustomerHelper.updateStoragePrivacy(privacy);
    },
    async handleRefresh() {
      this.isLoading = true;
      const preferences = await CustomerHelper.getPreferences();
      CustomerHelper.updateStoragePreferences(preferences);
      this.isLoading = false;
    },
    showOnBoarding() {
      const jsonData = {
        myPreferences: "1",
      };
      if (AppsHelper.sendToIOS(window, jsonData)) {
        return;
      }
      store.commit("openOnboarding");
    },
  },
});
</script>
<style scoped>
  .profileIcon{
    width: 16px;
    height: 16px;
  }
</style>