import { Format } from "@/helpers/formatHelper";
import { TenantApi } from "@/service/TenantApi";
import { AxiosResponse } from "axios";

export class TenantHelper {

    static async findTenantByIdFromPandora(id: string) {
      const response = await TenantApi.findTenantByIdFromPandora(id)
      return this.checkResponse(response);
    }
    
    static checkResponse(response:AxiosResponse) {
      if(!Format.IsNull(response.data)){
        return response.data
      }
      return null;
    }
}