<template>
  <div class="flex flex-wrap paddig-responsive">
    <div class="flex w-full text-left center-responsive">
      <h2>{{ $t("level_you_are_question") }}</h2>
    </div>
    <div class="flex w-full text-left">
      <p>{{ $t("dont_overrate_yourself") }}</p>
    </div>
    <div class="flex w-full mt-8">
      <div class="flex flex-col">
        <template v-for="option in options" :key="option.id">
          <label class="flex items-center mb-2">
            <input type="radio" :value="option.value" v-model="actualLevel" @change="showValue()" class="mr-2">
            <span>{{ option.label }}</span>
          </label>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { SelectOption } from "@/custom-models/levelingSelector";
  
export default defineComponent({
  name: 'ActualLevelSelector',
  props: {
    propLevel:{ 
      type: Number,
      default: 0.1 
    }
  },
  created (){
    this.actualLevel = this.propLevel;
  },
  data() {
    return {
      actualLevel: 0.1,
      options: [
        { id: 1, label: this.$t("beginner"), value: 0.1 },
        { id: 2, label: this.$t("intermediate"), value: 1.0 },
        { id: 3, label: this.$t("high_intermediate"), value: 2.0 },
        { id: 4, label: this.$t("advanced"), value: 3.0},
        { id: 5, label: this.$t("competitive"), value: 4.0 },
      ] as SelectOption[]
    };
  },
  methods: {//this.$t("sorry_this_club_does_not_have_as_a_sport", { sport: getSportLabel(this.activeSport)}) loco: Sorry, this club does not have {sport} as a sport.
    showValue() {
      this.$emit("selectedActualLevel", this.actualLevel);
    }
  }
});
</script>