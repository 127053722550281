const clickOutside = {
    beforeMount: (el: any, binding: any) => {
        // Define a click event listener
        el.clickOutsideEvent = (event: any) => {
            // Check if the clicked element is outside the bound element
            if (!(el === event.target || el.contains(event.target))) {
                // Call the provided method when a click outside occurs
                binding.value();
            }
        };

        // Attach the click event listener to the document
        document.addEventListener('click', el.clickOutsideEvent);
    },
    unmounted: (el: any) => {
        // Remove the click event listener when the element is unmounted
        document.removeEventListener('click', el.clickOutsideEvent);
    },
};

export default clickOutside;