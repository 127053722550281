import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4abb709c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mt-8" }
const _hoisted_2 = { class: "flex p-6 justify-around" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BorderButton = _resolveComponent("BorderButton")!
  const _component_PopUpLight = _resolveComponent("PopUpLight")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "cancelWalletButton pl-6 pr-6 relative cursor-pointer",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.confirmCancelBooking($event)))
    }, _toDisplayString(_ctx.$t("cancel")), 1),
    _createVNode(_Transition, null, {
      default: _withCtx(() => [
        (_ctx.showConfirmCancel)
          ? (_openBlock(), _createBlock(_component_PopUpLight, {
              key: 0,
              showOkButton: true,
              onClosed: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showConfirmCancel = false))
            }, {
              default: _withCtx(() => [
                _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t("are_u_sure_cancel_booking_question")), 1),
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_BorderButton, {
                    onClicked: _ctx.cancelBooking,
                    text: _ctx.$t('confirm')
                  }, null, 8, ["onClicked", "text"]),
                  _createVNode(_component_BorderButton, {
                    onClicked: _ctx.closeConfirm,
                    text: _ctx.$t('cancel')
                  }, null, 8, ["onClicked", "text"])
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}