import { Format } from "./formatHelper";
import { MatchApi } from "@/service/MatchApi";
import { AxiosResponse } from "axios";
import { MatchRequest } from 'models/match-request';

export default class MatchHelper {

  static async createMatch(body: MatchRequest) {
    const response = await MatchApi.createMatch(body)
    return response;//not call checkResponse because status is needed
  }

  static async createMatchWithoutSale(body: MatchRequest) {
    const response = await MatchApi.createMatchWithoutSale(body)
    return this.checkResponse(response);
  }

  static async findAllMatches(start: number, size: number) {
    const response = await MatchApi.findAllMatches(start, size)
    return this.checkResponse(response);
  }
  
  static checkResponse(response:AxiosResponse) {
    if(!Format.IsNull(response.data)){
      return response.data
    }
    return null;
  }

}

