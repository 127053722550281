
<template>
  <div class="modal-container">
    <div class="modal-popup relative md:mt-16" :style="backgroundImage">
      <span
        v-if="$store.state.allowClosePopup || showCancelButton"
        class="close"
        @click="closed()"
        >×</span
      >
      <div>
        <div class="modal-body">
          <div
            v-if="errorMsg != null && errorMsg != ''"
            class="warning"
            v-html="errorMsg"
          ></div>
          <main>
            <slot></slot>
          </main>
        </div>
      </div>
      <div
        v-bind:class="
          $store.state.loginIsOpen
            ? 'login-modal-footer'
            : 'register-modal-footer'
        "
        class="modal-footer text-center message"
      >
        <footer>
          <slot name="footer">
            <a href="#" v-if="showOkButton" @click="ok()" ref="okBtn" class="">
              {{ defaultTextOkButton }}</a
            >
          </slot>
        </footer>
      </div>
    </div>
  </div>
</template>


<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PopUp",
  components: {},
  props: {
    errorMsg: String,
    showOkButton: Boolean,
    showCancelButton: Boolean,
    textOkButton: { type: String },
    textCloseButton: { type: String },
    disableOkButton: Boolean,
    forceFullWidth: Boolean,
  },
  data() {
    return {
      backgroundImage: {
        backgroundImage: /*this.clubData ?*/ require("@/assets/images/footer-popup.png") /*: 'none' */,
      },
    };
  },
  methods: {
    ok() {
      this.$emit("ok");
    },

    closed() {
      this.$emit("closed");
    },
    defaultTextOkButton() {
      return this.$t("ok");
    },
    defaultTextCloseButton() {
      return this.$t("close");
    },
  },
});
</script>

<style  scoped>
@import "./../popup/popup.css";
</style>