<template>
  <div>
    <div class="mb-4">{{ $t("price") }}</div>
    <div class="flex justify-center w-full input-component relative">
      <div
        class="custom-select mr-4"
        @click="this.isDropdownOpen = !this.isDropdownOpen"
      >
        <div class="selected-option">
          <p>{{ currency.symbol }}</p>
        </div>
        <ul v-if="isDropdownOpen" class="dropdown-options">
          <li
            v-for="symbol in getCurrencies().filter(
              (obj, index, self) =>
                index === self.findIndex((o) => o.code === obj.code)
            )"
            :key="symbol.code"
            @click="changeCurrency(symbol)"
          >
            <span>{{ symbol.symbol }}</span>
          </li>
        </ul>
      </div>
      <!-- <SvgContainer name="inputPhoneIcon" /> -->
      <input
        class="w-full input-rounded !pl-4"
        :class="{ 'is-invalid': !isValid }"
        :readonly="readonly"
        type="text"
        :placeholder="$t('price')"
        v-model="price"
        @input="change"
        pattern="\d+(\.\d{0,2})?"
      />
    </div>
    <p v-if="error" class="error-message">{{ error }}</p>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Format } from "@/helpers/formatHelper";

export default defineComponent({
  name: "inputPrice",
  components: {},
  props: {
    modelValue: {},
    readonly: {
      type: Boolean,
      default: true,
    },
    
  },
  data() {
    return {
      price: null,
      currency: "",
      error: "",
      isDropdownOpen: false,
    };
  },
  computed: {
    isValid() {
      return this.error === "";
    },
  },
  mounted() {
    this.currency = this.getBrowserCurrency();
  },
  methods: {
    change() {
      const regex = /^\d+(\.\d{0,2})?$/;
      // If the input doesn't match the pattern, remove the last character
      if (!Format.IsNull(this.price) && !regex.test(this.price)) {
        this.price = this.price.slice(0, -1);
      }
      this.$emit("update:modelValue", {
        price: this.price,
        currency: this.currency.code,
      });
    },
    changeCurrency(currency) {
      this.currency = currency;
      this.change();
    },
    getCurrencies() {
      return [
        { language: "en-US", code: "USD", symbol: "$" },
        { language: "es-ES", code: "EUR", symbol: "€" },
        { language: "es", code: "EUR", symbol: "€" },
        { language: "es-MX", code: "MXN", symbol: "(Mex)$" },
        { language: "fr-FR", code: "EUR", symbol: "€" },
        { language: "de-DE", code: "EUR", symbol: "€" },
        { language: "it-IT", code: "EUR", symbol: "€" },
        { language: "pt-PT", code: "EUR", symbol: "€" },
        { language: "pt-BR", code: "BRL", symbol: "R$" },
        { language: "ru-RU", code: "RUB", symbol: "₽" },
        { language: "ja-JP", code: "JPY", symbol: "¥" },
        { language: "ko-KR", code: "KRW", symbol: "₩" },
        { language: "zh-CN", code: "CNY", symbol: "¥" },
        { language: "hi-IN", code: "INR", symbol: "₹" },
        { language: "ar-SA", code: "SAR", symbol: "﷼" },
        { language: "tr-TR", code: "TRY", symbol: "₺" },
        { language: "id-ID", code: "IDR", symbol: "Rp" },
        { language: "th-TH", code: "THB", symbol: "฿" },
        { language: "vi-VN", code: "VND", symbol: "₫" },
        { language: "pl-PL", code: "PLN", symbol: "zł" },
        { language: "nl-NL", code: "EUR", symbol: "€" },
      ];
    },
    getBrowserCurrency() {
      const userLanguage = navigator.language;
      const currency = this.getCurrencies().find(
        (cur) => cur.language === userLanguage
      );

      if (currency) {
        return currency;
      }
      return { code: "USD", symbol: "$" };
    },
  },
});
</script>

<style scoped >
.custom-select {
  position: relative;
  cursor: pointer;
  align-items: center;
}

.selected-option {
  display: flex;
  align-items: center;
  -webkit-backdrop-filter: blur(16px);
  backdrop-filter: blur(16px);
  height: 44px;
  padding: 10px;
  font-size: 14px;
  background: rgba(255, 255, 255, 0.02);
  border: 1px solid;
  border-radius: 24px;
  box-shadow: inset 0px 0px 12px rgba(255, 255, 255, 0.15);
  border-bottom-color: #264b67;
  border-left-color: #264b67;
  border-right-color: #076ae3;
  border-top-color: #264b67;
}

.flag-icon {
  width: 24px;
  height: 18px;
  margin-right: 5px;
}
.dropdown-options {
  max-height: 200px;
  width: fit-content;
  overflow-y: auto;
  -webkit-backdrop-filter: blur(16px);
  backdrop-filter: blur(16px);
  padding-left: 6px;
  font-size: 14px;
  background: rgba(255, 255, 255, 0.02);
  border: 1px solid;
  border-radius: 24px;
  box-shadow: inset 0px 0px 12px rgba(255, 255, 255, 0.15);
  border-bottom-color: #264b67;
  border-left-color: #264b67;
  border-right-color: #076ae3;
  border-top-color: #264b67;
}

.dropdown-options li {
  padding: 6px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dropdown-options img {
  width: 24px;
  height: 18px;
  margin-right: 6px;
}

.dropdown-options li:hover {
  background-color: #6f91da;
}
</style>
