/* tslint:disable */
/* eslint-disable */
/**
 * Marketplace Api
 * Marketplace Api description
 *
 * OpenAPI spec version: 1.0
 * Contact: develop@taykus.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { CreateStripePaymentIntentRequest } from '../models';
import { GenericResponse } from '../models';
import { Payment } from '../models';
import { PaymentProviderData } from '../models';
import { PaymentResponse } from '../models';
import { StartTransbankRequest } from '../models';
import { StripePaymentIntentStatus } from '../models';
import { TransbankValidatorResponse } from '../models';
/**
 * PaymentApi - axios parameter creator
 * @export
 */
export const PaymentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Service to call when an Azul payment was approved
         * @param {GenericResponse} azulResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveAzulPayment: async (azulResponse: GenericResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'azulResponse' is not null or undefined
            if (azulResponse === null || azulResponse === undefined) {
                throw new RequiredError('azulResponse','Required parameter azulResponse was null or undefined when calling approveAzulPayment.');
            }
            const localVarPath = `/api/payment/azul/approve`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (azulResponse !== undefined) {
                localVarQueryParameter['azulResponse'] = azulResponse;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Service to call when an Azul payment was cancelled
         * @param {GenericResponse} azulResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelAzulPayment: async (azulResponse: GenericResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'azulResponse' is not null or undefined
            if (azulResponse === null || azulResponse === undefined) {
                throw new RequiredError('azulResponse','Required parameter azulResponse was null or undefined when calling cancelAzulPayment.');
            }
            const localVarPath = `/api/payment/azul/cancel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (azulResponse !== undefined) {
                localVarQueryParameter['azulResponse'] = azulResponse;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateStripePaymentIntentRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStripePaymentIntent: async (body: CreateStripePaymentIntentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createStripePaymentIntent.');
            }
            const localVarPath = `/api/payment/stripe/create-payment-intent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers!['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Service to call when an Azul payment was declined
         * @param {GenericResponse} azulResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        declineAzulPayment: async (azulResponse: GenericResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'azulResponse' is not null or undefined
            if (azulResponse === null || azulResponse === undefined) {
                throw new RequiredError('azulResponse','Required parameter azulResponse was null or undefined when calling declineAzulPayment.');
            }
            const localVarPath = `/api/payment/azul/decline`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (azulResponse !== undefined) {
                localVarQueryParameter['azulResponse'] = azulResponse;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Ends the authorization process
         * @param {string} cres 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        endAuthorization: async (cres: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cres' is not null or undefined
            if (cres === null || cres === undefined) {
                throw new RequiredError('cres','Required parameter cres was null or undefined when calling endAuthorization.');
            }
            const localVarPath = `/api/payment/redsys/auth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cres !== undefined) {
                localVarQueryParameter['cres'] = cres;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the payments of a customer
         * @param {number} start Zero-based page index
         * @param {number} size The page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPaymentByCustomer: async (start: number, size: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'start' is not null or undefined
            if (start === null || start === undefined) {
                throw new RequiredError('start','Required parameter start was null or undefined when calling findPaymentByCustomer.');
            }
            // verify required parameter 'size' is not null or undefined
            if (size === null || size === undefined) {
                throw new RequiredError('size','Required parameter size was null or undefined when calling findPaymentByCustomer.');
            }
            const localVarPath = `/api/payment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the payments of a sale
         * @param {string} id The sale id
         * @param {number} start Zero-based page index
         * @param {number} size The page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPaymentBySale: async (id: string, start: number, size: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling findPaymentBySale.');
            }
            // verify required parameter 'start' is not null or undefined
            if (start === null || start === undefined) {
                throw new RequiredError('start','Required parameter start was null or undefined when calling findPaymentBySale.');
            }
            // verify required parameter 'size' is not null or undefined
            if (size === null || size === undefined) {
                throw new RequiredError('size','Required parameter size was null or undefined when calling findPaymentBySale.');
            }
            const localVarPath = `/api/payment/sale/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the payment provider data to use in a specific country
         * @param {string} countryCode The ISO 3166-2 country code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentProviderData: async (countryCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'countryCode' is not null or undefined
            if (countryCode === null || countryCode === undefined) {
                throw new RequiredError('countryCode','Required parameter countryCode was null or undefined when calling getPaymentProviderData.');
            }
            const localVarPath = `/api/payment/country/{countryCode}`
                .replace(`{${"countryCode"}}`, encodeURIComponent(String(countryCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id The operation id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStripePaymentIntentStatus: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getStripePaymentIntentStatus.');
            }
            const localVarPath = `/api/payment/stripe/status/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set the payment 3DS data
         * @param {string} threeDSMethodData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redsys3DSMethod: async (threeDSMethodData: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'threeDSMethodData' is not null or undefined
            if (threeDSMethodData === null || threeDSMethodData === undefined) {
                throw new RequiredError('threeDSMethodData','Required parameter threeDSMethodData was null or undefined when calling redsys3DSMethod.');
            }
            const localVarPath = `/api/payment/redsys/3ds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (threeDSMethodData !== undefined) {
                localVarQueryParameter['threeDSMethodData'] = threeDSMethodData;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StartTransbankRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startTransaction: async (body: StartTransbankRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling startTransaction.');
            }
            const localVarPath = `/api/payment/transbank/start-transaction`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers!['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/payment/transbank/validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentApi - functional programming interface
 * @export
 */
export const PaymentApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Service to call when an Azul payment was approved
         * @param {GenericResponse} azulResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approveAzulPayment(azulResponse: GenericResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await PaymentApiAxiosParamCreator(configuration).approveAzulPayment(azulResponse, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Service to call when an Azul payment was cancelled
         * @param {GenericResponse} azulResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelAzulPayment(azulResponse: GenericResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await PaymentApiAxiosParamCreator(configuration).cancelAzulPayment(azulResponse, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CreateStripePaymentIntentRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createStripePaymentIntent(body: CreateStripePaymentIntentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<{ [key: string]: string; }>>> {
            const localVarAxiosArgs = await PaymentApiAxiosParamCreator(configuration).createStripePaymentIntent(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Service to call when an Azul payment was declined
         * @param {GenericResponse} azulResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async declineAzulPayment(azulResponse: GenericResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<PaymentResponse>>> {
            const localVarAxiosArgs = await PaymentApiAxiosParamCreator(configuration).declineAzulPayment(azulResponse, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Ends the authorization process
         * @param {string} cres 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async endAuthorization(cres: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await PaymentApiAxiosParamCreator(configuration).endAuthorization(cres, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the payments of a customer
         * @param {number} start Zero-based page index
         * @param {number} size The page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findPaymentByCustomer(start: number, size: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<Payment>>>> {
            const localVarAxiosArgs = await PaymentApiAxiosParamCreator(configuration).findPaymentByCustomer(start, size, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the payments of a sale
         * @param {string} id The sale id
         * @param {number} start Zero-based page index
         * @param {number} size The page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findPaymentBySale(id: string, start: number, size: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<Payment>>>> {
            const localVarAxiosArgs = await PaymentApiAxiosParamCreator(configuration).findPaymentBySale(id, start, size, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the payment provider data to use in a specific country
         * @param {string} countryCode The ISO 3166-2 country code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaymentProviderData(countryCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<PaymentProviderData>>> {
            const localVarAxiosArgs = await PaymentApiAxiosParamCreator(configuration).getPaymentProviderData(countryCode, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id The operation id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStripePaymentIntentStatus(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<StripePaymentIntentStatus>>> {
            const localVarAxiosArgs = await PaymentApiAxiosParamCreator(configuration).getStripePaymentIntentStatus(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Set the payment 3DS data
         * @param {string} threeDSMethodData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async redsys3DSMethod(threeDSMethodData: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await PaymentApiAxiosParamCreator(configuration).redsys3DSMethod(threeDSMethodData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {StartTransbankRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startTransaction(body: StartTransbankRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<{ [key: string]: any; }>>> {
            const localVarAxiosArgs = await PaymentApiAxiosParamCreator(configuration).startTransaction(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<TransbankValidatorResponse>>>> {
            const localVarAxiosArgs = await PaymentApiAxiosParamCreator(configuration).validate(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PaymentApi - factory interface
 * @export
 */
export const PaymentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Service to call when an Azul payment was approved
         * @param {GenericResponse} azulResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approveAzulPayment(azulResponse: GenericResponse, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return PaymentApiFp(configuration).approveAzulPayment(azulResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Service to call when an Azul payment was cancelled
         * @param {GenericResponse} azulResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelAzulPayment(azulResponse: GenericResponse, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return PaymentApiFp(configuration).cancelAzulPayment(azulResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateStripePaymentIntentRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createStripePaymentIntent(body: CreateStripePaymentIntentRequest, options?: AxiosRequestConfig): Promise<AxiosResponse<{ [key: string]: string; }>> {
            return PaymentApiFp(configuration).createStripePaymentIntent(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Service to call when an Azul payment was declined
         * @param {GenericResponse} azulResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async declineAzulPayment(azulResponse: GenericResponse, options?: AxiosRequestConfig): Promise<AxiosResponse<PaymentResponse>> {
            return PaymentApiFp(configuration).declineAzulPayment(azulResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Ends the authorization process
         * @param {string} cres 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async endAuthorization(cres: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return PaymentApiFp(configuration).endAuthorization(cres, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the payments of a customer
         * @param {number} start Zero-based page index
         * @param {number} size The page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findPaymentByCustomer(start: number, size: number, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<Payment>>> {
            return PaymentApiFp(configuration).findPaymentByCustomer(start, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the payments of a sale
         * @param {string} id The sale id
         * @param {number} start Zero-based page index
         * @param {number} size The page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findPaymentBySale(id: string, start: number, size: number, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<Payment>>> {
            return PaymentApiFp(configuration).findPaymentBySale(id, start, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the payment provider data to use in a specific country
         * @param {string} countryCode The ISO 3166-2 country code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaymentProviderData(countryCode: string, options?: AxiosRequestConfig): Promise<AxiosResponse<PaymentProviderData>> {
            return PaymentApiFp(configuration).getPaymentProviderData(countryCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id The operation id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStripePaymentIntentStatus(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<StripePaymentIntentStatus>> {
            return PaymentApiFp(configuration).getStripePaymentIntentStatus(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set the payment 3DS data
         * @param {string} threeDSMethodData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async redsys3DSMethod(threeDSMethodData: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return PaymentApiFp(configuration).redsys3DSMethod(threeDSMethodData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StartTransbankRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startTransaction(body: StartTransbankRequest, options?: AxiosRequestConfig): Promise<AxiosResponse<{ [key: string]: any; }>> {
            return PaymentApiFp(configuration).startTransaction(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validate(options?: AxiosRequestConfig): Promise<AxiosResponse<Array<TransbankValidatorResponse>>> {
            return PaymentApiFp(configuration).validate(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentApi - object-oriented interface
 * @export
 * @class PaymentApi
 * @extends {BaseAPI}
 */
export class PaymentApi extends BaseAPI {
    /**
     * 
     * @summary Service to call when an Azul payment was approved
     * @param {GenericResponse} azulResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public async approveAzulPayment(azulResponse: GenericResponse, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return PaymentApiFp(this.configuration).approveAzulPayment(azulResponse, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Service to call when an Azul payment was cancelled
     * @param {GenericResponse} azulResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public async cancelAzulPayment(azulResponse: GenericResponse, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return PaymentApiFp(this.configuration).cancelAzulPayment(azulResponse, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {CreateStripePaymentIntentRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public async createStripePaymentIntent(body: CreateStripePaymentIntentRequest, options?: AxiosRequestConfig) : Promise<AxiosResponse<{ [key: string]: string; }>> {
        return PaymentApiFp(this.configuration).createStripePaymentIntent(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Service to call when an Azul payment was declined
     * @param {GenericResponse} azulResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public async declineAzulPayment(azulResponse: GenericResponse, options?: AxiosRequestConfig) : Promise<AxiosResponse<PaymentResponse>> {
        return PaymentApiFp(this.configuration).declineAzulPayment(azulResponse, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Ends the authorization process
     * @param {string} cres 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public async endAuthorization(cres: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return PaymentApiFp(this.configuration).endAuthorization(cres, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get the payments of a customer
     * @param {number} start Zero-based page index
     * @param {number} size The page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public async findPaymentByCustomer(start: number, size: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<Payment>>> {
        return PaymentApiFp(this.configuration).findPaymentByCustomer(start, size, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get the payments of a sale
     * @param {string} id The sale id
     * @param {number} start Zero-based page index
     * @param {number} size The page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public async findPaymentBySale(id: string, start: number, size: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<Payment>>> {
        return PaymentApiFp(this.configuration).findPaymentBySale(id, start, size, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get the payment provider data to use in a specific country
     * @param {string} countryCode The ISO 3166-2 country code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public async getPaymentProviderData(countryCode: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<PaymentProviderData>> {
        return PaymentApiFp(this.configuration).getPaymentProviderData(countryCode, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} id The operation id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public async getStripePaymentIntentStatus(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<StripePaymentIntentStatus>> {
        return PaymentApiFp(this.configuration).getStripePaymentIntentStatus(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Set the payment 3DS data
     * @param {string} threeDSMethodData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public async redsys3DSMethod(threeDSMethodData: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return PaymentApiFp(this.configuration).redsys3DSMethod(threeDSMethodData, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {StartTransbankRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public async startTransaction(body: StartTransbankRequest, options?: AxiosRequestConfig) : Promise<AxiosResponse<{ [key: string]: any; }>> {
        return PaymentApiFp(this.configuration).startTransaction(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public async validate(options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<TransbankValidatorResponse>>> {
        return PaymentApiFp(this.configuration).validate(options).then((request) => request(this.axios, this.basePath));
    }
}
