import { AxiosRequestConfig } from 'axios';
import { AuthorizationApiAxiosParamCreator } from '../../apis/authorization-api';
import { Token } from '../../models';
import { BaseApi } from './BaseApi';
import { USER_STATUS } from "@/enum/constants"

export class AuthorizationApi extends BaseApi {
  
  

  /**
   * Authenticate a customer using username/password
   * @param {string} username The customer's username
   * @param {string} password The customer's password
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  public static async authenticate(username: string, password: string, options?: AxiosRequestConfig) {
    const axiosArgs = await AuthorizationApiAxiosParamCreator().authenticate(username, password, options);

    const response = this.getAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
    const token = (await response).data.value;
    localStorage.setItem('t', JSON.stringify(token))
    localStorage.setItem('auth', USER_STATUS.LOG_IN);
  }

  /**
   *
   * @summary Authenticate a customer using a Google JWT token
   * @param {string} token Google JWT
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  public static async authenticateWithGoogle(token: string, options?: AxiosRequestConfig) {
    const axiosArgs = await AuthorizationApiAxiosParamCreator().authenticateWithGoogle(token, options);
    const response = this.getAxios().request<Token>({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
    const googleToken = (await response).data.value;
    localStorage.setItem('t', JSON.stringify(googleToken))
    localStorage.setItem('auth', USER_STATUS.LOG_IN);
    return response;
  }

  /**
   *
   * @summary Authenticate a customer using a Google access token
   * @param {string} token Google access token
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  public static async authenticateWithGoogleAccessToken(token: string, options?: AxiosRequestConfig) {
    const axiosArgs = await AuthorizationApiAxiosParamCreator().authenticateWithGoogleAccessToken(token, options);
    const response = this.getAxios().request<Token>({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
    const googleToken = (await response).data.value;
    localStorage.setItem('t', JSON.stringify(googleToken))
    localStorage.setItem('auth', USER_STATUS.LOG_IN);
    return response;
  }

  /**
   * 
   * @summary Authenticate a customer using Apple sign on token
   * @param {string} token Apple JWT
   * @param {string} code Apple authorization code
   * @param {string} [fullName] The full name of the user
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
    public static async authenticateWithApple(token: string, code: string, fullName?: string, options?: AxiosRequestConfig) {
      const axiosArgs = await AuthorizationApiAxiosParamCreator().authenticateWithApple(token, code, fullName, options);
      const response = this.getAxios().request<Token>({
        url: axiosArgs.url,
        method: axiosArgs.options.method,
        headers: axiosArgs.options.headers,
        params: axiosArgs.options.params,
        data: axiosArgs.options.data,
      });
      const googleToken = (await response).data.value;
      localStorage.setItem('t', JSON.stringify(googleToken))
      localStorage.setItem('auth', USER_STATUS.LOG_IN);
      return response;
    }
}
