/* tslint:disable */
/* eslint-disable */
/**
 * Marketplace Api
 * Marketplace Api description
 *
 * OpenAPI spec version: 1.0
 * Contact: develop@taykus.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
/**
 * EmailApi - axios parameter creator
 * @export
 */
export const EmailApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Sent an email to contact with Tenants
         * @param {string} tenantName The tenant name
         * @param {string} name The owner name
         * @param {string} email The email address
         * @param {string} phonePrefix The phone prefix
         * @param {string} phone The phone number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmail: async (tenantName: string, name: string, email: string, phonePrefix: string, phone: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantName' is not null or undefined
            if (tenantName === null || tenantName === undefined) {
                throw new RequiredError('tenantName','Required parameter tenantName was null or undefined when calling sendEmail.');
            }
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling sendEmail.');
            }
            // verify required parameter 'email' is not null or undefined
            if (email === null || email === undefined) {
                throw new RequiredError('email','Required parameter email was null or undefined when calling sendEmail.');
            }
            // verify required parameter 'phonePrefix' is not null or undefined
            if (phonePrefix === null || phonePrefix === undefined) {
                throw new RequiredError('phonePrefix','Required parameter phonePrefix was null or undefined when calling sendEmail.');
            }
            // verify required parameter 'phone' is not null or undefined
            if (phone === null || phone === undefined) {
                throw new RequiredError('phone','Required parameter phone was null or undefined when calling sendEmail.');
            }
            const localVarPath = `/api/email/contact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tenantName !== undefined) {
                localVarQueryParameter['tenantName'] = tenantName;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (phonePrefix !== undefined) {
                localVarQueryParameter['phonePrefix'] = phonePrefix;
            }

            if (phone !== undefined) {
                localVarQueryParameter['phone'] = phone;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmailApi - functional programming interface
 * @export
 */
export const EmailApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Sent an email to contact with Tenants
         * @param {string} tenantName The tenant name
         * @param {string} name The owner name
         * @param {string} email The email address
         * @param {string} phonePrefix The phone prefix
         * @param {string} phone The phone number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendEmail(tenantName: string, name: string, email: string, phonePrefix: string, phone: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await EmailApiAxiosParamCreator(configuration).sendEmail(tenantName, name, email, phonePrefix, phone, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * EmailApi - factory interface
 * @export
 */
export const EmailApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Sent an email to contact with Tenants
         * @param {string} tenantName The tenant name
         * @param {string} name The owner name
         * @param {string} email The email address
         * @param {string} phonePrefix The phone prefix
         * @param {string} phone The phone number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendEmail(tenantName: string, name: string, email: string, phonePrefix: string, phone: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return EmailApiFp(configuration).sendEmail(tenantName, name, email, phonePrefix, phone, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmailApi - object-oriented interface
 * @export
 * @class EmailApi
 * @extends {BaseAPI}
 */
export class EmailApi extends BaseAPI {
    /**
     * 
     * @summary Sent an email to contact with Tenants
     * @param {string} tenantName The tenant name
     * @param {string} name The owner name
     * @param {string} email The email address
     * @param {string} phonePrefix The phone prefix
     * @param {string} phone The phone number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public async sendEmail(tenantName: string, name: string, email: string, phonePrefix: string, phone: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return EmailApiFp(this.configuration).sendEmail(tenantName, name, email, phonePrefix, phone, options).then((request) => request(this.axios, this.basePath));
    }
}
