<template>
  <div class="flex justify-center mt-6 w-full input-component relative">
    <PhonePrefix
      class="w-full"
      v-model="prefix"
      @selectedPrefix="handleSelectedPrefix"
    />
    <div class="flex w-full input-component relative">
      <SvgContainer name="inputPhoneIcon" />
      <input
        class="input-rounded"
        :class="{ 'is-invalid': !isValid }"
        :readonly="readonly"
        type="text"
        id="phone"
        maxlength="40"
        :placeholder="$t('phone_number')"
        v-model="phone"
        @input="validatePhone"
      />
    </div>
  </div>
  <div class="flex justify-start">
    <p v-if="error" class="error-message max-w-xs whitespace-normal">{{ error }}</p>
  </div>
</template>

<script>
import SvgContainer from "@/views/SvgContainer.vue";
import PhonePrefix from "@/components/countryFlagSelector/phonePrefix.vue";

export default {
  name: "inputPhone",
  components: {
    SvgContainer,
    PhonePrefix,
  },
  emits: ["phoneValidated"],
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      phone: "",
      prefix: "",
      error: "",
    };
  },
  computed: {
    isValid() {
      return this.error === "";
    },
  },
  methods: {
    validatePhone() {
      if (this.phone === null || this.phone === "") {
        this.error = this.$t("the_phone_number_cannot_be_empty");
        this.$emit("phoneValidated", null);
      } else if (this.phone.length < 6) {
        this.error = this.$t("phone_min");
        this.$emit("phoneValidated", null);
      } else {
        this.error = "";
        this.$emit("phoneValidated", this.phone, this.prefix);
      }
    },
    clear() {
      this.phone = "";
    },
    handleSelectedPrefix(prefix) {
      this.prefix = prefix;
      this.$emit("phoneValidated", this.phone, this.prefix);
    },
  },
};
</script>

