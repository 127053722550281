<template>
  <div>
    <select
      v-model="selectedHour"
      class="custom-select w-full"
      id="timeSelector"
      ref="timeSelector"
      @change="handleHourChange"
    >
      <option v-for="hour in hours" :key="hour" :value="hour">
        {{ hour }}
      </option>
    </select>
  </div>
</template>

<script>
import { formatTime } from "@/helpers/dateHelper";

export default {
  name: "timeSelector",
  emits: ["timeSelected"],
  props: ["initialHour"],
  data() {
    return {
      selectedHour: null,
      hours: [],
      store: null,
    };
  },
  mounted() {
    this.generateHours();
    this.selectedHour = this.initialHour;
  },

  methods: {
    generateHours() {
      // Create a new Date object
      const now = new Date();

      for (let hour = 0; hour < 24; hour++) {
        for (let minute = 0; minute < 60; minute += 30) {
          now.setHours(hour);
          now.setMinutes(minute);
          this.hours.push(formatTime(now, navigator));
        }
      }
    },
    handleHourChange() {
      this.$emit("timeSelected", this.selectedHour);
    },
  },
};
</script>
<style scoped>
.custom-select {
  box-shadow: inset 0px 0px 12px rgba(255, 255, 255, 0.15);
  -webkit-backdrop-filter: blur(16px);
  backdrop-filter: blur(16px);
  height: 40px;
  padding-left: 20px;
  font-size: 14px;
  background: rgba(255, 255, 255, 0.02);
  border: 1px solid;
  border-radius: 24px;
  border-bottom-color: #264b67;
  border-left-color: #264b67;
  border-right-color: #076ae3;
  border-top-color: #264b67;
}
.custom-select option {
  /* Styles for the options */
  background: var(--background-color);
  color: var(--main-color) !important;
  padding: 8px;
  border-radius: 4px;
}

.custom-select option:hover {
  /* Styles for the options on hover */
  color: #5615ac;
}
</style>