import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UpdateUser = _resolveComponent("UpdateUser")!
  const _component_PopUpLight = _resolveComponent("PopUpLight")!

  return (_openBlock(), _createBlock(_Transition, null, {
    default: _withCtx(() => [
      (_ctx.showGenderLevel)
        ? (_openBlock(), _createBlock(_component_PopUpLight, {
            key: 0,
            onClosed: _cache[1] || (_cache[1] = ($event: any) => (_ctx.closeGenderLevel())),
            showClose: false,
            isWhiteTheme: _ctx.isWhiteTheme
          }, {
            default: _withCtx(() => [
              _createVNode(_component_UpdateUser, {
                customSport: _ctx.sport,
                onCloseGenderLevel: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeGenderLevel())),
                isWhiteTheme: _ctx.isWhiteTheme
              }, null, 8, ["customSport", "isWhiteTheme"])
            ]),
            _: 1
          }, 8, ["isWhiteTheme"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}