<template>
  <transition name="fade" v-if="!isWebView">
        <div class="z-10 absolute" v-if="showCookie">
      <div class="cookie-notice">
        <div class="cookie-notice__text">
          <h1>{{ $t("cookie_notice") }}</h1>
          <p>{{ $t("cookie_message") }}</p>
          <div class="button">
            <a href="#" @click="closeCookieNotice">{{ $t("i_agree") }}</a>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
  
<script lang="ts">
import { defineComponent, nextTick } from "vue";
import LocalStorage from "@/helpers/localStorage";
import { AppsHelper } from "@/helpers/appsHelper";

export default defineComponent({
  name: "CookiesPrivacy",
  data() {
    return {
      showCookie: false as boolean,
      isWebView: false as boolean
    };
  },
  mounted() {
    nextTick(() => {//to check if rendered afet mounted
      this.fixIphoneHeight();
    });
    let cookieAccept: boolean;
    if (LocalStorage.get("cookie_accept") != null) {
      cookieAccept = JSON.parse(LocalStorage.get("cookie_accept")!);
      if (cookieAccept) {
        this.showCookie = false;
        return;
      }
    }
    this.showCookie = true;
    this.isWebView = AppsHelper.isWebView() as boolean;
  },
  methods: {
    closeCookieNotice() {
      LocalStorage.add("cookie_accept", true);
      this.showCookie = false;
    },
    fixIphoneHeight() {
      if (AppsHelper.isIOS()) {
        const cookieContainer = document.querySelector(".cookie-notice");
        if (cookieContainer) {
          cookieContainer.classList.add("!bottom-155");
        }
      }
    },
  },
});
</script>
  
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

body {
  background: linear-gradient(135deg, #c2d1e8 0%, #8db4ef 50%, #c2d1e8 100%);
}
.cookie-notice {
  position: fixed;
  right: 10px;
  max-width: 300px;
  background: linear-gradient(
    rgba(60, 90, 120, 1) 0%,
    rgba(30, 60, 90, 1) 60%,
    rgba(20, 40, 60, 1) 100%
  );
  padding: 15px;
  border-radius: 32px;
  bottom: 10px;

}
.cookie-notice__text {
  color: rgb(220, 220, 220);
  text-align: justify;
  font-size: 10px;
}
.button a {
  display: block;
  text-decoration: none;
  color: rgb(200, 200, 200);
  padding: 5px;
  width: 100%;
  margin-top: 12px;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
  box-shadow: inset 0 1px rgba(255, 255, 255, 0.1), 0 1px 3px rgba(0, 0, 0, 0.3),
    0 0 1px 1px rgba(255, 255, 255, 0.1);

  text-align: center;
  letter-spacing: 0px;
  border-radius: 3px;
}
h1 {
  font-size: 16px;
  margin: 0;
  padding: 0;
}
</style>
  