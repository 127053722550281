<template>
  <main>
    <h3
      class="title-onboarding not-italic font-medium text-3xl text-center text-white"
    >
      {{ $t("where_do_you_usually_play") }}
    </h3>
    <span
      class="subtitle-onboarding not-italic font-normal text-sm text-center block"
    >
      {{ $t("select_your_favorite_place") }}</span
    >
    <div class="buttons">
      <label>
        <button
          class="option-play flex items-center"
          v-for="(button, index) in buttons"
          :key="index"
          :class="{ label_seleccionado: index === usuallyPlaySelected }"
          :disabled="index === usuallyPlaySelected"
          @click="updateValue(index)"
        >
          <input
            type="checkbox"
            :class="{ check_seleccionado: index === usuallyPlaySelected }"
          />
          {{ button }}
        </button>
      </label>
    </div>
    <div
      v-if="geolocationError"
      class="flex justify-center error-message"
      style="white-space: pre-line"
    >
      {{ geolocationError }}
    </div>
  </main>
  <div>
    <Search v-if="usuallyPlaySelected === 1" 
      @showNextStep="showNextStep" 
    />
  </div>
</template>

<script>
import Search from "./searchSnippet.vue";
import store from "../../store";
import { serviceGlobalError } from "@/helpers/errorHelper";

export default {
  name: "usuallyPlay",
  emits: ["showNextStep"],
  components: {
    Search,
  },
  data() {
    return {
      error: "",
      geolocationError: "",
      geolocationOptions: "",
      buttons: [this.$t("near_me"), this.$t("my_favorite_clubs")],
      usuallyPlaySelected: 0,
      location: {
        name: "",
        cordX: null,
        cordY: null,
      },
      locations: [],
    };
  },
  computed: {
    isValid() {
      return this.error === "";
    },
  },
  mounted() {
    if (store.state.preferences.tenants.length !== 0) {
      this.updateValue(1); //TODO: hacer Constante 0 geolocation 1 Tenants
      return;
    }
    this.selectGeolocation();
    //check browser geolocation status, and add or remove location from data to send
    if ("permissions" in navigator) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then((permission) => {
          this.geolocationOptions = permission.state;
          permission.addEventListener("change", () => {
            this.geolocationOptions = permission.state;
            if (this.geolocationOptions == "granted") {
              this.geolocationError = "";
              this.selectGeolocation();
            } else {
              this.clearLocation();
            }
          });
        });
    }
  },
  methods: {
    updateValue(index) {
      this.usuallyPlaySelected = index;
      store.commit("setUsuallyPlaySelected", index);
      this.clearLocation();
      if (index === 0) {
        this.selectGeolocation();
      }
      if (index === 1) {
        this.geolocationError = "";
      }
    },
    getGeolocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          this.handleSuccess.bind(this),
          this.handleError.bind(this)
        );
      } else {
        this.geolocationError = this.$t("geolocation_browser_error");
      }
    },
    handleSuccess(position) {
      this.location.cordY = position.coords.longitude;
      this.location.cordX = position.coords.latitude;
      this.location.name = this.$t("near_me");
      this.locations.push(this.location);
      store.commit("setPreferencesPlaces", this.locations);
      //this.getClosestCity();
    },
    handleError(error) {
      this.geolocationError = this.$t("geolocation_locked");
    },
    /* TODO export to helpe if needed again
    async getClosestCity() {
      try {
        const response = await fetch(
          `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${this.location.cordX}&lon=${this.location.cordY}`
        );
        const data = await response.json();
        if (data.address && data.address.city) {
          this.location.name = data.address.city;
        }
        this.locations.push(this.location);
        store.commit("setPreferencesPlaces", this.locations);
      } catch (error) {
        serviceGlobalError(error);
      }
    },*/
    showNextStep() {
      this.clearLocation();
      this.$emit("showNextStep", 1);
    },
    clearLocation() {
      this.locations = [];
      store.commit("setPreferencesPlaces", this.locations);
    },
    clearTenants() {
      const tenants = [];
      store.commit("setPreferencesTenants", tenants);
    },
    selectGeolocation() {
      this.clearTenants();
      this.getGeolocation();
    },
    clear() {
      this.error = "";
    },
  },
};
</script>

<style>
.label_seleccionado {
  background: rgba(26, 192, 234, 0.05);
  box-shadow: inset 0px 0px 24px rgba(26, 192, 234, 0.5);
  backdrop-filter: blur(16px);
  border-radius: 16px;
}
</style>
