import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center" }
const _hoisted_2 = { class: "flex flex-wrap items-center justify-center" }
const _hoisted_3 = { class: "flex p-6 justify-around" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_activityCard = _resolveComponent("activityCard")!
  const _component_PopUpLight = _resolveComponent("PopUpLight")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activities, (activity) => {
          return (_openBlock(), _createElementBlock("div", {
            key: activity.id,
            class: "max-w-sm m-2 flex flex-col justify-between overflow-hidden"
          }, [
            _createVNode(_component_activityCard, {
              activity: activity,
              isDetail: false,
              onClick: ($event: any) => (_ctx.activitySelected(activity, $event))
            }, null, 8, ["activity", "onClick"])
          ]))
        }), 128))
      ])
    ]),
    _createVNode(_Transition, null, {
      default: _withCtx(() => [
        (_ctx.showActivityPopup)
          ? (_openBlock(), _createBlock(_component_PopUpLight, {
              key: 0,
              showOkButton: true,
              onClosed: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showActivityPopup = false))
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_activityCard, {
                    activity: _ctx.activity,
                    isDetail: true,
                    onSelfClose: _ctx.handleActivityDetailClose
                  }, null, 8, ["activity", "onSelfClose"])
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}