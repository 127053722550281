<template>
  <span>
    <div class="relative h-full">
      <div id="payment-element"></div>
    </div>
  </span>
</template>
<script lang="ts">
import { defineComponent, ref } from "vue";
import loadScript from "load-script";
import { SaleApi } from "@/service/SaleApi";
import { AuthorizeSaleRequest } from "models/authorize-sale-request";

export default defineComponent({
  name: "redsysForm",
  data() {
    return {
      token: "",
      error: "",
    };
  },
  components: {},
  props: ["data", "cardId", "user"],
  emits: ["paymentExecuted"],
  async mounted() {
    const targetElement: any = document.getElementById("payment-element");
    targetElement.innerHTML = "";

    await this.loadMercadoPagoScript();

    await this.loadMercadoPagoForm(
      this.data.paymentProviderData.properties.publicKey,
      this.data.id,
      this.data.amountData.grossAmount,
      this.data.paymentProviderData.properties.email,
      this.data.amountData.currencyCode
    );

    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
  },
  methods: {
    async loadMercadoPagoScript() {
      const scriptLoaded = ref(false);
      const scriptUrl = process.env.VUE_APP_MERCADOPAGO_INSITE_SCRIPT;

      const script: any = await new Promise((resolve, reject) => {
        loadScript(scriptUrl, (err, script: any) => {
          if (err) {
            reject(err);
          } else {
            scriptLoaded.value = true;
            resolve(script);
          }
        });
      });
    },
    loadMercadoPagoForm(
      pk: string,
      id: string,
      amount: number,
      email: string,
      currency: string
    ) {
      const w: any = window;
      let emit = this.$emit;
      const paymentErrorMsg = this.$t(
        "payment_platform_operation_cannot_be_completed"
      );
      const MercadoPago = w.MercadoPago;
      const mp = new MercadoPago(pk, {
        locale: "es-AR",
      });
      const bricksBuilder: any = mp.bricks();

      const renderPaymentBrick = async (bricksBuilder: any) => {
        // Set up the payment preferences
        const settings = {
          initialization: {
            amount: amount,
            currency_id: currency,
            payer: {
              firstName: this.user.fullName,
              lastName: "",
              email: this.user.username,
              entityType: "individual",
            },
          },
          customization: {
            visual: {
              style: {
                theme: "dark",
              },
            },
            paymentMethods: {
              debitCard: "all",
              // ticket: "all",
              // atm: "all",
              // onboarding_credits: "all",
              creditCard: "all",
              maxInstallments: 1, // if removed it shows periodic fees options
            },
            installments: 1,
          },
          callbacks: {
            onReady: () => {
              // Callback called when the Brick is ready.
              // You can hide loading indicators here, for example.
            },
            onSubmit: async ({ selectedPaymentMethod, formData }: any) => {
              // Callback called when the submit button is clicked
              const data: AuthorizeSaleRequest = {
                saleId: id,
                storeCard: true,
                operationId: formData.token,
              };

              try {
                await SaleApi.authorize(data);
                emit("paymentExecuted", { success: true });
                return;
              } catch (error: any) {
                emit("paymentExecuted", {
                  success: false,
                  status: error.response.status,
                  error:
                    (error &&
                      error.response &&
                      error.response.data.messages[0] === null) ||
                    error.response.data.messages[0] === undefined
                      ? paymentErrorMsg
                      : error.response.data.messages[0],
                });
                return;
              }
            },
            onError: (error: any) => {
              // Callback called for all Brick error cases
              this.error = error;
              console.error(error);
            },
          },
          saveCardToken: true, // Enable saving card data for future use
        };

        // Render the payment form inside the #payment-element div
        w.paymentBrickController = await bricksBuilder.create(
          "payment",
          "payment-element",
          settings
        );
      };

      renderPaymentBrick(bricksBuilder);
    },
  },
});
</script>
<style scoped>
#card-form {
  height: 169px !important;
}
@media (max-width: 500px) {
  #card-form {
    height: 200px !important;
  }
}
</style>