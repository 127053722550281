/* tslint:disable */
/* eslint-disable */
/**
 * Marketplace Api
 * Marketplace Api description
 *
 * OpenAPI spec version: 1.0
 * Contact: develop@taykus.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { Token } from '../models';
/**
 * AuthorizationApi - axios parameter creator
 * @export
 */
export const AuthorizationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Authenticate a customer using username/password
         * @param {string} username The customer&#x27;s username
         * @param {string} password The customer&#x27;s password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticate: async (username: string, password: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            if (username === null || username === undefined) {
                throw new RequiredError('username','Required parameter username was null or undefined when calling authenticate.');
            }
            // verify required parameter 'password' is not null or undefined
            if (password === null || password === undefined) {
                throw new RequiredError('password','Required parameter password was null or undefined when calling authenticate.');
            }
            const localVarPath = `/api/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }

            if (password !== undefined) {
                localVarQueryParameter['password'] = password;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Authenticate a customer using Apple sign on token
         * @param {string} token Apple JWT
         * @param {string} code Apple authorization code
         * @param {string} [fullName] The full name of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateWithApple: async (token: string, code: string, fullName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling authenticateWithApple.');
            }
            // verify required parameter 'code' is not null or undefined
            if (code === null || code === undefined) {
                throw new RequiredError('code','Required parameter code was null or undefined when calling authenticateWithApple.');
            }
            const localVarPath = `/api/auth/apple`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (fullName !== undefined) {
                localVarQueryParameter['fullName'] = fullName;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Authenticate a customer using a Google JWT token
         * @param {string} token Google JWT
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateWithGoogle: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling authenticateWithGoogle.');
            }
            const localVarPath = `/api/auth/google`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Authenticate a customer using a Google access token
         * @param {string} token Google access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateWithGoogleAccessToken: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling authenticateWithGoogleAccessToken.');
            }
            const localVarPath = `/api/auth/google/access_token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthorizationApi - functional programming interface
 * @export
 */
export const AuthorizationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Authenticate a customer using username/password
         * @param {string} username The customer&#x27;s username
         * @param {string} password The customer&#x27;s password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticate(username: string, password: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Token>>> {
            const localVarAxiosArgs = await AuthorizationApiAxiosParamCreator(configuration).authenticate(username, password, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Authenticate a customer using Apple sign on token
         * @param {string} token Apple JWT
         * @param {string} code Apple authorization code
         * @param {string} [fullName] The full name of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticateWithApple(token: string, code: string, fullName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Token>>> {
            const localVarAxiosArgs = await AuthorizationApiAxiosParamCreator(configuration).authenticateWithApple(token, code, fullName, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Authenticate a customer using a Google JWT token
         * @param {string} token Google JWT
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticateWithGoogle(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Token>>> {
            const localVarAxiosArgs = await AuthorizationApiAxiosParamCreator(configuration).authenticateWithGoogle(token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Authenticate a customer using a Google access token
         * @param {string} token Google access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticateWithGoogleAccessToken(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Token>>> {
            const localVarAxiosArgs = await AuthorizationApiAxiosParamCreator(configuration).authenticateWithGoogleAccessToken(token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AuthorizationApi - factory interface
 * @export
 */
export const AuthorizationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Authenticate a customer using username/password
         * @param {string} username The customer&#x27;s username
         * @param {string} password The customer&#x27;s password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticate(username: string, password: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Token>> {
            return AuthorizationApiFp(configuration).authenticate(username, password, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Authenticate a customer using Apple sign on token
         * @param {string} token Apple JWT
         * @param {string} code Apple authorization code
         * @param {string} [fullName] The full name of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticateWithApple(token: string, code: string, fullName?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Token>> {
            return AuthorizationApiFp(configuration).authenticateWithApple(token, code, fullName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Authenticate a customer using a Google JWT token
         * @param {string} token Google JWT
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticateWithGoogle(token: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Token>> {
            return AuthorizationApiFp(configuration).authenticateWithGoogle(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Authenticate a customer using a Google access token
         * @param {string} token Google access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticateWithGoogleAccessToken(token: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Token>> {
            return AuthorizationApiFp(configuration).authenticateWithGoogleAccessToken(token, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthorizationApi - object-oriented interface
 * @export
 * @class AuthorizationApi
 * @extends {BaseAPI}
 */
export class AuthorizationApi extends BaseAPI {
    /**
     * 
     * @summary Authenticate a customer using username/password
     * @param {string} username The customer&#x27;s username
     * @param {string} password The customer&#x27;s password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationApi
     */
    public async authenticate(username: string, password: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Token>> {
        return AuthorizationApiFp(this.configuration).authenticate(username, password, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Authenticate a customer using Apple sign on token
     * @param {string} token Apple JWT
     * @param {string} code Apple authorization code
     * @param {string} [fullName] The full name of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationApi
     */
    public async authenticateWithApple(token: string, code: string, fullName?: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Token>> {
        return AuthorizationApiFp(this.configuration).authenticateWithApple(token, code, fullName, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Authenticate a customer using a Google JWT token
     * @param {string} token Google JWT
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationApi
     */
    public async authenticateWithGoogle(token: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Token>> {
        return AuthorizationApiFp(this.configuration).authenticateWithGoogle(token, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Authenticate a customer using a Google access token
     * @param {string} token Google access token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationApi
     */
    public async authenticateWithGoogleAccessToken(token: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Token>> {
        return AuthorizationApiFp(this.configuration).authenticateWithGoogleAccessToken(token, options).then((request) => request(this.axios, this.basePath));
    }
}
