<template>
  <div>
    <!-- Gender Section -->
    <div v-if ="category === categoryMatch" class="section">
      <h5 class="mt-4 text-left">{{ activeFilter.labelGender }}</h5>
      <div class="flex flex-wrap mt-4">
        <div
          class="optionButton md:p-4 sm:p-4 text-sm relative cursor-pointer"
          v-for="type in activeFilter.genders"
          :key="type.id"
          :class="{ active: isSelectedGender(type.id) }"
          @click="toggleGender(type.id)"
        >
          {{ type.label }}
        </div>
      </div>
    </div>
    <!-- Type Section -->
    <div class="section">
      <h5 class="mt-4 text-left">{{ activeFilter.labelType }}</h5>
      <div class="flex flex-wrap mt-4">
        <div
          class="optionButton md:p-4 sm:p-4 text-sm relative cursor-pointer"
          v-for="type in activeFilter.types"
          :key="type.id"
          :class="{ hidden: !type.show, active: isSelectedType(type.id) }"
          @click="toggleType(type.id)"
        >
          {{ type.label }}
        </div>
      </div>
    </div>
    <!-- SubType Section -->
    <div class="section">
      <h5 class="mt-4 text-left">{{ activeFilter.labelSubType }}</h5>
      <div class="flex mt-4 jflex-wrap">
        <div
          class="optionButton md:p-4 sm:p-4 text-sm relative cursor-pointer"
          v-for="subType in activeFilter.subtypes"
          :key="subType.id"
          :class="{ hidden: !subType.show, active: isSelectedSubType(subType.id) }"
          @click="toggleSubType(subType.id)"
        >
          {{ subType.label }}
        </div>
      </div>
    </div>
    <!-- Size Section -->
    <div class="section">
      <h5 class="mt-4 text-left">{{ activeFilter.labelSize }}</h5>
      <div class="flex flex-wrap mt-2">
        <div
          class="optionButton md:p-4 sm:p-4 text-sm relative cursor-pointer"
          v-for="size in activeFilter.sizes"
          :key="size.id"
          :class="{ hidden: !size.show, active: isSelectedSize(size.id) }"
          @click="toggleSize(size.id)"
        >
          {{ size.label }}
        </div>
      </div>
    </div>
    <!-- Show Result Button -->
    <div class="flex">
      <button class="mx-2 mt-8" @click="send">
        {{ $t("filter") }}
      </button>
    </div>
  </div>
</template>

<script>
import {
  PadelType,
  PadelSubType,
  PadelSize,
  TenisType,
  TenisSubType,
  TenisSize,
  Sport,
  PickleballType,
  PickleballSubType,
  PickleballSize,
  SoccerType,
  SoccerSubtype,
  SoccerSize,
  PadbolType,
  PadbolSubType,
  PadbolSize,
  RacquetballType,
  RacquetballSubType,
  RacquetballSize,
  SquashType,
  SquashSubType,
  SquashSize,
  Gender,
  Category
} from "@/enum/constants";
import { Format } from "@/helpers/formatHelper";

export default {
  name: "searchFilter",
  emits: ["sendFilter"],
  props: ["filter", "sport", "category"],
  data() {
    return {
      categoryMatch: Category.MATCH,
      sportFilters: [
        {
          type: Sport.PADEL,
          labelType: this.$t("type"),
          labelSubType: this.$t("wall"),
          labelSize: this.$t("size"),
          labelGender: null,
          types: [
            {
              id: PadelType.INDOOR,
              label: this.$t("indoor"),
              selected: false,
              show: true
            },
            {
              id: PadelType.OUTDOOR,
              label: this.$t("outdoor"),
              selected: false,
              show: true
            },
            {
              id: PadelType.COVER,
              label: this.$t("covered"),
              selected: false,
              show: true
            },
          ],
          subtypes: [
            { id: PadelSubType.WALL, label: this.$t("wall"), selected: false },
            {
              id: PadelSubType.CRISTAL,
              label: this.$t("crystal"),
              selected: false,
              show: true
            },
            {
              id: PadelSubType.PANORAMIC,
              label: this.$t("panoramic"),
              selected: false,
              show: true
            },
          ],
          sizes: [
            {
              id: PadelSize.DOUBLE,
              label: this.$t("common_double"),
              selected: false,
              show: true
            },
            {
              id: PadelSize.INDIVIDUAL,
              label: this.$t("individual"),
              selected: false,
              show: true
            },
          ],
        },
        {
          type: Sport.TENIS,
          labelType: this.$t("type"),
          labelSubType: this.$t("features"),
          labelSize: this.$t("size"),
          labelGender: null,
          types: [
            {
              id: TenisType.INDOOR,
              label: this.$t("indoor"),
              selected: false,
              show: true
            },
            {
              id: TenisType.OUTDOOR,
              label: this.$t("outdoor"),
              selected: false,
              show: true
            },
            {
              id: TenisType.COVER,
              label: this.$t("covered"),
              selected: false,
              show: true
            },
          ],
          subtypes: [
            { id: TenisSubType.CLAY, label: this.$t("clay"), selected: false },
            {
              id: TenisSubType.QUICK,
              label: this.$t("quick"),
              selected: false,
              show: true
            },
            {
              id: TenisSubType.CEMENT,
              label: this.$t("cement"),
              selected: false,
              show: true
            },
            {
              id: TenisSubType.SYNTHETICGRASS,
              label: this.$t("synthetic_grass"),
              selected: false,
              show: true
            },
            {
              id: TenisSubType.GRASS,
              label: this.$t("grass"),
              selected: false,
              show: true
            },
          ],
          sizes: [
            {
              id: TenisSize.DOUBLE,
              label: this.$t("common_double"),
              selected: false,
              show: true
            },
            {
              id: TenisSize.INDIVIDUAL,
              label: this.$t("individual"),
              selected: false,
              show: true
            },
            {
              id: TenisSize.MINI,
              label: this.$t("mini"),
              selected: false,
              show: true
            },
          ],
        },
        {
          type: Sport.PICKLEBALL,
          labelType: this.$t("type"),
          labelSubType: this.$t("features"),
          labelSize: this.$t("size"),
          labelGender: null,
          label: this.$t("type"),
          types: [
            {
              id: PickleballType.INDOOR,
              label: this.$t("indoor"),
              selected: false,
              show: true
            },
            {
              id: PickleballType.OUTDOOR,
              label: this.$t("outdoor"),
              selected: false,
              show: true
            },
            {
              id: PickleballType.COVER,
              label: this.$t("covered"),
              selected: false,
              show: true
            },
          ],
          subtypes: [
            {
              id: PickleballSubType.ASPHALT,
              label: this.$t("asphalt"),
              selected: false,
              show: true
            },
            {
              id: PickleballSubType.PLASTICPRESURE,
              label: this.$t("plastic_pressure"),
              selected: false,
              show: true
            },
            {
              id: PickleballSubType.PARQUET,
              label: this.$t("parquet"),
              selected: false,
              show: true
            },
          ],
          sizes: [
            {
              id: PickleballSize.DOUBLE,
              label: this.$t("common_double"),
              selected: false,
              show: true
            },
            {
              id: PickleballSize.INDIVIDUAL,
              label: this.$t("individual"),
              selected: false,
              show: true
            },
            {
              id: PickleballSize.MINI,
              label: this.$t("mini"),
              selected: false,
              show: true
            },
          ],
        },
        {
          type: Sport.SOCCER,
          labelType: this.$t("type"),
          labelSubType: this.$t("surface"),
          labelSize: this.$t("size"),
          labelGender: null,
          label: this.$t("type"),
          types: [
            {
              id: SoccerType.INDOOR,
              label: this.$t("indoor"),
              selected: false,
              show: true
            },
            {
              id: SoccerType.OUTDOOR,
              label: this.$t("outdoor"),
              selected: false,
              show: true
            }
          ],
          subtypes: [
            {
              id: SoccerSubtype.CONCRETE,
              label: this.$t("concrete"),
              selected: false,
              show: true
            },
            {
              id: SoccerSubtype.PARQUET,
              label: this.$t("parquet"),
              selected: false,
              show: true
            },
            {
              id: SoccerSubtype.SYNTHETIC,
              label: this.$t("synthetic"),
              selected: false,
              show: true
            },
            {
              id: SoccerSubtype.ARTIFICIALTURF,
              label: this.$t("artificial_turf"),
              selected: false,
              show: true
            },
            {
              id: SoccerSubtype.GRASS,
              label: this.$t("grass"),
              selected: false,
              show: true
            },
            {
              id: SoccerSubtype.SAND,
              label: this.$t("sand"),
              selected: false,
              show: true
            },
          ],
          sizes: [
            {
              id: SoccerSize.FUT3,
              label: this.$t("fut3"),
              selected: false,
              show: true
            },
            {
              id: SoccerSize.FUT5,
              label: this.$t("fut5"),
              selected: false,
              show: true
            },
            {
              id: SoccerSize.FUT7,
              label: this.$t("fut7"),
              selected: false,
              show: true
            },
            {
              id: SoccerSize.FUT11,
              label: this.$t("fut11"),
              selected: false,
              show: true
            },
            {
              id: SoccerSize.FUTSAL,
              label: this.$t("FUTSAL"),
              selected: false,
              show: true
            },
          ],
        },
      ],
      activeFilter: [],
    };
  },
  mounted() {
    const activeFilterSport = parseInt(this.sport);
    if(this.category === Category.MATCH){
      const genderFilter = [
          {
            id: Gender.FEMALE,
            label: this.$t("female"),
            selected: false,
            show: true
          },
          {
            id: Gender.MALE,
            label: this.$t("male"),
            selected: false,
            show: true
          },
          {
            id: Gender.MIX,
            label: this.$t("mixed"),
            selected: false,
            show: true
          },
          {
            id: Gender.OPEN,
            label: this.$t("open"),
            selected: false,
            show: true
          }
        ]
      this.sportFilters[activeFilterSport].labelGender = this.$t("gender"),
      this.sportFilters[activeFilterSport].genders = genderFilter;
    }

    this.activeFilter = this.sportFilters.find(
      (y) => y.type === activeFilterSport
    );
    if (this.filter.gender !== undefined) {
      for (const gender of this.filter.gender) {
        let filter = this.activeFilter.genders.find((y) => y.id === gender);
        if (!Format.IsNull(filter)) {
          filter.selected = true;
        }
        
      }
    }

    if (this.filter.type !== undefined) {
      for (const type of this.filter.type) {
        let filter = this.activeFilter.types.find((y) => y.id === type);
        if (!Format.IsNull(filter)) {
          filter.selected = true;
        }
        
      }
    }

    if (this.filter.subType !== undefined) {
      for (const type of this.filter.subType) {
        let filter = this.activeFilter.subtypes.find((y) => y.id === type);
        if (!Format.IsNull(filter)) {
          filter.selected = true;
        }
      }
    }

    if (this.filter.size !== undefined) {
      for (const type of this.filter.size) {
        let filter = this.activeFilter.sizes.find((y) => y.id === type);
        if (!Format.IsNull(filter)) {
          filter.selected = true;
        }
      }
    }
  },
  methods: {
    isSelectedGender(genderId) {
      return (
        this.activeFilter.genders.find((gender) => gender.id === genderId).selected ||
        false
      );
    },
    toggleGender(genderId) {
      const gender = this.activeFilter.genders.find((gender) => gender.id === genderId);
      if (gender) {
        gender.selected = !gender.selected;
      }
    },
    isSelectedType(typeId) {
      return (
        this.activeFilter.types.find((type) => type.id === typeId).selected ||
        false
      );
    },
    toggleType(typeId) {
      const type = this.activeFilter.types.find((type) => type.id === typeId);
      if (type) {
        type.selected = !type.selected;
      }
      if(this.sport === Sport.SOCCER){
        const hideOptionIndex = this.activeFilter.sizes.findIndex((size) => size.id === SoccerSize.FUT11);
        this.activeFilter.sizes[hideOptionIndex].show = true;
        if(type.selected){
          this.activeFilter.sizes[hideOptionIndex].selected = false;
          this.activeFilter.sizes[hideOptionIndex].show = false;
        }
      }
    },
    isSelectedSubType(subTypeId) {
      return (
        this.activeFilter.subtypes.find((subType) => subType.id === subTypeId)
          .selected || false
      );
    },
    toggleSubType(subTypeId) {
      const subType = this.activeFilter.subtypes.find(
        (subType) => subType.id === subTypeId
      );
      if (subType) {
        subType.selected = !subType.selected;
      }
    },
    isSelectedSize(sizeId) {
      return (
        this.activeFilter.sizes.find((size) => size.id === sizeId).selected ||
        false
      );
    },
    toggleSize(sizeId) {
      const size = this.activeFilter.sizes.find((size) => size.id === sizeId);
      if (size) {
        size.selected = !size.selected;
      }
    },
    send() {
      const filter = {
        type: this.activeFilter.types
          .filter((type) => type.selected)
          .map((type) => type.id),
        subType: this.activeFilter.subtypes
          .filter((subType) => subType.selected)
          .map((subType) => subType.id),
        size: this.activeFilter.sizes
          .filter((size) => size.selected)
          .map((size) => size.id),
      };
      if (this.category === Category.MATCH) {
        filter.gender = this.activeFilter.genders
          .filter((gender) => gender.selected)
          .map((gender) => gender.id);
      }
      this.$emit("sendFilter", filter);
    },
  },
};
</script>

<style scoped>
.section {
  margin-bottom: 10px;
}
button {
  background: linear-gradient(
    103.18deg,
    var(--color1) -38.71%,
    var(--color2) 57.77%
  );
  box-shadow: -2px 4px 18px rgba(0, 15, 53, 0.3);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  border-radius: 32px;
  padding: 14.5px 0;
  cursor: pointer;
  width: 100%;
}
.hidden {
  display: none;
}
</style>
