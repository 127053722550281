<template>
  <div :class="cssClass">
    <div class="flex justify-start">{{ $t("select_your_gender") }}</div>
    <div class="flex flex-wrap mt-6 cursor-pointer w-full justify-center">
      <div
        @click="click($event, gender.MAN)"
        class="flex flex-col items-center p-2 pl-4 pr-4 clueColor br60"
        :class="{ selected: modelValue === gender.MAN }"
      >
        {{ $t(genderLabel.MALE) }}
      </div>
      <div
        @click="click($event, gender.WOMAN)"
        class="flex flex-col items-center p-2 pl-4 pr-4 clueColor br60 ml-4"
        :class="{ selected: modelValue === gender.WOMAN }"
      >
        {{ $t(genderLabel.FEMALE) }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { UserGender, GenderLabel } from "@/enum/constants";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "UserGenderSelector",
  components: {},
  props: {
    modelValue: {
      type: Number as PropType<number | null>,
      required: false,
    },
    cssClass: String,
  },

  data() {
    return {
      gender: UserGender,
      genderLabel: GenderLabel,
    };
  },
  methods: {
    click(e: any, gender: any) {
      this.$emit("update:modelValue", gender);
      this.$emit("selectionChanged", gender);
    },
  },
});
</script>

<style scoped>
.selected {
  background-color: var(--color2);
}
</style>
