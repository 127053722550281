<template>
  <div class="flex flex-wrap paddig-responsive">
    <div class="flex w-full text-left center-responsive">
      <h2>{{ $t("received_sport_lessons_last_year_question", { sport: getSportLabel(Sport.SQUASH)}) }}</h2>
    </div>
    <div class="flex w-full mt-8">
      <div class="flex flex-col">
        <template v-for="option in options" :key="option.id">
          <label class="flex items-center mb-2">
            <input type="radio" :value="option.value" v-model="lesson" @change="showValue()" class="mr-2">
            <span>{{ option.label }}</span>
          </label>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { SelectOption } from "@/custom-models/levelingSelector";
import { getSportLabel } from "@/helpers/dataHelper";
import { Sport } from "@/enum/constants";
  
export default defineComponent({
  name: 'LessonsSelector',
  props: {
    propLesson:{ 
      type: Number,
      default: 0.0 
    }
  },
  created (){
    this.lesson = this.propLesson;
  },
  data() {
    return {
      Sport: Sport,
      lesson: 0,
      options: [
        { id: 1, label: this.$t("no_never"), value: 0.0 },
        { id: 2, label: this.$t("yes_one_per_week"), value: 0.1 },
        { id: 3, label: this.$t("yes_two_or_more_per_week"), value: 0.2 },
      ] as SelectOption[]
    };
  },
  methods: {
    getSportLabel,
    showValue() {
      this.$emit("selectedLesson", this.lesson);
    }
  }
});
</script>