import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.loaded)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("table", null, [
          _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("th", null, _toDisplayString(_ctx.$t("Test")), 1),
              _createElementVNode("th", null, _toDisplayString(_ctx.$t("Evidence")), 1)
            ])
          ]),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.validation, (value) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: value.orderId
              }, [
                _createElementVNode("td", null, _toDisplayString(value.operationName), 1),
                _createElementVNode("td", null, _toDisplayString(value.orderId), 1)
              ]))
            }), 128))
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}