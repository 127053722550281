import { Format } from "./formatHelper";
import { PaymentApi } from "@/service/PaymentApi";
import { AxiosResponse } from "axios";

export default class PaymentHelper {

  static async findPaymentByCustomer(start: number, size: number) {
    const response = await PaymentApi.findPaymentByCustomer(start, size)
    return this.checkResponse(response);
  }

  static checkResponse(response:AxiosResponse) {
    if(!Format.IsNull(response.data)){
      return response.data
    }
    return null;
  }

}

