<template>
  <div class="flex">
    <div class="ml-auto mr-12 mt-4">
      <TaykusChat :bookingId="bookingId" />
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import store from "../store";
import TaykusChat from "@/components/chat/TaykusChat.vue";
import { Format } from "@/helpers/formatHelper";
import { customGlobalError } from "@/helpers/errorHelper";

export default defineComponent({
  name: "ChatView",
  components: {
    TaykusChat,
  },
  data() {
    return {
      bookingId: "",
    };
  },
  created() {
    const bookingId = this.$route.params.bookingId;
    
    if (Format.IsNull(bookingId)) {
      customGlobalError(this.$t("wrong_url"));
      return;
    }
    this.bookingId = bookingId.toString();
    let user = "";
    if (
      !Format.IsNull(this.$route.params.id) &&
      !Format.IsNull(this.$route.params.token)
    ) {
      const webViewUser = {
        id: atob(this.$route.params.id as string),
      };

      user = JSON.stringify(webViewUser);
      localStorage.setItem("user", user);
      localStorage.setItem(
        "t",
        JSON.stringify(atob(this.$route.params.token as string))
      );
    } else {
      user = localStorage.getItem("user") as string;
        if (user === null || user === "") {
        store.commit("openLogin");
        return;
      }
    }
  },
});
</script>
<style scoped>
.Wrapper404 {
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
}

.cactusR {
  bottom: 1px;
  right: 30px;
}
.cactusL {
  bottom: 1px;
  left: 30px;
}
.buttomPayMode {
  border-radius: 32px;
  padding: 14px 0;
  background-image: linear-gradient(to right, var(--color1), var(--color2));
  margin-left: auto;
  margin-right: auto;
}
.Wrapper404 {
  max-width: 420px;
}
.opacity {
  opacity: 0.6;
}
</style>