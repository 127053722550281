<template>
  <span>
    <div class="relative h-full">
       <div id="card-form" />
      <form name="datos">
        <input v-model="token" type="hidden" id="token" />
        <input v-model="errorCode" type="hidden" id="errorCode" />
        <!-- <a href="javascript:alert(document.datos.token.value + '--' + document.datos.errorCode.value)"> ver</a> -->
      </form>
    </div>
    <div id="container"></div>
    <Transition>
      <PopUpLight v-if="showBridge" 
        @closed="showBridge= false"
        :customClass="'width-lg'"
      >
        <div class="mt-8">
          <span v-if=showPopUpMsg>{{popUpMsg}}</span>
          <iframe v-if=showIframe class="iframe" title="Payment Bridge" frameborder="0" :src="iframeSrc"></iframe>
        </div>
      </PopUpLight>
  </Transition>
  </span>
</template>
<script lang="ts">
import { defineComponent, ref } from "vue";
import loadScript from "load-script";
import { SaleApi } from "@/service/SaleApi";
import { AuthorizeSaleRequest } from "models/authorize-sale-request";
import { Format } from "@/helpers/formatHelper";
import { GlobalErrors } from "@/enum/constants";
import { customGlobalError } from "@/helpers/errorHelper";
import axios from 'axios';
import PopUpLight from "@/components/popup/popupLight.vue";
import { encodeString } from "@/helpers/dataHelper";
import { PaymentAuthorization } from "models/payment-authorization";

export default defineComponent({
  name: "redsysForm",
  data() {
    return {
      token: "",
      errorCode: "",
      ip:"" as string,
      params: {} as { [key: string]: string },
      acsParamValue:"",
      acsUrl:"" as string,
      acsParamName:"" as string,
      showBridge: false as boolean,
      showPopUpMsg: true as boolean,
      showIframe: false as boolean,
      popUpMsg: "" as string,
      iframeSrc: "" as string,
      timeAuth: null as any | null,
    };
  },
  components:{
    PopUpLight
  },
  props: ["redsysData", "storeCard", "cardId", "onlyStoreCard"],
  emits: ["paymentExecuted"],
  async mounted() {
    const targetElement: any = document.getElementById("card-form");
    targetElement.innerHTML = "";

    await this.loadRedsysScript();
    await this.loadRedsysForm();

    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }

    this.redsysListener();
  },
  methods: {
    async loadRedsysScript() {
      const scriptLoaded = ref(false);
      const scriptUrl = process.env.VUE_APP_REDSYS_INSITE_SCRIPT;

      const script: any = await new Promise((resolve, reject) => {
        loadScript(scriptUrl, (err, script: any) => {
          if (err) {
            reject(err);
          } else {
            scriptLoaded.value = true;
            resolve(script);
          }
        });
      });
    },
    async loadRedsysForm() {
      const w: any = window;
      var insiteJSON = {
        id: "card-form",
        fuc: this.redsysData.paymentProviderData.properties.merchantCode,
        terminal:
          this.redsysData.paymentProviderData.properties.merchantTerminal,
        order: this.redsysData.paymentProviderData.properties.orderId,
        estiloInsite: "inline",
        mostrarLogo: false,
        styleButton: "margin-top:10px;background-color:transparent;",
        styleBody: "background:#011f3f; ",
        styleBox: "background:#011f3f;top:5px;margin-bottom:8px",
        styleBoxText:
          "color:#ffffff;background-color:#011f3f;position:relative;top:0px;margin-bottom:0px;",
      };
      await w.getInSiteFormJSON(insiteJSON);
    },
    async iFrameListener(event: any) {
        window.removeEventListener("message", this.iFrameListener);
        const resultData = event.data;
        resultData.status = GlobalErrors.ERROR_500;//ponemos siempre el status del error a 500 por si el succes es false, en caso de ser true lo ignora
        return this.$emit("paymentExecuted", resultData);
    },
    async formListener(event: any) {
        this.bankAuthorize();
    },
    startAuthTimer(){
      if (this.timeAuth !== null) {
        clearTimeout(this.timeAuth)
      }
      this.timeAuth = setTimeout(() => {
        this.bankAuthorize();
      }, 10000)
    },
    async initPayment(data: AuthorizeSaleRequest){
      const response = await SaleApi.authorize(data);
      if(!response.data){
        switch (response.status) {
          case GlobalErrors.ERROR_500:
            return customGlobalError(this.$t("payment_platform_operation_cannot_be_completed"),"/");
          case GlobalErrors.ERROR_400:
            return customGlobalError(this.$t("does_no_accept_this_type_of_card"),"/");
        }
      }
      const responseData = response.data;
      if (!Format.IsNull(responseData.paymentProviderData.authorization)) {
        const authorization = responseData.paymentProviderData.authorization;
        if(authorization.required){
          await this.threeDSMethodAuthorize(authorization);
        }else{
          this.$emit("paymentExecuted", { success: true });
          return;
        }
      }
    },
    async threeDSMethodAuthorize(authorization: PaymentAuthorization){
      this.showBridge=true;
      this.popUpMsg=this.$t("connecting_to_bank");

      window.addEventListener("message", this.formListener);
      const threeDSMethodData: {
        threeDSServerTransID: string;
        threeDSMethodNotificationURL: string;
      } = {
        threeDSServerTransID: authorization.threeDSTransactionId!,
        threeDSMethodNotificationURL: authorization.threeDSMethodNotificationUrl!
      };
      const base64EncodedData = btoa(JSON.stringify(threeDSMethodData));

      const objectData = {
          acsUrl: authorization.threeDSMethodNotificationUrl!,
          acsParamName: 'threeDSMethodData',
          acsParamValue: base64EncodedData
        };
      const payload = encodeString(JSON.stringify(objectData));
      this.iframeSrc = "/payment-bridge/" + payload;
      this.showIframe = true;
      this.startAuthTimer();
    },
    async bankAuthorize(){
      window.removeEventListener("message", this.formListener);
      clearTimeout(this.timeAuth);
      //this.popUpMsg=this.$t("resolving_ip");//es para obtener la ip para redsys, pero no hace falta de momento
      /*
      try {
        const response = await axios.get('https://api.ipify.org?format=json');
        this.ip = response.data.ip;
        this.params = {
          browserIP: this.ip
        };
      } catch (error) {
        console.error('Error fetching IP address:', error);
      }
      */
      this.ip = "";
      const browserJavaEnabled = navigator.javaEnabled().toString(); 
      const browserJavascriptEnabled = 'true'; //vue is js
      const browserLanguage = navigator.language;
      const browserColorDepth = screen.colorDepth.toString();
      const browserScreenHeight = screen.height.toString();
      const browserScreenWidth = screen.width.toString();
      const browserTZ = new Date().getTimezoneOffset().toString();

      this.params = {
        ...this.params,
        browserJavaEnabled: browserJavaEnabled,
        browserJavascriptEnabled: browserJavascriptEnabled,
        browserLanguage: browserLanguage,
        browserColorDepth: browserColorDepth,
        browserScreenHeight: browserScreenHeight,
        browserScreenWidth: browserScreenWidth,
        browserTZ: browserTZ,
      };
      this.popUpMsg=this.$t("authorizing_transaction");
      try {
        const response = await SaleApi.authorizeSaleWithAuthorization(this.redsysData.id, this.params);
        if(!response.data){
          switch (response.status) {
            case GlobalErrors.ERROR_500:
              return customGlobalError(this.$t("payment_platform_operation_cannot_be_completed"),"/");
            case GlobalErrors.ERROR_400:
              return customGlobalError(this.$t("does_no_accept_this_type_of_card"),"/");
          }
        }
        const responseData = response.data;
        if (!Format.IsNull(responseData.paymentProviderData.authorization)) {
          const authorization = responseData.paymentProviderData.authorization;

          window.addEventListener("message", this.iFrameListener);
          const objectData = {
            acsUrl: authorization.acsUrl,
            acsParamName: authorization.acsParamName,
            acsParamValue: authorization.acsParamValue
          };
          const payload = encodeString(JSON.stringify(objectData));
          this.iframeSrc = "/payment-bridge/" + payload;
          //this.showBridge = true;
          this.showPopUpMsg = false;
          this.showIframe = true;
        }
      } catch (error) {
        this.emitError(error, this.$t("payment_platform_operation_cannot_be_completed"))
      }
    },
    emitError(error: any, paymentErrorMsg:string){
      this.$emit("paymentExecuted", {
        success: false,
        status: error.response.status,
        error:
          (error &&
            error.response &&
            error.response.data.messages[0] === null) ||
          error.response.data.messages[0] === undefined
            ? paymentErrorMsg
            : error.response.data.messages[0],
      });
      return;
    },
    redsysListener() {
      const w: any = window;
      let emit = this.$emit;
      const redsysData = this.redsysData;
      const storeCard = this.storeCard;
      const onlyStoreCard = this.onlyStoreCard;
      const cardId = this.cardId;
      let token = this.token;
      let errorCode = this.errorCode;

      const paymentErrorMsg = this.$t("payment_platform_operation_cannot_be_completed");
      const notAcceptCardErrorMsg = this.$t("does_no_accept_this_type_of_card");

      //var handler = async function receiveMessage(event: any) {
      const handler = async (event: any) => {
        w.storeIdOper(event, "token", "errorCode", () => {
          //esta línea es necesaria para eliminar el token antiguo en caso de generar dos o mas reservas seguidas
          token = "";
          errorCode = "";
          //document.getElementById("token").value = "";
          //document.getElementById("errorCode").value = "";*/
          return true;
        });

        const tokenDom: any = document.getElementById("token");
        const errorDom: any = document.getElementById("errorCode");
        token = tokenDom.value;

        if (token === "-1") {
          emit("paymentExecuted", {
            success: false,
            status: -1,
            error: paymentErrorMsg,
          });
          window.removeEventListener("message", handler);
          return;
        }

        if (token !== "") {
          let data: AuthorizeSaleRequest = {
            saleId: "",
            operationId: token,
          };
          window.removeEventListener("message", handler);

          if (onlyStoreCard === false) {
            data.saleId = redsysData.id;
            data.storeCard = storeCard;
            data.cardId = cardId;
            try {
              await this.initPayment(data)
            } catch (error: any) {
              this.emitError(error, paymentErrorMsg)
              return;
            }
          }
        }
      };
      window.addEventListener("message", handler);
    },
  },
});
</script>
<style>
.iframe {
  width: 100%;
  height: 700px;
  overflow: hidden;
  border-radius: 2px;
  display: block;
}

#card-form {
  height: 180px !important;
  margin-top: 0.5rem;
}

@media (max-width: 500px) {
  #card-form {
    height: 210px !important;
  }
}

</style>