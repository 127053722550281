import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4c0332c1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mt-6 text-center text-3xl" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["WrapperPrivacy mx-auto max-w-2xl overflow-auto flex justify-center flex-col items-center sm:w-full sm:p-6", { whiteTheme: _ctx.isWhiteTheme }])
  }, [
    _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t("private_policy")), 1),
    _createElementVNode("p", {
      class: "mt-6 text-justify tp-4",
      innerHTML: _ctx.$t('privacy_policy_full_text')
    }, null, 8, _hoisted_2)
  ], 2))
}