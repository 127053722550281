import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "fixed top-16 left-[10%] z-[100]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HistoryBack = _resolveComponent("HistoryBack")!
  const _component_MyClubs = _resolveComponent("MyClubs", true)!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_HistoryBack)
    ]),
    _createVNode(_component_MyClubs)
  ]))
}