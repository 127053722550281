// BaseApi.ts
import axios, { AxiosInstance } from 'axios';
import { serviceGlobalError } from "@/helpers/errorHelper";
import { GlobalErrors } from "@/enum/constants";
import store from '@/store';

export class BaseApi {
  static authorizedAxios = null;

  static excludedEndpoints = [
    { url: '/api/sale', method: 'POST' },
    { httpCode: GlobalErrors.ERROR_PRIVATE_CLUB },
  ];

  static getAuthorizedAxios() {
    const token = JSON.parse(localStorage.getItem('t')!);
    let instance = null;
    if (token) {
      instance = axios.create({
        headers: {
          Authorization: token ? `Bearer ${token}` : undefined,
        },
      });
    } else {
      instance = axios.create();
    }
    instance.interceptors.response.use(
      (response) => response,
      (error) => {
        store.commit("setLoading", false);
        const { config } = error;
        const httpCode = error.response.status;
        const endpoint = config.url;
        const method = config.method.toUpperCase();
        const isExcluded = BaseApi.excludedEndpoints.some(
          (excludedEndpoint) =>
            excludedEndpoint.url === endpoint && excludedEndpoint.method === method ||
            excludedEndpoint.httpCode === httpCode
        );//TODO modificar url a comparar con regexp para que pueda comparar con '/api/sale/{id}'
        if (isExcluded) {
          return Promise.reject(error);
        }

        serviceGlobalError(error);
        return Promise.reject(error);
      }
    );

    return instance;
  }

  static getAxios() {
    const instance = axios.create();

    instance.interceptors.response.use(
      (response) => response,
      (error) => {
        store.commit("setLoading", false);
        const { config } = error;
        const endpoint = config.url;
        const method = config.method.toUpperCase();
        const isExcluded = BaseApi.excludedEndpoints.some(
          (excludedEndpoint) =>
            excludedEndpoint.url === endpoint && excludedEndpoint.method === method
        );
        if (isExcluded) {
          return Promise.reject(error);
        }

        serviceGlobalError(error);
        return Promise.reject(error);
      }
    );

    return instance;
  }
}
