<template>
  <div :class="cssClass">
    <div v-if="titleTop" class="m-6">{{ $t("sport") }}</div>
    <div class="flex flex-wrap justify-center items-center space-x-3 cursor-pointer">
      <div v-if="!titleTop" class="text-center" >{{ $t("sport") }}</div>
      <div
        v-if="shouldShowSport(sport.PADEL)"
        class="flex flex-col items-center"
        @click="click($event, sport.PADEL)"
      >
        <div class="mb-2 p-3 br100" :class="{ selected: modelValue === sport.PADEL }">
          <img src="../../assets/images/iconPadel.svg" alt="" />
        </div>
        <div v-if="showLabels">{{ $t("padel") }}</div>
      </div>
      <div
        v-if="shouldShowSport(sport.TENIS)"
        class="flex flex-col items-center"
        @click="click($event, sport.TENIS)"
      >
        <div class="mb-2 p-3 br100" :class="{ selected: modelValue === sport.TENIS }">
          <img src="../../assets/images/tenis.svg" alt="" />
        </div>
        <div v-if="showLabels">{{ $t("tennis") }}</div>
      </div>
      <div
        v-if="shouldShowSport(sport.PICKLEBALL)"
        class="flex flex-col items-center"
        @click="click($event, sport.PICKLEBALL)"
      >
        <div class="mb-2 p-3 br100" :class="{ selected: modelValue === sport.PICKLEBALL }">
          <img src="../../assets/images/Pickleball.svg" alt="" />
        </div>
        <div v-if="showLabels">{{ $t("pickleball") }}</div>
      </div>
      <div
        v-if="shouldShowSport(sport.SOCCER)"
        class="flex flex-col items-center"
        @click="click($event, sport.SOCCER)"
      >
        <div class="mb-2 p-3 br100" :class="{ selected: modelValue === sport.SOCCER }">
          <img src="../../assets/images/soccer-ball.svg" alt="" />
        </div>
        <div v-if="showLabels">{{ $t("soccer") }}</div>
      </div>
      <div
        v-if="shouldShowSport(sport.PADBOL)"
        class="flex flex-col items-center"
        @click="click($event, sport.PADBOL)"
      >
        <div class="mb-2 p-3 br100" :class="{ selected: modelValue === sport.PADBOL }">
          <img src="@/assets/images/sportIcons/padbolIcon.svg" class="white-icon" alt="" />
        </div>
        <div v-if="showLabels">{{ $t("padbol") }}</div>
      </div>
      <div 
        v-if="shouldShowSport(sport.RACQUETBALL)"
        class="flex flex-col items-center"
        @click="click($event, sport.RACQUETBALL)"
      >
        <div class="mb-2 p-3 br100" :class="{ selected: modelValue === sport.RACQUETBALL }">
          <img src="@/assets/images/sportIcons/racquetbalIcon.svg" class="white-icon" alt="" />
        </div>
        <div v-if="showLabels">{{ $t("racquetball") }}</div>
      </div>
      <div 
        v-if="shouldShowSport(sport.SQUASH)"
        class="flex flex-col items-center"
        @click="click($event, sport.SQUASH)"
      >
        <div class="mb-2 p-3 br100" :class="{ selected: modelValue === sport.SQUASH }">
          <img src="@/assets/images/sportIcons/squashIcon.svg" class="white-icon" alt="" />
        </div>
        <div v-if="showLabels">{{ $t("squash") }}</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Sport } from "@/enum/constants";
import { defineComponent } from "vue";

export default defineComponent({
  name: "SportSelector",
  components: {},
  props: {
    modelValue: Number,
    cssClass: String,
    sports: {
      type: Array,
      required: false,
      default: null
    },
    showLabels: {
      type: Boolean,
      required: false,
      default: true
    },
    titleTop: {
      type: Boolean,
      required: false,
      default: true
    },
  },
  data() {
    return {
      sport: Sport,
    };
  },
  methods: {
    click(e: any, sport: any) {
      this.$emit("update:modelValue", sport);
      this.$emit("sportSelected", sport);
    },
    shouldShowSport(sport: number) {
      return this.sports === null || this.sports.includes(sport);
    },
  },
});
</script>

<style scoped>
.selected {
  background-color: var(--color2);
}

img {
  height: 20px;
  width: 20px;
}
.white-icon {
  filter: brightness(0) invert(1);
}
</style>
