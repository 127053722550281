<template>
  <div class="flex justify-center mt-6 w-full input-component relative">
    <SvgContainer name="inputEmailIcon" />
    <input
      class="w-full input-rounded"
      :class="{ 'is-invalid': !isValid, '': true }"
      :readonly="readonly"
      type="text"
      id="input_email"
      :placeholder="$t('email_address')"
      :value="modelValue"
      @input="onInput($event)"
    />
  </div>
  <div class="flex justify-start">
    <p v-if="error" class="error-message max-w-xs whitespace-normal">{{ error }}</p>
  </div>
</template>

<script>
import SvgContainer from "@/views/SvgContainer.vue";
import { Format } from "@/helpers/formatHelper";

export default {
  name: "InputEmail",
  components: {
    SvgContainer,
  },
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    modelValue: String,
  },

  data() {
    return {
      error: "",
    };
  },
  computed: {
    isValid() {
      return this.error === "";
    },
  },
  methods: {
    onInput(e) {
      if (Format.IsNull(e.target.value)) {
        return;
      }
      const email = e.target.value;
      if (email === null || email === "") {
        this.error = this.$t("the_email_cannot_be_empty");
        this.$emit("update:modelValue", email);
        return;
      }

      const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!regexEmail.test(email)) {
        this.error = this.$t("the_email_is_invalid");
        this.$emit("update:modelValue", email);
        return;
      }
      this.error = "";
      this.$emit("update:modelValue", e.target.value);
    },
  },
};
</script>


