<!-- MainView.vue -->
<template>
    <div>
      <div class="fixed top-16 left-[10%] z-[100]">
        <HistoryBack/>
      </div>
      <MyLikes/>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent } from 'vue';
  import MyLikes from "@/components/myProfile/myLikes.vue";
  import HistoryBack from "@/components/form/historyBack.vue";
  
  export default defineComponent({
    name: 'menuProfileLikes',
    components: {
      MyLikes,
      HistoryBack
    },
  });
  </script>

  