<template>
      <div @click="showCalendar = true" class="active optionButton md:p-4 sm:p-4 relative cursor-pointer m0-auto">
        <div>{{ dateStr }}</div>
      </div>
      <Transition>
      <PopUpLight v-if="showCalendar" @closed="showCalendar = false">
        <SearchCalendar
          :defaultDate="rawDate"
          @dateChanged="setActiveDate"
        ></SearchCalendar>
      </PopUpLight>
    </Transition>

</template>
<script lang="ts">
import { defineComponent } from 'vue';
import PopUpLight from "../popup/popupLight.vue";
import { beautifyDateWithOutTime } from "@/helpers/dateHelper";
import SearchCalendar from "@/views/SearchCalendar.vue";

export default defineComponent({
    name: "CalendarButton",
    emits: ["getAvailability"],
    props: {
        date: {
            type: String,
            required: true
        },
        rawDate: {
            type: String,
            required: false
        },
    },
    components: {
        PopUpLight,
        SearchCalendar
    },
    data() {
        return {
            showCalendar: false as boolean,
            dateStr: "" as string
        };
    },
    mounted(){
        this.dateStr = this.date;
    },
    methods: {
        setActiveDate(date: string) {
            this.showCalendar = false;
            this.dateStr = beautifyDateWithOutTime(date, true, false);
            this.$emit("getAvailability", date);
        },
    },
});
</script>
<style scoped>
</style> 