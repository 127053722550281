<template>
  <Transition>
    <PopUpLight
    v-if="showGenderLevel"
      @closed="closeGenderLevel()"
      :showClose="false"
      :isWhiteTheme="isWhiteTheme"
    >
      <UpdateUser
        :customSport="sport"
        @closeGenderLevel="closeGenderLevel()"
        :isWhiteTheme="isWhiteTheme"
      />
    </PopUpLight>
  </Transition>  
</template>
<script lang="ts">
import { defineComponent } from "vue";
import UpdateUser from "@/components/match/updateUser.vue";
import PopUpLight from "@/components/popup/popupLight.vue";
import { Format } from "@/helpers/formatHelper";
import {customGlobalError} from "@/helpers/errorHelper";
import { AppsHelper } from "@/helpers/appsHelper";
import { WHITE_THEME_BACKGROUND_COLOR } from "@/enum/constants";

export default defineComponent({
  name: "LevelingGender",
  components: {
    PopUpLight,
    UpdateUser
  },
  data() {
    return {
      user: null,
      errorMsg: null as string | null,
      showGenderLevel: false,
      sport: 0,
      isWhiteTheme: false
    };
  },
  async mounted() {
    if (!Format.IsNull(this.$route.params.userToken) &&
        !Format.IsNull(this.$route.params.sportId)
        ) {
          this.sport= Number(this.$route.params.sportId);
          localStorage.setItem("t", JSON.stringify(atob(this.$route.params.userToken as string)));
          this.showGenderLevel = true;
    } else {
      customGlobalError(this.$t("wrong_url"))
      return;
    }
    this.isWhiteTheme = AppsHelper.isWhiteTheme(this.$route.query);
    if(this.isWhiteTheme){
      const bodyElement = document.getElementsByTagName("body")[0];
      bodyElement.style.backgroundImage = 'none';
      bodyElement.style.backgroundColor = WHITE_THEME_BACKGROUND_COLOR;
    }
  },
  methods: {
    closeGenderLevel() {
      this.showGenderLevel = false;
    },
  },
});
</script>
<style scoped>
</style>