<template>
  <div
    class="containerMapsResults md:pb-8"
    v-if="availability !== null && availability.tenant !== undefined"
  >
    <div class="ResultBookWrapper">
      <span
        class="flex justify-center items-center m-5"
        v-if="!onlyOneSport()"
      >
        <div class="flex">
          <SportSelector
          v-model="activeSport"
          :sports="this.availability.tenant.sports"
          :showLabels="false"
          :titleTop="false"
          @sportSelected="selectSport"
        />
        </div>
      </span>
      <div class="ContainerTimeSelector flex mt-3 flex-wrap">
        <div
          v-for="(hour, index) in hours"
          :key="index"
          @click="setActiveHour(hour.value)"
          :class="{ active: activeHour == hour.value }"
          class="optionButton cursor-pointer h-12 w-1/5 rounded-lg flex justify-center items-center text-sm"
        >
          {{ hour.label }}
        </div>
      </div>
      <br />
      <div class="flex justify-center">
        <div class="p-5 withLine textGradient width-fitcontent">
          {{ $t("prefer_team") }}
        </div>
      </div>
    </div>

    <Transition>
      <PopUpLight v-if="errorMsg" @closed="errorMsg = null">
        <div class="mt-8">
          {{ errorMsg }}
        </div>
      </PopUpLight>
    </Transition>
  </div>
</template>
  
<script type="ts">
import { BookingType, Sport } from "@/enum/constants";
import PopUpLight from "../popup/popupLight.vue";
import { setTimeToDate, formatDate, formatTime, beautifyDateWithOutTime } from "@/helpers/dateHelper";
import { BookingApi } from "@/service/BookingApi";
import { checkURLBookingFormat, getSportLabel } from "@/helpers/dataHelper";
import { SearchType, GlobalErrors } from "@/enum/constants";
import { customGlobalError } from "@/helpers/errorHelper";
import { AppsHelper } from "@/helpers/appsHelper";
import { Format } from "@/helpers/formatHelper";
import store from "../../store";
import { BookingHelper } from "@/helpers/bookingHelper";
import SportSelector from "@/components/form/sportSelector.vue";

export default {
  name: "bookBooking",
  props: ["tenantInfo"],
  components: {
    PopUpLight,
    SportSelector
  },
  emits: ["pricesLoaded", "privateClub"],
  data() {
    return {
      hours: [],
      showCalendar: false,
      sport: Sport, // to use in html
      activeHour: "",
      activeSport: Sport.PADEL,
      tenantId: null,
      activeDate: new Date(),
      resources: [],
      availability: null,
      errorMsg: null,
    };
  },
  async created() {
    if (!checkURLBookingFormat) {
      return;
    }

    this.tenantId = this.$route.query.id;
    this.activeHour = this.$route.query.hour;
    if (this.activeHour === undefined) {
      this.activeHour = "0";
    }

    this.activeDate = setTimeToDate(
      new Date(this.$route.query.date),
      this.activeHour
    );
    this.dateStr = beautifyDateWithOutTime(this.activeDate, true, false);


    if (!Format.IsNull(this.$route.query.sport)) {
      this.activeSport = +this.$route.query.sport;//"+" -> try to convert to number
    }
    this.availability = this.tenantInfo;

    this.hours = this.availability.times;

    if(Format.IsNull(this.hours)){
      await this.loadTenantAvailability();
    }

    if(!this.availability.tenant.sports.includes(this.activeSport)){
      customGlobalError(this.$t("sorry_this_club_does_not_have_as_a_sport", { sport: getSportLabel(this.activeSport)}));
      return;
    }
    this.loadPrices();
    
  },
  watch: {
    tenantInfo: {
      handler(newTenantInfo) {
        this.hours = newTenantInfo.times;
      },
      deep: true, //Watch for changes in nested properties of tenantInfo
    },
  },
  methods: {
    async selectSport() {
      this.activeHour = "";
      await this.loadTenantAvailability();
    },
    async loadPrices() {
      const request = {
        tenantId: this.tenantId,
        date: this.getDateSystemFormat(),
        sport: this.activeSport,
        type: BookingType.BOOKING
      };

      store.commit("setLoading", true);
      try {
        const response = await BookingApi.tenantAvailability(request); // load prices
        store.commit("setLoading", false);
        this.resources = response.data.resources;
      } catch (error) {
        if (error.response.status === GlobalErrors.ERROR_PRIVATE_CLUB) {
          this.$emit("privateClub");
        }
        return;
      }

      if (this.resources.length == 0) {
        store.commit("setLoading", true);
        await this.loadTenantAvailability();
        const response = await BookingApi.tenantAvailability(request); // retry with actualized availability
        store.commit("setLoading", false);
        this.resources = response.data.resources;
        if (this.resources.length == 0) {
          customGlobalError(
            this.$t("sorry_we_dont_have_any_availability_at") +
              " " +
              formatTime(this.activeDate, navigator)
          );
          this.fixIphoneHeight();
          return;
        }
      }

      this.$emit("pricesLoaded", {
        date: this.getDateSystemFormat(),
        resources: this.resources,
        sport: this.activeSport,
      });

      this.fixIphoneHeight();
    },
    async loadTenantAvailability() {
      const request = {
        tenantIds: [this.$route.query.id],
        date: this.getDateSystemFormat(),
        sport: this.activeSport,
        category: BookingType.BOOKING,
        searchType: SearchType.FULL,
      };
      store.commit("setLoading", true);
      const message = await BookingHelper.messageAvailability(request);
      store.commit("setLoading", false);

      if (
        Format.IsNull(message.bookings) ||
        message.bookings.length === 0
      ) {
        customGlobalError(this.$t("search_no_resources_found"));
        return;
      }
      this.availability = message.bookings[0];
      this.hours = this.availability.times;
    },
    async setActiveHour(hour) {
      this.activeHour = hour;
      const currentQuery = { ...this.$route.query };
      currentQuery.hour = this.activeHour;
      this.$router.replace({ query: currentQuery }); //replace query hour
      this.activeDate = setTimeToDate(this.activeDate, this.activeHour);
      await this.loadPrices();
    },
    async parentCallGetAvailability(date){
      const currentQuery = { ...this.$route.query };
      currentQuery.date = formatDate(date);
      this.$router.replace({ query: currentQuery }); //replace query date
      this.activeDate = date;
      this.dateStr = beautifyDateWithOutTime(this.activeDate, true, false);
      await this.loadTenantAvailability();
    },
    onlyOneSport() {
      return this.availability.tenant.sports.length === 1;
    },
    getDateSystemFormat() {
      return formatDate(this.activeDate);
    },
    fixIphoneHeight() {
      if (AppsHelper.isSafariOrIosWebView()) {
        const resultsContainer = document.querySelector(".ResultBookWrapper");
        if (resultsContainer) {
          resultsContainer.classList.add("pb-150");
        }
      }
    },
  },
};
</script>
