<!-- MainView.vue -->
<template>
    <div>
      <div class="fixed top-16 left-[10%] z-[100]">
        <HistoryBack/>
      </div>
      <MyPrivacy
        @refresh="handleRefreshPrivacy"
      />
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent } from 'vue';
  import MyPrivacy from "@/components/myProfile/myPrivacy.vue";
  import HistoryBack from "@/components/form/historyBack.vue";
  import CustomerHelper from "@/helpers/customerHelper";
  
  export default defineComponent({
    name: 'menuProfilePrivacy',
    components: {
      MyPrivacy,
      HistoryBack
    },
    methods: {
      async handleRefreshPrivacy(privacy: any) {
        CustomerHelper.updateStoragePrivacy(privacy);
      },
    }
  });
  </script>

  