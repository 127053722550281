<template>
  <div class="mt-4">
    <input
      class="hidden"
      type="hidden"
      ref="datePicker"
      v-model="selectedDate"
    />
  </div>
</template>
  
<script>
import flatpickr from "flatpickr";
import { formatDateWithLocale } from "@/helpers/dateHelper";
import "flatpickr/dist/flatpickr.min.css";
// try more themes at - https://flatpickr.js.org/themes/
import "flatpickr/dist/themes/dark.css";

// Must import to work :S
import { es } from "flatpickr/dist/l10n/es.js";
import { cat } from "flatpickr/dist/l10n/cat.js";

export default {
  data() {
    return {
      selectedDate: null,
    };
  },
  props: ["defaultDate"],
  mounted() {
    const i18nLocale = this.$store.state.i18nLocale;
    flatpickr(this.$refs.datePicker, {
      inline: true,
      locale: i18nLocale,
      minDate: "today",
      dateFormat: formatDateWithLocale(i18nLocale),
      defaultDate: new Date(this.defaultDate),
      onChange: (selectedDates) => {
        this.selectedDate = selectedDates[0];
        this.$emit("dateChanged", this.selectedDate);
      },
    });
  },
};
</script>

<style>
#ResultsContainer .flatpickr-calendar {
  width: 100%;
  background-color: transparent !important;
  box-shadow: none;
}

#ResultsContainer .flatpickr-months {
  background: var(--background-search);
  color: var(--main-color);
  border-radius: 8px;
  font-size: 12px;
  padding: 10px;
}

#ResultsContainer .flatpickr-month {
  color: var(--main-color);
  border-radius: 8px;
  font-size: 12px;
  background-color: transparent !important;
}

.flatpickr-months .flatpickr-month {
  background-color: transparent !important;
}

.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-current-month input.cur-year {
  font-weight: 500;
}

/* .flatpickr-day.selected {
  border-color: #fff !important;
  border: 2px solid #fff;
} */

.flatpickr-day.today {
  border: 2px solid #fff;
}

#ResultsContainer span.flatpickr-weekday {
  color: var(--main-color);
}

#ResultsContainer .flatpickr-day {
  color: var(--main-color);
  min-width: 14.28%;
}

#ResultsContainer .flatpickr-disabled {
  opacity: 0.1;
}

#ResultsContainer .nextMonthDay {
  opacity: 0.1;
}

#ResultsContainer .flatpickr-rContainer {
  background: var(--background-search);
  padding: 20px;
  border-radius: 8px;
}

html body #ResultsContainer .flatpickr-prev-month {
  border-radius: 50%;
  border: 1px solid var(--main-color);
  width: 20px !important;
  height: 20px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  margin: 18px 20px;
}
html body #ResultsContainer .flatpickr-next-month {
  border-radius: 50%;
  border: 1px solid var(--main-color);
  width: 20px !important;
  height: 20px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  margin: 18px 20px;
}

#ResultsContainer .flatpickr-prev-month svg {
  fill: var(--main-color);
  width: 10px !important;
}
#ResultsContainer .flatpickr-next-month svg {
  fill: var(--main-color);
  width: 10px !important;
}

#ResultsContainer .flatpickr-disabled {
  opacity: 0.4;
}

#ResultsContainer .flatpickr-days {
  width: 100%;
}

#ResultsContainer .dayContainer {
  justify-content: start;
}

#ResultsContainer .dayContainer {
  justify-content: start;
  max-width: 100%;
  width: 100%;
  min-width: 100%;
}

#ResultsContainer .dayContainer span {
  margin: 0;
}

#ResultsContainer .flatpickr-rContainer {
  width: 100%;
}

#ResultsContainer
  .flatpickr-current-month
  select.flatpickr-monthDropdown-months {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

#ResultsContainer .flatpickr-months {
  margin-bottom: 20px;
}
</style>