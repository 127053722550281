/* tslint:disable */
/* eslint-disable */
/**
 * Marketplace Api
 * Marketplace Api description
 *
 * OpenAPI spec version: 1.0
 * Contact: develop@taykus.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { AuthorizeSaleRequest } from '../models';
import { Sale } from '../models';
/**
 * SaleApi - axios parameter creator
 * @export
 */
export const SaleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Authorize the payment of a sale
         * @param {AuthorizeSaleRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizeSale: async (body: AuthorizeSaleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling authorizeSale.');
            }
            const localVarPath = `/api/sale`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers!['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the payment 3DS data
         * @param {{ [key: string]: string; }} body 
         * @param {string} id 
         * @param {string} [userAgent] 
         * @param {string} [accept] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizeSaleWithAuthorization: async (body: { [key: string]: string; }, id: string, userAgent?: string, accept?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling authorizeSaleWithAuthorization.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling authorizeSaleWithAuthorization.');
            }
            const localVarPath = `/api/sale/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (userAgent !== undefined && userAgent !== null) {
                localVarHeaderParameter['User-Agent'] = String(userAgent);
            }

            if (accept !== undefined && accept !== null) {
                localVarHeaderParameter['Accept'] = String(accept);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers!['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all the customer sales
         * @param {number} start Zero-based page index
         * @param {number} size The page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSaleByCustomer: async (start: number, size: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'start' is not null or undefined
            if (start === null || start === undefined) {
                throw new RequiredError('start','Required parameter start was null or undefined when calling findSaleByCustomer.');
            }
            // verify required parameter 'size' is not null or undefined
            if (size === null || size === undefined) {
                throw new RequiredError('size','Required parameter size was null or undefined when calling findSaleByCustomer.');
            }
            const localVarPath = `/api/sale`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a sale by id
         * @param {string} id The sale id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSaleById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling findSaleById.');
            }
            const localVarPath = `/api/sale/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update sale to be retried
         * @param {string} id The sale id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrySale: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling retrySale.');
            }
            const localVarPath = `/api/sale/{id}/retry`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} body 
         * @param {string} stripeSignature 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeWebhook: async (body: string, stripeSignature: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling stripeWebhook.');
            }
            // verify required parameter 'stripeSignature' is not null or undefined
            if (stripeSignature === null || stripeSignature === undefined) {
                throw new RequiredError('stripeSignature','Required parameter stripeSignature was null or undefined when calling stripeWebhook.');
            }
            const localVarPath = `/api/sale/webhook/stripe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (stripeSignature !== undefined && stripeSignature !== null) {
                localVarHeaderParameter['Stripe-Signature'] = String(stripeSignature);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers!['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SaleApi - functional programming interface
 * @export
 */
export const SaleApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Authorize the payment of a sale
         * @param {AuthorizeSaleRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authorizeSale(body: AuthorizeSaleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Sale>>> {
            const localVarAxiosArgs = await SaleApiAxiosParamCreator(configuration).authorizeSale(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the payment 3DS data
         * @param {{ [key: string]: string; }} body 
         * @param {string} id 
         * @param {string} [userAgent] 
         * @param {string} [accept] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authorizeSaleWithAuthorization(body: { [key: string]: string; }, id: string, userAgent?: string, accept?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Sale>>> {
            const localVarAxiosArgs = await SaleApiAxiosParamCreator(configuration).authorizeSaleWithAuthorization(body, id, userAgent, accept, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get all the customer sales
         * @param {number} start Zero-based page index
         * @param {number} size The page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findSaleByCustomer(start: number, size: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<Sale>>>> {
            const localVarAxiosArgs = await SaleApiAxiosParamCreator(configuration).findSaleByCustomer(start, size, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get a sale by id
         * @param {string} id The sale id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findSaleById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Sale>>> {
            const localVarAxiosArgs = await SaleApiAxiosParamCreator(configuration).findSaleById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update sale to be retried
         * @param {string} id The sale id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrySale(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Sale>>> {
            const localVarAxiosArgs = await SaleApiAxiosParamCreator(configuration).retrySale(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} body 
         * @param {string} stripeSignature 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stripeWebhook(body: string, stripeSignature: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Sale>>> {
            const localVarAxiosArgs = await SaleApiAxiosParamCreator(configuration).stripeWebhook(body, stripeSignature, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SaleApi - factory interface
 * @export
 */
export const SaleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Authorize the payment of a sale
         * @param {AuthorizeSaleRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authorizeSale(body: AuthorizeSaleRequest, options?: AxiosRequestConfig): Promise<AxiosResponse<Sale>> {
            return SaleApiFp(configuration).authorizeSale(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the payment 3DS data
         * @param {{ [key: string]: string; }} body 
         * @param {string} id 
         * @param {string} [userAgent] 
         * @param {string} [accept] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authorizeSaleWithAuthorization(body: { [key: string]: string; }, id: string, userAgent?: string, accept?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Sale>> {
            return SaleApiFp(configuration).authorizeSaleWithAuthorization(body, id, userAgent, accept, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all the customer sales
         * @param {number} start Zero-based page index
         * @param {number} size The page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findSaleByCustomer(start: number, size: number, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<Sale>>> {
            return SaleApiFp(configuration).findSaleByCustomer(start, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a sale by id
         * @param {string} id The sale id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findSaleById(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Sale>> {
            return SaleApiFp(configuration).findSaleById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update sale to be retried
         * @param {string} id The sale id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrySale(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Sale>> {
            return SaleApiFp(configuration).retrySale(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} body 
         * @param {string} stripeSignature 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stripeWebhook(body: string, stripeSignature: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Sale>> {
            return SaleApiFp(configuration).stripeWebhook(body, stripeSignature, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SaleApi - object-oriented interface
 * @export
 * @class SaleApi
 * @extends {BaseAPI}
 */
export class SaleApi extends BaseAPI {
    /**
     * 
     * @summary Authorize the payment of a sale
     * @param {AuthorizeSaleRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SaleApi
     */
    public async authorizeSale(body: AuthorizeSaleRequest, options?: AxiosRequestConfig) : Promise<AxiosResponse<Sale>> {
        return SaleApiFp(this.configuration).authorizeSale(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get the payment 3DS data
     * @param {{ [key: string]: string; }} body 
     * @param {string} id 
     * @param {string} [userAgent] 
     * @param {string} [accept] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SaleApi
     */
    public async authorizeSaleWithAuthorization(body: { [key: string]: string; }, id: string, userAgent?: string, accept?: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Sale>> {
        return SaleApiFp(this.configuration).authorizeSaleWithAuthorization(body, id, userAgent, accept, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get all the customer sales
     * @param {number} start Zero-based page index
     * @param {number} size The page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SaleApi
     */
    public async findSaleByCustomer(start: number, size: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<Sale>>> {
        return SaleApiFp(this.configuration).findSaleByCustomer(start, size, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get a sale by id
     * @param {string} id The sale id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SaleApi
     */
    public async findSaleById(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Sale>> {
        return SaleApiFp(this.configuration).findSaleById(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Update sale to be retried
     * @param {string} id The sale id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SaleApi
     */
    public async retrySale(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Sale>> {
        return SaleApiFp(this.configuration).retrySale(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} body 
     * @param {string} stripeSignature 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SaleApi
     */
    public async stripeWebhook(body: string, stripeSignature: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Sale>> {
        return SaleApiFp(this.configuration).stripeWebhook(body, stripeSignature, options).then((request) => request(this.axios, this.basePath));
    }
}
