import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { MatchApiAxiosParamCreator } from '../../apis/match-api';
import { BaseApi } from './BaseApi';
import { MatchRequest } from 'models/match-request';


export class MatchApi extends BaseApi {

  /**
  * 
  * @summary Create a match
  * @param {MatchRequest} body 
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
  public static async createMatch(body: MatchRequest, options?: AxiosRequestConfig): Promise<AxiosResponse<any>>{
    const axiosArgs = await MatchApiAxiosParamCreator().createMatch(body, options);
    
    return this.getAuthorizedAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }

  /**
  * 
  * @summary Create a match
  * @param {MatchRequest} body 
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
  public static async createMatchWithoutSale(body: MatchRequest, options?: AxiosRequestConfig): Promise<AxiosResponse<any>>{
    const axiosArgs = await MatchApiAxiosParamCreator().createMatchWithoutSale(body, options);
    
    return this.getAuthorizedAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }

  
  /**
  * 
  * @summary Get all matches alive
  * @param {number} start Zero-based page index
  * @param {number} size The page size
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
  public static async findAllMatches(start: number, size: number, options?: AxiosRequestConfig): Promise<AxiosResponse<any>>{
    const axiosArgs = await MatchApiAxiosParamCreator().findAllMatches(start, size, options);
    
    return this.getAuthorizedAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }
        
}
