<template>
  <Transition>
    <Popup
      v-if="openRegister || $store.state.registerIsOpen"
      :showOkButton="false"
      @closed="closeRegister()"
    >
    <div class="max-w-md">
      <div>
        <h4 class="text-center">
          <header>
            <img
              class="inline-block logo"
              alt="Taykus logo"
              :src="clubData.logo"
            />
          </header>
        </h4>
      </div>
      <h2>{{ $t("register") }}</h2>
      <p>{{ $t("discover_your_places_in_the_world") }}</p>
      <form @submit.prevent="submitForm">
        <div class="flex justify-center mt-6 w-full input-component relative">
          <SvgContainer name="registerFullNameIcon" />
          <input
            class="w-full input-rounded"
            type="text"
            :placeholder="$t('full_name')"
            v-model="fullName"
            @input="validateFullName"
            :class="{
              'is-invalid': !isFullNameValid && isFullNameTouched,
            }"
          />
        </div>
        <div class="flex justify-start">
          <p
          v-if="!isFullNameValid && isFullNameTouched"
          class="validation-error max-w-xs whitespace-normal"
          >
            {{ $t("full_name_cant_be_null") }}
          </p>
        </div>
        
        <InputPhone ref="inputPhone" @phoneValidated="handlePhoneValidated" />
        <InputEmail ref="inputEmail" v-model="email" />
        <InputPassword
          ref="inputPsw"
          @passwordValidated="handlePasswordValidated"
        />
        <div class="flex justify-center w-full mt-4">
          <div class="relative w-full input-component policy">
            <div class="input-table flex w-6/6">
              <input
                type="checkbox"
                class="cursor-pointer"
                v-model="isChecked"
                @click="isChecked = !isChecked"
                :disabled="sendMailMessage != ''"
                :class="{ seleccionado: isChecked, 'is-invalid': !isChecked }"
              />
              <label>
                <span @click="isChecked = !isChecked" style="cursor: pointer"
                  >{{ $t("i_have_and_agree") }}&nbsp;</span
                >
                <a id="legalLink" href="#" @click="openLegal()">{{
                  $t("private_policy")
                }}</a>
              </label>
            </div>
          </div>
        </div>
        <div
          v-if="sendMailMessage == ''"
          class="flex justify-center w-full mt-8 input-component"
        >
          <input
            type="submit"
            :value="$t('register')"
            :disabled="hasErrors"
            :class="{ 'button-disabled': hasErrors, '': true }"
          />
        </div>
        <div
          v-if="sendMailMessage"
          id="sendMailMessage"
          class="flex justify-center w-full"
        >
          <h2 class="send-mail-message">{{ sendMailMessage }}</h2>
        </div>
      </form>
      <div id="registerError" class="flex justify-center w-full">
        <p class="form-error max-w-xs whitespace-normal">{{ registerError }}</p>
      </div>
      <div class="flex justify-center w-full create-account">
        <div class="relative w-full account-text">
          <SvgContainer name="registerLeftBar" />
          <span>{{ $t("or_create_account_with") }}</span>
          <SvgContainer name="registerRightBar" />
        </div>
      </div>
      <div class="flex justify-center w-full">
        <div class="relative w-3/6 float-right">
          <CustomGoogleLogin @success="handleAppLoginSuccess" />
        </div>
        <div class="relative w-3/6">
          <CustomAppleLogin @success="handleAppLoginSuccess" />
        </div>
      </div>
      <div>
        <div
          class="walletButton mt-6 p-4 text-center cursor-pointer sm:text-sm"
          @click="showLogin()"
        >
          {{ $t("login") }}
        </div>
      </div>
    </div>
    </Popup>
  </Transition>
</template>


<script lang="ts">
import { defineComponent, ref } from "vue";
import Popup from "../../components/popup/popup.vue";
import InputPassword from "../../components/form/inputPassword.vue";
import InputEmail from "../../components/form/inputEmail.vue";
import InputPhone from "../../components/form/inputPhone.vue";
import CustomGoogleLogin from "@/components/google/customGoogleLogin.vue";
import store from "../../store";
import SvgContainer from "@/views/SvgContainer.vue";
import { RegisterApi } from "../../service/RegisterApi";
import { CustomerApi } from "../../service/CustomerApi";
import LocalStorage from "../../helpers/localStorage";
import { DayPreference } from "../../custom-models/day-preference";
import CustomAppleLogin from "@/components/apple/customAppleLogin.vue";
import { clearURL } from "@/helpers/dataHelper";

export default defineComponent({
  name: "RegisterPopUp",
  components: {
    Popup,
    InputPassword,
    InputEmail,
    InputPhone,
    SvgContainer,
    CustomGoogleLogin,
    CustomAppleLogin,
  },
  props: {
    open: { type: Boolean, default: false },
    returnUrl: { type: String },
  },

  setup() {
    const isActive = ref(false);
    const isChecked = ref(false);
    return {
      isActive,
      isChecked,
    };
  },
  computed: {
    isFullNameValid(): boolean {
      return this.validateFullName();
    },
    hasErrors(): boolean {
      //test only return false;/*
      return (
        this.password == null ||
        this.password == "" ||
        this.email == null ||
        this.email == "" ||
        this.phone == null ||
        this.phone == "" ||
        !this.isFullNameValid ||
        !this.isChecked
      );
    },
  },
  data() {
    return {
      clubData: {
        name: "test",
        fontColor: "#2B4164",
        background: "white",
        logo: /*'https://i.imgur.com/U6T5onI.png' ||*/ require("@/assets/images/header-popup.png"),
        buttons: {
          color1: "black",
          color2: "white",
        },
      },
      isContainerClosed: false,
      openRegister: false,
      error: "",
      isRegister: true,
      fullName: "",
      phone: "",
      email: "",
      password: "",
      isFullNameTouched: false,
      registerError: "",
      sendMailMessage: "",
      token: null as string | null,
      user: null,
      prefix: "",
    };
  },

  async created() {
    this.token = Array.isArray(this.$route.query.token)
      ? this.$route.query.token[0]
      : this.$route.query.token;
    if (this.token) {
      clearURL();
      await RegisterApi.endRegister(this.token);
      this.getUser();
      this.restoreOnboarding();
      return;
    }
  },
  mounted() {
    this.openRegister = this.open;
    this.error = "";
    this.registerError = "";
    this.$watch("fullName", () => {
      if (!this.isFullNameTouched) {
        this.isFullNameTouched = true;
      }
    });
  },
  updated() {
    //refresh all when component change
    this.isFullNameTouched = false;
  },
  methods: {
    closeContainer() {
      this.isContainerClosed = true;
    },
    async register(data: any) {
      //this.Onboarding();//test only
      try {
        await RegisterApi.initRegister(
          data.username,
          data.password,
          data.fullName,
          data.prefix,
          data.phone
        );
        this.registerError = "";
        this.sendMailMessage = this.$t("you_are_one_step_away_from_being_a_tayker");
      } catch (error: any) {
        this.registerError = error.response.data.messages[0];
      }
      const currentDate = new Date();
      currentDate.setHours(currentDate.getHours() + 1);

      LocalStorage.addWithTimout(
        "url_redirect",
        this.$route.fullPath,
        currentDate
      );
    },
    async getUser() {
      await CustomerApi.get();
      store.commit("setUser", JSON.parse(localStorage.getItem("user")!));
    },
    showLogin() {
      store.commit("closeRegister");
      store.commit("openLogin", { allowClose: true });
    },
    async restoreOnboarding() {
      const response = await CustomerApi.getPreferences();
      const preferences = response.data;
      if (preferences != null) {
        let mappedDaysToArray = [];
        if (preferences.days != null) {
          mappedDaysToArray = preferences.days.map((day: DayPreference) => [
            day.morning,
            day.noon,
            day.afternoon,
          ]);
        }

        let action = 0;
        let sport = 0;
        let places = [];
        let tenants = [];

        if (preferences.action != null) {
          action = preferences.action;
        }
        if (preferences.sport != null) {
          sport = preferences.sport;
        }
        if (preferences.places != null) {
          places = preferences.places;
        }
        if (preferences.tenants != null) {
          tenants = preferences.tenants;
        }

        store.commit("setPreferencesAction", action);
        store.commit("setPreferencesSport", sport);
        store.commit("setPlayDayTimes", mappedDaysToArray);
        store.commit("setPreferencesPlaces", places);
        store.commit("setPreferencesTenants", tenants);
      }
      this.Onboarding();
    },
    handlePhoneValidated(phone: any, prefix: string) {
      this.sendMailMessage = "";
      this.phone = phone;
      this.prefix = prefix;
    },
    handlePasswordValidated(password: any) {
      this.sendMailMessage = "";
      this.password = password;
    },
    handleAppLoginSuccess(isNew: boolean) {
      this.closeRegister();
      this.getUser();
      if (isNew) {
        this.Onboarding();
      }
    },
    clearComponents() {
      this.sendMailMessage = "";
      this.fullName = "";
      this.phone = "";
      this.email = "";
      this.password = "";
      this.isChecked = false;
      const inputEmailComponent = this.$refs.inputEmail as {
        clear: () => void;
      };
      const inputPhoneComponent = this.$refs.inputPhone as {
        clear: () => void;
      };
      const inputPswComponent = this.$refs.inputPsw as { clear: () => void };
      if (
        inputEmailComponent &&
        typeof inputEmailComponent.clear === "function"
      ) {
        inputEmailComponent.clear();
      }
      if (
        inputPhoneComponent &&
        typeof inputPhoneComponent.clear === "function"
      ) {
        inputPhoneComponent.clear();
      }
      if (inputPswComponent && typeof inputPswComponent.clear === "function") {
        inputPswComponent.clear();
      }
    },
    closeRegister() {
      this.error = "";
      this.sendMailMessage = "";
      this.openRegister = false;
      this.clearComponents();
      store.commit("closeRegister");
    },
    Onboarding() {
      store.commit("openOnboarding");
    },
    validateFullName() {
      this.sendMailMessage = "";
      if (this.fullName === null || this.fullName === "") {
        return false;
      }
      return true;
    },
    submitForm() {
      const data = {
        fullName: this.fullName,
        phone: this.phone,
        username: this.email,
        password: this.password,
        prefix: this.prefix,
      };
      this.register(data);
    },
    openLegal() {
      window.open("/legal", "_blank");
    },
  },
});
</script>
<style>
.form-error {
  color: #f472b6 !important;
}
.validation-error {
  color: #f472b6 !important;
}
.is-invalid {
  border: 1px solid #f472b6 !important;
}
.button-disabled {
  opacity: 0.5 !important;
  cursor: default !important;
}
.send-mail-message {
  font-size: 24px !important;
  text-align: center !important;
  margin: 30px 0px 0px 0px !important;
}
.create-account {
  margin: 10px 0px 10px 0px;
}
.reference-mail-register {
  z-index: 9;
  backdrop-filter: blur(4px);
}
.wrapper-register-mail {
  width: 327px;
  height: 480px;
}
.wrapper-register-mail.closed {
  display: none;
}
#legalLink {
  color: gold !important;
}
</style>