import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "hidden" }
const _hoisted_2 = ["action"]
const _hoisted_3 = ["id", "name"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      id: "acsUrlForm",
      action: _ctx.acsUrl,
      method: "POST"
    }, [
      _withDirectives(_createElementVNode("input", {
        class: "hidden",
        id: _ctx.acsParamName,
        name: _ctx.acsParamName,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.acsParamValue) = $event))
      }, null, 8, _hoisted_3), [
        [_vModelText, _ctx.acsParamValue]
      ])
    ], 8, _hoisted_2)
  ]))
}