import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-83f8dd02"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex justify-start" }
const _hoisted_2 = { class: "flex flex-wrap mt-6 cursor-pointer w-full justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.cssClass)
  }, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t("select_your_gender")), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.click($event, _ctx.gender.MAN))),
        class: _normalizeClass(["flex flex-col items-center p-2 pl-4 pr-4 clueColor br60", { selected: _ctx.modelValue === _ctx.gender.MAN }])
      }, _toDisplayString(_ctx.$t(_ctx.genderLabel.MALE)), 3),
      _createElementVNode("div", {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.click($event, _ctx.gender.WOMAN))),
        class: _normalizeClass(["flex flex-col items-center p-2 pl-4 pr-4 clueColor br60 ml-4", { selected: _ctx.modelValue === _ctx.gender.WOMAN }])
      }, _toDisplayString(_ctx.$t(_ctx.genderLabel.FEMALE)), 3)
    ])
  ], 2))
}