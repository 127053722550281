import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-30a344ce"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fixed top-16 left-[10%] z-[100]" }
const _hoisted_2 = { class: "MyPrivacity p-8 text-sm" }
const _hoisted_3 = { class: "flex items-center justify-center" }
const _hoisted_4 = { class: "mt-12 br16 altBackground p-6" }
const _hoisted_5 = { class: "flex p-8 pl-4 pr-0 items-center justify-center flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HistoryBack = _resolveComponent("HistoryBack")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_HistoryBack)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("remove_account")), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", null, _toDisplayString(_ctx.$t("we_are_going_to_miss_you")) + _toDisplayString(_ctx.$t("if_you_change_your_mind_do_not_hesitate_to_register_again")), 1),
          _createElementVNode("div", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.remove())),
            class: "walletButton p-1 text-center cursor-pointer text-sm mt-4"
          }, _toDisplayString(_ctx.$t("remove_account")), 1)
        ])
      ])
    ])
  ], 64))
}