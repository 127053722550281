/* tslint:disable */
/* eslint-disable */
/**
 * Marketplace Api
 * Marketplace Api description
 *
 * OpenAPI spec version: 1.0
 * Contact: develop@taykus.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { Token } from '../models';
/**
 * RegisterApi - axios parameter creator
 * @export
 */
export const RegisterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Service to finalize a register process
         * @param {string} token The token received to finalize registration process
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        endRegister: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling endRegister.');
            }
            const localVarPath = `/api/register/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Service to register a new user
         * @param {string} username The username used to be identified
         * @param {string} password The user password
         * @param {string} fullName The user password
         * @param {string} prefix The phone code area
         * @param {string} phone The user phone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initRegister: async (username: string, password: string, fullName: string, prefix: string, phone: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            if (username === null || username === undefined) {
                throw new RequiredError('username','Required parameter username was null or undefined when calling initRegister.');
            }
            // verify required parameter 'password' is not null or undefined
            if (password === null || password === undefined) {
                throw new RequiredError('password','Required parameter password was null or undefined when calling initRegister.');
            }
            // verify required parameter 'fullName' is not null or undefined
            if (fullName === null || fullName === undefined) {
                throw new RequiredError('fullName','Required parameter fullName was null or undefined when calling initRegister.');
            }
            // verify required parameter 'prefix' is not null or undefined
            if (prefix === null || prefix === undefined) {
                throw new RequiredError('prefix','Required parameter prefix was null or undefined when calling initRegister.');
            }
            // verify required parameter 'phone' is not null or undefined
            if (phone === null || phone === undefined) {
                throw new RequiredError('phone','Required parameter phone was null or undefined when calling initRegister.');
            }
            const localVarPath = `/api/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }

            if (password !== undefined) {
                localVarQueryParameter['password'] = password;
            }

            if (fullName !== undefined) {
                localVarQueryParameter['fullName'] = fullName;
            }

            if (prefix !== undefined) {
                localVarQueryParameter['prefix'] = prefix;
            }

            if (phone !== undefined) {
                localVarQueryParameter['phone'] = phone;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RegisterApi - functional programming interface
 * @export
 */
export const RegisterApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Service to finalize a register process
         * @param {string} token The token received to finalize registration process
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async endRegister(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Token>>> {
            const localVarAxiosArgs = await RegisterApiAxiosParamCreator(configuration).endRegister(token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Service to register a new user
         * @param {string} username The username used to be identified
         * @param {string} password The user password
         * @param {string} fullName The user password
         * @param {string} prefix The phone code area
         * @param {string} phone The user phone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initRegister(username: string, password: string, fullName: string, prefix: string, phone: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await RegisterApiAxiosParamCreator(configuration).initRegister(username, password, fullName, prefix, phone, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * RegisterApi - factory interface
 * @export
 */
export const RegisterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Service to finalize a register process
         * @param {string} token The token received to finalize registration process
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async endRegister(token: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Token>> {
            return RegisterApiFp(configuration).endRegister(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Service to register a new user
         * @param {string} username The username used to be identified
         * @param {string} password The user password
         * @param {string} fullName The user password
         * @param {string} prefix The phone code area
         * @param {string} phone The user phone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initRegister(username: string, password: string, fullName: string, prefix: string, phone: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return RegisterApiFp(configuration).initRegister(username, password, fullName, prefix, phone, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RegisterApi - object-oriented interface
 * @export
 * @class RegisterApi
 * @extends {BaseAPI}
 */
export class RegisterApi extends BaseAPI {
    /**
     * 
     * @summary Service to finalize a register process
     * @param {string} token The token received to finalize registration process
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegisterApi
     */
    public async endRegister(token: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Token>> {
        return RegisterApiFp(this.configuration).endRegister(token, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Service to register a new user
     * @param {string} username The username used to be identified
     * @param {string} password The user password
     * @param {string} fullName The user password
     * @param {string} prefix The phone code area
     * @param {string} phone The user phone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegisterApi
     */
    public async initRegister(username: string, password: string, fullName: string, prefix: string, phone: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return RegisterApiFp(this.configuration).initRegister(username, password, fullName, prefix, phone, options).then((request) => request(this.axios, this.basePath));
    }
}
