import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex flex-wrap paddig-responsive" }
const _hoisted_2 = { class: "flex w-full text-left center-responsive" }
const _hoisted_3 = { class: "flex w-full mt-8" }
const _hoisted_4 = { class: "flex flex-col" }
const _hoisted_5 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.$t("sport_highest_level_compete_question", { sport: _ctx.getSportLabel(_ctx.Sport.TENIS)})), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
          return (_openBlock(), _createElementBlock("label", {
            key: option.id,
            class: "flex items-center mb-2"
          }, [
            _withDirectives(_createElementVNode("input", {
              type: "radio",
              value: option.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.levelCompeted) = $event)),
              onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showValue())),
              class: "mr-2"
            }, null, 40, _hoisted_5), [
              [_vModelRadio, _ctx.levelCompeted]
            ]),
            _createElementVNode("span", null, _toDisplayString(option.label), 1)
          ]))
        }), 128))
      ])
    ])
  ]))
}