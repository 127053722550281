import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1ea1f046"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.css, "flex justify-center colorButton cursor-pointer"]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.click()))
  }, [
    _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.text), 1)
  ], 2))
}