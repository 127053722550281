<template>
  <div class="br16 flex flex-col altBackground p-4 pb-6 pt-6 w-full items-center">
    <span>{{ firstValue }}</span>
    <span>{{ secondValue }}</span>
  </div>
</template>

<script>
  export default {
    name: "ResultBox",
    props: {
      firstValue: {
        type: String,
        required: false,
      },
      secondValue: {
        type: String,
        required: false,
      }
    }
  };
</script>

<style scoped>
</style>