import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { ActivityApiAxiosParamCreator } from '../../apis/activity-api';
import { BaseApi } from './BaseApi';
import { PreBooking } from 'models';

export class ActivityApi extends BaseApi {

    /**
    * 
    * @summary Add customer to an activity
    * @param {string} id The booking id
    * @param {*} [options] Override http request option.
    * @throws {RequiredError}
    * @memberof ActivityApi
    */
    public static async addToActivity(id: string, options?: AxiosRequestConfig):  Promise<AxiosResponse<PreBooking>> {
    const axiosArgs = await ActivityApiAxiosParamCreator().addToActivity(id, options);
    
    return this.getAuthorizedAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  } 
    /**
    * 
    * @summary Get activity by Hash
    * @param {string} id The activity id
    * @param {*} [options] Override http request option.
    * @throws {RequiredError}
    */
    public static async findByHash(id: string, options?: AxiosRequestConfig):  Promise<AxiosResponse<PreBooking>> {
    const axiosArgs = await ActivityApiAxiosParamCreator().findByHash(id, options);
    
    return this.getAuthorizedAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }
}
