<template>
  <div id="slider-wrapper" class="wrapper-scroll-js" ref="wrapper">
    <div class="container-scroll-js" ref="container">
      <div class="content-scroll-js flex" ref="content">
        <div class="container-hours flex mt-2">
          <div
           
            v-for="(hour, index) in fullHours"
            :key="index"
          >
            <router-link class="walletButton pl-2 pr-2" to="#" @click="hourSelected(hour.value)">
              {{ hour.label }}
            </router-link>
          </div>
          <div v-if="showShowMore"
            @click="showMore"
           >
            <span class="walletButton pl-2 pr-2 cursor-pointer">{{$t("show_more")}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { BookingHelper } from "@/helpers/bookingHelper";
import { getSearchDataToSend } from "@/helpers/dataHelper";
import { useStore } from "vuex";
import { SearchType } from "@/enum/constants";

export default {
  created() {
    this.store = useStore();
    this.fullHours = this.hours;
  },
  mounted() {
    this.installScrollShadow();
    this.installDragScrollX();
  },
  props: {
    hours: {
      type: Array,
      required: false,
    },
    tenantId: {
      type: String,
      required: true,
    },
  },
   data() {
    return {
      fullHours: [],
      showShowMore: true
    };
  },
  emits: ["hourSelected"],
  methods: {
    async showMore(){
      let dataToSend = getSearchDataToSend();
      let arrayTenant = [];
      arrayTenant[0] = this.tenantId;
      dataToSend.tenantIds = arrayTenant;
      dataToSend.searchType = SearchType.FULL,
      this.store.commit("setLoading", true);
      const message = await BookingHelper.messageAvailability(dataToSend);
      const tenant = message.bookings[0];
      this.store.commit("setLoading", false);
      // Merge arrays
      this.fullHours = [...this.fullHours, ...tenant.times];
      const map = new Map();
      this.fullHours.forEach(obj => map.set(obj.value, obj));
      // Convert Map values back to an array
      this.fullHours = Array.from(map.values());
      this.showShowMore = false;
    },
    hourSelected(hour) {
      this.$emit("hourSelected", hour);
    },
    installScrollShadow() {
      const wrapper = this.$refs.wrapper;
      const container = this.$refs.container;
      const content = this.$refs.content;

      const scrollShadowHorizontal = () => {
        const containerSize = Math.round(container.offsetWidth);
        const contentSize = Math.round(content.offsetWidth);
        const scrollLeft = Math.round(container.scrollLeft + containerSize);

        if (container.scrollLeft > 6) {
          wrapper.classList.add("scroll-left");
        } else {
          wrapper.classList.remove("scroll-left");
        }

        if (scrollLeft >= contentSize || contentSize <= containerSize) {
          wrapper.classList.remove("scroll-right");
        } else {
          wrapper.classList.add("scroll-right");
        }
      };

      container.addEventListener("scroll", scrollShadowHorizontal);
      window.addEventListener("resize", scrollShadowHorizontal);

      scrollShadowHorizontal();
    },
    installDragScrollX() {
      const container = this.$refs.container;

      let mouseDown = false;
      let startX, scrollLeft;
      let touchStartX = 0;
      let isDragging = false;

      const startDragging = (e) => {
        mouseDown = true;
        startX = e.pageX - container.offsetLeft;
        scrollLeft = container.scrollLeft;
      };

      const stopDragging = () => {
        mouseDown = false;
      };

      container.addEventListener("mousemove", (e) => {
        e.preventDefault();
        if (!mouseDown) return;

        const x = e.pageX - container.offsetLeft;
        const scroll = x - startX;
        container.scrollLeft = scrollLeft - scroll;
      });

      container.addEventListener("mousedown", startDragging, false);
      container.addEventListener("mouseup", stopDragging, false);
      container.addEventListener("mouseleave", stopDragging, false);

      container.addEventListener('touchstart', (e) => {
          const touch = e.touches[0];
          touchStartX = touch.clientX;
          scrollLeft = container.scrollLeft;
          isDragging = true;
      });

      container.addEventListener('touchmove', (e) => {
          if (!isDragging) return;
          const touch = e.touches[0];
          const touchX = touch.clientX;
          const deltaX = touchX - touchStartX;
          container.scrollLeft = scrollLeft - deltaX;
      });

      container.addEventListener('touchend', () => {
          isDragging = false;
      });

      container.addEventListener('touchcancel', () => {
          isDragging = false;
      });
    },
  },
};
</script>

<style scoped>
#slider-wrapper .container {
  max-width: 960px;
}
#slider-wrapper .container.container-sm {
  max-width: 620px;
}
.wrapper-scroll-js {
  position: relative;
  overflow: hidden;
}
.wrapper-scroll-js .container-scroll-js {
  display: block;
  overflow: auto;
  overflow-x: hidden;
}
.wrapper-scroll-js .container-scroll-js .content-scroll-js {
  display: inline-block;
  white-space: nowrap;
}
.wrapper-scroll-js:before,
.wrapper-scroll-js:after {
  opacity: 0;
  display: block;
  content: "";
  position: absolute;
  height: 100%;
  box-shadow: 0 0px 8px 8px rgba(0, 0, 0, 0.45);
  border-radius: 100%;
  z-index: 2;
  visibility: hidden;
  transition: 0.6s;
  width: 35px;
  left: -35px;
}
.wrapper-scroll-js:after {
  top: 0;
  left: auto;
  right: -35px;
}
.wrapper-scroll-js.scroll-right:after {
  opacity: 1;
  visibility: visible;
}
.wrapper-scroll-js.scroll-left:before {
  opacity: 1;
  visibility: visible;
}
#slider-wrapper .container-hours {
  gap: 10px;
}
#slider-wrapper .container-hours div {
  border-radius: 16px;
}
.container-hours {
  gap: 10px;
}
.container-scroll-js::-webkit-scrollbar {
  -webkit-appearance: none;
}
.container-scroll-js {
  padding-bottom: 20px;
}
</style>