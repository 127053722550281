<template>
  <div>
    <form novalidate>
    <div class="flex justify-center w-full input-component relative">
      <SvgContainer name="inputText" />
      <input
        class="w-full input-rounded"
        :class="{ 'is-invalid': !isValid, '': true }"
        :readonly="readonly"
        type="text"
        :placeholder="placeholder"
        :value="modelValue"
        @input="onInput($event)"
        :pattern = "pattern"
      />
    </div>
    </form>
    <p v-if="error" class="error-message">{{ error }}</p>
  </div>
</template>

<script>
import SvgContainer from "@/views/SvgContainer.vue";
import { Format } from "@/helpers/formatHelper";
export default {
  name: "InputText",
  components: {
    SvgContainer,
  },
  props: {
    readonly: Boolean,
    placeholder: String,
    modelValue: String,
    pattern: {
      type: RegExp,
      required:false,
    },
  },
  data() {
    return {
      error: "",
    };
  },
  computed: {
    isValid() {
      return this.error === "";
    },
  },
  methods: {
    onInput(e) {
        if (this.pattern && !this.pattern.test(e.target.value)) {
          e.target.value = e.target.value.slice(0, -1);
        }
      this.$emit(
        "update:modelValue",
        Format.removeNonPrintableChars(e.target.value)
      );
    },
  },
};
</script>

