import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { EmailApiAxiosParamCreator } from '../../apis/email-api';
import { BaseApi } from './BaseApi';

export class EmailApi extends BaseApi {

  /**
  * 
  * @summary Sent an email to contact with Tenants
  * @param {string} name The tenant name
  * @param {string} email The email address
  * @param {string} phonePrefix The phone prefix
  * @param {string} phone The phone number
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
  public static async sendEmail(tenantName: string, name: string, email: string, phonePrefix: string, phone: string, options?: AxiosRequestConfig): Promise<AxiosResponse<any>>{
    const axiosArgs = await EmailApiAxiosParamCreator().sendEmail(tenantName, name, email, phonePrefix, phone, options);
    
    return this.getAuthorizedAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }
}
