<template>
  <div class="modal-onboarding">
    <div
      class="modal-overlay"
      v-if="showModal"
      @click="showModal = false"
    ></div>
    <div class="modal z-10 absolute" v-if="showModal">
      <div class="modal-body h-full relative">
        <InputWhere
          :placeholder="$t('search_your_clubs')"
          :allowNearMe="false"
          :allowFavorites="false"
        />
        <div class="absolute w-full container-botton-buttons">
          <div
            class="search-btn flex justify-center items-center m-6 cursor-pointer"
            @click="showNextStep"
          >
            {{ $t("text_continue") }}
          </div>
          <div
            class="flex justify-center items-center cursor-pointer"
            @click="hideModal"
          >
            {{ $t("cancel") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputWhere from "@/components/form/inputWhereSearch.vue";

export default {
  components: {
    InputWhere,
  },
  emits: ["showNextStep"],
  data() {
    return {
      showModal: true,
    };
  },
  methods: {
    hideModal() {
      this.showModal = false;
    },
    showNextStep() {
      this.showModal = false;
      this.$emit("showNextStep", 1);
    },
  },
};
</script>

<style >
.modal-body .reference-seach {
  background: transparent;
  padding-left: 50px;
  height: 50px;
  border-radius: 24px;
}

.search-container .seach-icon {
  z-index: 1;
  top: 12px;
  left: 15px;
  opacity: 0.5;
}

.search-container .clear-icon {
  top: 8.3px;
  right: 10px;
  cursor: pointer;
  height: 27px;
}

.wrapper-suggestion {
  margin: 0 auto;
  border-top: 1px solid #ffffff59;
}
@media (min-width: 767px) {
  .wrapper-suggestion {
    width: 90%;
  }
}
.search-container input {
  outline: none;
}

.container-suggestion {
  text-align: start;
  margin: 0 auto;
  padding: 12px 0 12px 22px;
}

.container-suggestion:hover {
  background: linear-gradient(
    103.18deg,
    rgba(7, 106, 227, 0.42) 3.29%,
    rgba(6, 27, 46, 0) 97.77%
  );
}

.wrapper-list-select {
  position: absolute;
  z-index: 9;
  width: 100%;
  background: var(--background-color);
  margin: 0 auto;
  box-shadow: inset 0 0 12px hsla(0, 0%, 100%, 0.15);
  -webkit-backdrop-filter: blur(16px);
  backdrop-filter: blur(16px);
  border-radius: 24px;
  min-height: 200px;
}
.wrapper-list-select li {
  padding: 0 20px;
}
.wrapper-list-select h1 {
  padding: 8px 20px 0 20px;
}
.wrapper-list-select button {
  border-radius: 24px;
  padding: 4px 32px 4px 22px;
  background-color: #044a9d;
}

.close-list-element {
  right: 12px;
}

.container-botton-buttons {
  bottom: 12px;
}

.buttons {
  margin-bottom: 68px;
}
</style>