<template>
  <div v-if="isBooking">
    <div>
      <p class="custom-gradient mb-1 text-lg">{{ name }}</p>
    </div>
    <div>
      <p>{{ typeName +" | "+ subTypeName +" | "+sizeName }}</p>
    </div>
  </div>
  <div v-else>
    <p v-if="writeName">{{ name +" | "+ typeName +" | "+ subTypeName +" | "+sizeName }}</p>
    <p v-else>{{ typeName +" | "+ subTypeName +" | "+sizeName }}</p>
  </div>
</template>
<script lang="ts">
import { PadbolSize, PadbolSubType, PadbolType, PadelSize, PadelSubType, PadelType, PickleballSize, PickleballSubType, PickleballType, RacquetballSize, RacquetballSubType, RacquetballType, SoccerSize, SoccerSubtype, SoccerType, Sport, SquashSize, SquashSubType, SquashType, TenisSize, TenisSubType, TenisType } from '@/enum/constants';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: "resourceLabel",
  props: {
    sport: {
      type: Number,
      required: true
    },
    data: {
      type: Object as PropType<any>,
      required: true,
    },
    showName: {
      type: Boolean,
      required: false,
      default: false
    },
    isBooking: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data() {
    return {
      name: this.data.name
    };
  },
  computed: {
    writeName(): boolean {
      if(this.name && this.showName){
        return true
      }
    return false;
    },
    typeName(): string {
      const type = this.data.attributes.type;
      switch (this.sport) {
        case Sport.PADEL:
          switch (type) {
            case PadelType.OUTDOOR:
              return this.$t("outdoor");
            case PadelType.INDOOR:
              return this.$t("indoor");
            case PadelType.COVER:
              return this.$t("covered");
            }
        break;
        case Sport.TENIS:
          switch (type) {
              case TenisType.OUTDOOR:
                return this.$t("outdoor");
              case TenisType.INDOOR:
                return this.$t("indoor");
              case TenisType.COVER:
                return this.$t("covered");
              }
        break;
        case Sport.PICKLEBALL:
          switch (type) {
            case PickleballType.OUTDOOR:
              return this.$t("outdoor");
            case PickleballType.INDOOR:
              return this.$t("indoor");
            case PickleballType.COVER:
              return this.$t("covered");
            }
        break;
        case Sport.SOCCER:
          switch (type) {
            case SoccerType.OUTDOOR:
              return this.$t("outdoor");
            case SoccerType.INDOOR:
              return this.$t("indoor");
            }
        break;
        case Sport.PADBOL:
          switch (type) {
            case PadbolType.COVER:
              return this.$t("covered");
              case PadbolType.INDOOR:
              return this.$t("indoor");
              case PadbolType.OUTDOOR:
              return this.$t("outdoor");
            }
        break;
        case Sport.RACQUETBALL:
          switch (type) {
            case RacquetballType.COVER:
              return this.$t("covered");
              case RacquetballType.INDOOR:
              return this.$t("indoor");
            }
        break;
        case Sport.SQUASH:
          switch (type) {
            case SquashType.COVER:
              return this.$t("covered");
              case RacquetballType.INDOOR:
              return this.$t("indoor");
            }
        break;
      }
      return "";
    },
    subTypeName(): string {
      let subType = this.data.attributes.wall
      if(this.sport == Sport.SOCCER ){
        subType = this.data.attributes.surface
      }
      switch (this.sport) {
        case Sport.PADEL:
          switch (subType) {
          case PadelSubType.WALL:
            return this.$t("wall");
          case PadelSubType.CRISTAL:
            return this.$t("crystal");
          case PadelSubType.PANORAMIC:
            return this.$t("panoramic");
          }
        break;
        case Sport.TENIS:
          switch (subType) {
            case TenisSubType.CLAY:
              return this.$t("clay");
            case TenisSubType.QUICK:
              return this.$t("quick");
            case TenisSubType.CEMENT:
              return this.$t("cement");
            case TenisSubType.SYNTHETICGRASS:
              return this.$t("synthetic_grass");
            case TenisSubType.GRASS:
              return this.$t("grass");
          }
        break;
        case Sport.PICKLEBALL:
          switch (subType) {
            case PickleballSubType.ASPHALT:
              return this.$t("asphalt");
            case PickleballSubType.PLASTICPRESURE:
              return this.$t("plastic_pressure");
            case PickleballSubType.PARQUET:
              return this.$t("parquet");
          }
        break;
        case Sport.SOCCER:
          switch (subType) {
            case SoccerSubtype.ARTIFICIALTURF:
              return this.$t("artificial_turf");
            case SoccerSubtype.CONCRETE:
              return this.$t("concrete");
            case SoccerSubtype.DIRTFIELDS:
              return this.$t("dirt_fields");
            case SoccerSubtype.GRASS:
              return this.$t("grass")
            case SoccerSubtype.SYNTHETIC:
              return this.$t("synthetic")
            case SoccerSubtype.WOOD:
              return this.$t("wood")
          }
        break;
        case Sport.PADBOL:
          switch (subType) {
            case PadbolSubType.CRISTAL:
              return this.$t("crystal");
            case PadbolSubType.WALL:
              return this.$t("wall");
          }
        break;
        case Sport.RACQUETBALL:
          switch (subType) {
            case RacquetballSubType.WALL:
              return this.$t("wall");
          }
        break;
        case Sport.SQUASH:
          switch (subType) {
            case SquashSubType.WALL:
              return this.$t("wall");
          }
        break;
      }
      return "";
    },
    sizeName(): string {
      const size = this.data.attributes.size
      switch (this.sport) {
        case Sport.PADEL:
          switch (size) {
            case PadelSize.DOUBLE:
              return this.$t("common_double");
            case PadelSize.INDIVIDUAL:
              return this.$t("individual");
         }
        break;
        case Sport.TENIS:
          switch (size) {
              case TenisSize.DOUBLE:
                return this.$t("common_double");
              case TenisSize.INDIVIDUAL:
                return this.$t("individual");
              case TenisSize.MINI:
                return this.$t("mini");
            }
        break;
        case Sport.PICKLEBALL:
          switch (size) {
            case PickleballSize.DOUBLE:
              return this.$t("common_double");
            case PickleballSize.INDIVIDUAL:
              return this.$t("individual");
            case PickleballSize.MINI:
              return this.$t("mini");
            }
        break;
        case Sport.SOCCER:
          switch (size) {
            case SoccerSize.FUT11:
              return this.$t("fut11");
            case SoccerSize.FUT3:
              return this.$t("fut3");
            case SoccerSize.FUT5:
              return this.$t("fut5");
            case SoccerSize.FUT7:
              return this.$t("fut7")
            case SoccerSize.FUTSAL:
              return this.$t("futsal")
          }
        break;
        case Sport.PADBOL:
          switch (size) {
            case PadbolSize.DOUBLE:
              return this.$t("common_double");
            case PadbolSize.INDIVIDUAL:
              return this.$t("individual");
          }
        break;
        case Sport.RACQUETBALL:
          switch (size) {
            case RacquetballSize.DOUBLE:
              return this.$t("common_double");
            case RacquetballSize.INDIVIDUAL:
              return this.$t("individual");
          }
        break;
        case Sport.SQUASH:
          switch (size) {
            case SquashSize.DOUBLE:
              return this.$t("common_double");
            case RacquetballSize.INDIVIDUAL:
              return this.$t("individual");
          }
        break;
      }
      return "";
    },
  },
});
</script>
