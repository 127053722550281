<!-- MainView.vue -->
<template>
    <div>
      <div class="fixed top-16 left-[10%] z-[100]">
        <HistoryBack/>
      </div>
      <MyMatches/>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent } from 'vue';
  import MyMatches from "@/components/myProfile/myMatches.vue";
  import HistoryBack from "@/components/form/historyBack.vue";
  
  export default defineComponent({
    name: 'menuProfileMatches',
    components: {
      MyMatches,
      HistoryBack
    },
  });
  </script>

  