<template>
  <div class="flex flex-col" :class="{ 'activity': isDetail === false }">
    <div class="relative">
      <img
        :src="activity.image"
        :alt="activity.tenant!.name"
        class="h-64 object-cover"
      />
      <div class="absolute top-2 right-2 bg-black bg-opacity-50 text-white text-xs rounded p-2">
        {{ $t('enrollment_colon') + " " + formatDate(new Date(activity.registrationStartDate!), formatDateWithLocale(activity.placeAmount!.culture!)) }} - {{ formatDate(new Date(activity.registrationEndDate!), formatDateWithLocale(activity.placeAmount!.culture!)) }}
      </div>
      <div v-if="activity.inscribed" class="absolute bottom-2 left-2 bg-blue-600 text-white text-xs rounded p-2">
        {{ $t('already_enrolled') }}
      </div>
      <div class="absolute bottom-2 right-2 bg-black bg-opacity-50 text-white text-sm rounded p-2">
        {{ activity.placeAmount!.grossAmountStr }}
      </div>
    </div>
    <div class="p-4 flex-grow">
      <h2 class="text-white text-lg font-bold">
        {{ activity.name }}
      </h2>
      <p class="text-gray-300 text-sm">
        {{ $t('from') }} {{ beautifyDateWithOutTime(activity.startDate, true, false) }} {{ $t('to').toLowerCase() }} {{ beautifyDateWithOutTime(activity.endDate, true, false) }}
      </p>
      <p class="text-gray-400 text-xs mt-2 text-left">
        {{ displayDescription }}
      </p>
    </div>
    <div v-if="canRegister">
      <div class="flex w-full">
        <div class="relative w-full input-component sm:justify-center">
          <input
            type="button"
            @click="signIn(activity.id!, $event)"
            class="search-btn sm:box-border sm:w-full sm:flex sm:flex-row sm:justify-center sm:items-center sm:p-0 sm:h-12"
            :value="$t('i_want_to_enroll')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType  } from "vue";
import { formatDate, beautifyDateWithOutTime, formatDateWithLocale } from "@/helpers/dateHelper";
import { addThreeDotsTextWithLength, encodeString } from "@/helpers/dataHelper";
import { PreBooking, BookingType, ActivityParticipantResponse } from "models";
import { ACTIVITY_MAX_DESC, BookingType as BookingTypeEnum } from "@/enum/constants";

import store from '@/store';
import LocalStorage from "@/helpers/localStorage";
import { CheckoutPayment } from "@/custom-models/checkoutPayment";
import { ActivityHelper } from "@/helpers/activityHelper";
import { Activity } from "@/custom-models/activity";



export default defineComponent({
  name: "activityCard",
  emits: ["selfClose"],
  props: {
    activity: {
      type: Object as PropType<Activity>,
      required: true,
    },
    isDetail: {
      type: Boolean,
      required: true,
    }
  },
  computed: {
    canRegister(): boolean {
      return this.isDetail && this.activity.insertable! && !this.activity.inscribed!;
    },
    displayDescription(): string | null {
      if(this.activity.description){
        if (this.isDetail) {
          return this.activity.description;
        } else {
          return addThreeDotsTextWithLength(this.activity.description, ACTIVITY_MAX_DESC);
        }
      }
      return "";
    }
  },
  methods: {
    beautifyDateWithOutTime,
    formatDate,
    formatDateWithLocale,
    async signIn(id:string, e: MouseEvent) {
      e.preventDefault();
      e.stopPropagation();
      const user = localStorage.getItem("user");
      if (!user) {
        this.$emit("selfClose");
        store.commit("openLogin", { goToUrl: this.$route.fullPath });
        return;
      }
      store.commit("setLoading", true);
      const response: ActivityParticipantResponse = await ActivityHelper.addToActivity(id)
      store.commit("setLoading", false);
      if(response){
        this.goToPay(response)
      }
    },
    goToPay(response: ActivityParticipantResponse) {
      const paymentJson: CheckoutPayment = {
        saleId: response.saleId as string,
        saleType: BookingTypeEnum.ACTIVITY,
      }
      const activityBookingType: BookingType = {
        name: "ACTIVITY",
        code : BookingTypeEnum.ACTIVITY,
      };

      let customActivity: Activity = response.activity!
      customActivity = { ...response.activity, type: activityBookingType };
      LocalStorage.addWithTimout(response.saleId!, customActivity);
     
      if(response.free){
        return this.$router.push("/checkout-ok/" + response.saleId);
      }
      return this.$router.push({
        name: "checkoutPaymentViewCoded",
        params: { data: encodeString(JSON.stringify(paymentJson)) },
      });
    },
  },
});
</script>
<style scoped>
.activity{
  min-height: 480px;
  background: var(--background-color);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2), 0px 0px 8px -4px #3c7eeb;
  border-radius: 20px;
  cursor: pointer;
}
</style>
