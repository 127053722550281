<template>
  <div class="fixed top-16 left-[10%] z-[100]"><HistoryBack /></div>
  <div class="MyPrivacity p-8 text-sm">
    <div class="flex items-center justify-center">{{ $t("remove_account") }}</div>
    <div class="mt-12 br16 altBackground p-6">
      <div class="flex p-8 pl-4 pr-0 items-center justify-center flex-col">
        <div>
          {{ $t("we_are_going_to_miss_you") }}{{ $t("if_you_change_your_mind_do_not_hesitate_to_register_again") }}
        </div>
        <div
          @click="remove()"
          class="walletButton p-1 text-center cursor-pointer text-sm mt-4"
        >
        {{ $t("remove_account") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { CustomerApi } from "@/service/CustomerApi";
import CustomerHelper from "@/helpers/customerHelper";
import store from "../store";
import { defineComponent } from "vue";
import HistoryBack from "@/components/form/historyBack.vue";

export default defineComponent({
  name: "removeAccount",
  components: {
    HistoryBack
  },
  created() {
    const user = localStorage.getItem("user");
    if (user === null || user === "") {
      store.commit("openLogin", { goToUrl: this.$route.fullPath });
      return;
    }
  },
  methods: {
    async remove() {
      const user = localStorage.getItem("user");
      if (user === null) {
        return;
      }
      await CustomerApi.deleteCustomer();
      CustomerHelper.logout(store);
      this.$router.push("/");
    },
  },
});
</script>
<style scoped>
@media (min-width: 767px) {
  .profileContainer {
    max-width: 1000px;
    margin: 0 auto;
    position: relative;
  }
}

.profileContainer .backButton {
  top: 10px;
  left: 20px;
}

.profileContainer .editName {
  right: -20px;
}

.profileContainer .br100 {
  border-radius: 50%;
}

.profileContainer .topProfile .editImageProfile {
  bottom: 0;
  right: 0;
}

.profileContainer .topProfile {
  position: relative;
  top: 30px;
}

.profileContainer .topProfile .sportContainer {
  gap: 26px;
}

.profileContainer .secondBar .progressBar {
  border-bottom-right-radius: 16px;
  border-top-right-radius: 16px;
}

.profileContainer .gradiend {
  background: linear-gradient(
    103.18deg,
    var(--color1) 20.29%,
    var(--color2) 97.77%
  );
}

.profileContainer .countContainer {
  gap: 16px;
}

.altBackground {
  background: var(--background-color-box);
}
</style>
<style>
.myMatches .containerImageMatch {
  min-width: 44px;
  border-radius: 50%;
  overflow: hidden;
}

.myMatches .divisor {
  width: 3px;
}

.myMatches .end {
  justify-content: end;
}

.myMatches .usersScore {
  position: absolute;
  right: 10px;
  top: 5px;
}

.myMatches .scoreUsers {
  top: -5px;
  font-size: 10px;
  right: -10px;
  font-weight: bold;
  color: black;
}

.myMatches .numberScore {
  top: 3px;
  left: 4px;
}
</style>

<style>
.checkPrivacity .check {
  width: 10px;
  height: 7px;
  margin-top: 2px;
}

.checkPrivacity {
  border: 1px solid white;
  height: 12px;
  width: 12px;
  overflow: hidden;
  background: white;
  border-radius: 2px;
}

.checkPrivacity img {
  top: -4px;
  min-height: 18px;
  min-width: 18px;
  left: -4px;
}
</style>


<style>
.myPays .bt {
  border-top: 1px solid rgba(255, 255, 255, 0.212);
}

.myPays .bl {
  border-left: 1px solid rgba(255, 255, 255, 0.212);
}

.myPays .treeElements {
  width: 33%;
  padding: 8px 12px;
  min-height: 70px;
  text-align: center;
}
</style>
<style>
.MyClubs .ClubElements {
  border: 1px solid rgba(255, 255, 255, 0.089);
}

.MyClubs .opacityClubs {
  opacity: 0.7;
}
</style>
<style>
.myFav .containerFavs {
  width: 33%;
  min-height: 80px;
}

.myFav .containerFavs img {
  max-width: auto !important;
}

.myFav .containerFavs.bl {
  border-left: 1px solid rgba(255, 255, 255, 0.089);
}

.myFav .containerFavs.br {
  border-right: 1px solid rgba(255, 255, 255, 0.089);
}

.linearSpace {
  background: linear-gradient(
    270deg,
    #023b70 3.65%,
    #406297 19.27%,
    #4668a0 83.33%,
    #033768 100%
  );
  height: 1px;
}

.myFav .favElements {
  border: 1px solid rgba(255, 255, 255, 0.089);
}
</style>