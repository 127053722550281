<template>
  <div :class="cssClass">
    <div>{{ $t("gender") }}</div>
    <div class="flex flex-wrap justify-between mt-6 cursor-pointer">
      <div
        @click="click($event, gender.MALE)"
        class="flex flex-col items-center p-2 pl-4 pr-4 clueColor br60"
        :class="{ selected: modelValue === gender.MALE }"
      >
        {{ $t("male") }}
      </div>
      <div
        @click="click($event, gender.FEMALE)"
        class="flex flex-col items-center p-2 pl-4 pr-4 clueColor br60"
        :class="{ selected: modelValue === gender.FEMALE }"
      >
        {{ $t("female") }}
      </div>
      <div
        @click="click($event, gender.MIX)"
        class="flex flex-col items-center p-2 pl-4 pr-4 clueColor br60"
        :class="{ selected: modelValue === gender.MIX }"
      >
        {{ $t("mixed") }}
      </div>
      <div
        @click="click($event, gender.OPEN)"
        class="flex flex-col items-center p-2 pl-4 pr-4 clueColor br60"
        :class="{ selected: modelValue === gender.OPEN }"
      >
        {{ $t("open") }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Gender } from "@/enum/constants";
import { defineComponent } from "vue";

export default defineComponent({
  name: "GenderSelector",
  components: {},
  props: {
    modelValue: Number,
    cssClass: String,
  },

  data() {
    return {
      gender: Gender,
    };
  },
  methods: {
    click(e: any, gender: any) {
      this.$emit("update:modelValue", gender);
    },
  },
});
</script>

<style>
</style>
