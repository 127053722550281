<!-- Popup.vue -->
<template>
  <Transition>
    <PopUpLight v-if="isOpen" @closed="this.close()">
      <div class="mt-8">
        {{ errorMsg }}
      </div>
    </PopUpLight>
  </Transition>
</template>
  
<script>
import { useStore } from "vuex";
import { watch } from "vue";
import PopUpLight from "@/components/popup/popupLight.vue";
import { GlobalErrors, NetworkError } from "@/enum/constants";

export default {
  name: "globalError",

  components: {
    PopUpLight,
  },
  computed: {
    isOpen() {
      return this.store.state.globalErrorIsOpen;
    },
    errorData() {
      return this.store.state.globalErrorData;
    },
  },
  created() {
    this.store = useStore();
    watch(
      () => this.errorData,
      (newValue) => {
        if (this.store.state.globalErrorOnlyPopup == true) {
          this.store.commit("setGlobalErrorData", null);
          this.store.commit("setOnlyPopup", false);
          this.openLogin = false;
          this.store.commit("openLogin");
          return;
        }
        if (newValue !== null) {
          this.manageError(newValue);
        }
      }
    );
  },
  data() {
    return {
      errorMsg: "",
      openLogin: false,
      clearUserAndToken: false,
    };
  },
  methods: {
    close() {
      this.store.commit("setGlobalErrorData", null);
      this.store.commit("setGlobalErrorCode", null);
      this.store.commit("closeGlobalError");
      if (this.store.state.globalErrorRedirectUrl != null) {
        this.$router.push(this.store.state.globalErrorRedirectUrl);
        this.store.commit("setGlobalErrorRedirectUrl", null);
        return;
      }
      if (this.openLogin) {
        this.openLogin = false;
        this.store.commit("openLogin");
      }
    },
    manageError(error) {
      switch (error.response.status) {
        case -1:
          this.errorMsg = error.response.message;
          break;
        case GlobalErrors.ERROR_400:
          this.errorMsg = error.response.data.messages[0];
          break;
        case GlobalErrors.ERROR_500:
          if (
            error.response &&
            error.response.data &&
            error.response.data.messages &&
            error.response.data.messages[0]
          ) {
            this.errorMsg = error.response.data.messages[0];
          } else {
            if (error.code === NetworkError.ABORTED || error.message.includes(NetworkError.NET) || error.response.data.includes(NetworkError.PROXY)) {
              this.store.commit("setGlobalErrorRedirectUrl", "/DisconnectError");
              this.close();
            }
          }
          this.errorMsg = this.$t("sorry_we_are_having_problems_please_try_again_later");
          break;
        default:
          this.errorMsg = this.$t(
            "sorry_we_are_having_problems_please_try_again_later"
          );
      }
    },
  },
};
</script>
