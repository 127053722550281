import { vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", null, [
      _withDirectives(_createElementVNode("input", {
        type: "radio",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedOption) = $event)),
        value: "APPROVED"
      }, null, 512), [
        [_vModelRadio, _ctx.selectedOption]
      ]),
      _createTextVNode(" APPROVED ")
    ]),
    _createElementVNode("label", null, [
      _withDirectives(_createElementVNode("input", {
        type: "radio",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedOption) = $event)),
        value: "DECLINED"
      }, null, 512), [
        [_vModelRadio, _ctx.selectedOption]
      ]),
      _createTextVNode(" DECLINED ")
    ]),
    _createElementVNode("label", null, [
      _withDirectives(_createElementVNode("input", {
        type: "radio",
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedOption) = $event)),
        value: "CANCEL"
      }, null, 512), [
        [_vModelRadio, _ctx.selectedOption]
      ]),
      _createTextVNode(" CANCEL ")
    ]),
    _createElementVNode("button", {
      onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)))
    }, "Submit")
  ]))
}