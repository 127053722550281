import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { CardApiAxiosParamCreator } from '../../apis/card-api';
import { BaseApi } from './BaseApi';

export class CardApi extends BaseApi {

  /**
   * 
   * @summary Delete card by id
   * @param {string} id The card token
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  public static async deleteByToken(token: string, options?: AxiosRequestConfig): Promise<AxiosResponse<any>>{
    const axiosArgs = await CardApiAxiosParamCreator().deleteCard(token, options);
    
    return this.getAuthorizedAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }
}
