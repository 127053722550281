import { createStore } from 'vuex';
import { CustomerFavoritePlaces, CustomerPreferences } from "../../models";
import { Category, Sport } from "../enum/constants";

const loginIsOpen = false;
const loginRedirect = "";
const allowClosePopup = false;
const registerIsOpen = false;
const onboardingIsOpen = false;
const user = null;
const globalInfoIsOpen = false;
const globalErrorIsOpen = false;
const loading = false;
const globalErrorOnlyPopup = null;
const globalErrorData = null;
const globalErrorCode = null;
const globalErrorRedirectUrl = null;
const getInChatConversations = false;


const i18nLocale = '';


export default createStore({
  state: {
    loading,
    i18nLocale,
    loginIsOpen,
    allowClosePopup,
    loginRedirect,
    registerIsOpen,
    onboardingIsOpen,
    globalInfoIsOpen,
    globalErrorIsOpen,
    user,
    preferences: {
      sport: Sport.PADEL,
      action: Category.BOOKING,
      places: [] as CustomerFavoritePlaces[],
      tenants: [],
      days: []

    } as CustomerPreferences,
    usuallyPlaySelected: 0,
    category: Category,
    searchDateSelected: null,
    searchTimeSelected: null,
    fullDateTime: Date,
    searchIsFlexible: true,
    tenantInfo: {},
    globalErrorData: {},
    globalErrorRedirectUrl: null,
    globalErrorOnlyPopup: null,
    getInChatConversations: false,
  },
  mutations: {
    openLogin(state: any, payload: any) {
      state.loginIsOpen = true;
      if (payload !== undefined) {
        state.loginRedirect = payload.goToUrl;
        state.allowClosePopup = payload === undefined || payload.allowClose === undefined ? false : payload.allowClose;
      }
    },
    closeLogin(state: any) {
      state.loginIsOpen = false;
      state.user = JSON.parse(localStorage.getItem('user')!);
    },
    setUser(state, payload) {
      state.user = payload;
    },
    setPreferences(state, payload) {
      state.preferences = payload;
    },
    openRegister(state: any, payload: any) {
      state.registerIsOpen = true;
      state.allowClosePopup = payload === undefined || payload.allowClose === undefined ? false : payload.allowClose;
    },
    closeRegister(state: any) {
      state.registerIsOpen = false;
    },
    openOnboarding(state: any) {
      state.onboardingIsOpen = true;
    },
    closeOnboarding(state: any) {
      state.onboardingIsOpen = false;
    },
    openGlobalInfo(state: any) {
      state.globalInfoIsOpen = true;
    },
    closeGlobalInfo(state: any) {
      state.globalInfoIsOpen = false;
    },
    setUsuallyPlaySelected(state, payload) {
      state.usuallyPlaySelected = payload;
    },
    setPreferencesSport(state, payload) {
      state.preferences.sport = payload;
    },
    setPreferencesPlaces(state, payload) {
      state.preferences.places = payload;
    },
    setPlayDayTimes(state, payload) {
      state.preferences.days = payload;
    },
    setPreferencesAction(state, payload) {
      state.preferences.action = payload;
    },
    setPreferencesTenants(state, payload) {
      state.preferences.tenants = payload;
    },
    setI18nLocale(state, payload) {
      state.i18nLocale = payload;
    },
    setCategory(state, payload) {
      state.category = payload;
    },
    setSearchDateSelected(state, payload) {
      state.searchDateSelected = payload;
    },
    setSearchTimeSelected(state, payload) {
      state.searchTimeSelected = payload;
    },
    setfullDateTime(state, payload) {
      state.fullDateTime = payload;
    },
    setSearchIsFlexible(state, payload) {
      state.searchIsFlexible = payload;
    },
    setTenantInfo(state, payload) {
      state.tenantInfo = payload;
    },
    openGlobalError(state: any) {
      state.globalErrorIsOpen = true;
    },
    closeGlobalError(state: any) {
      state.globalErrorIsOpen = false;
    },
    setGlobalErrorData(state, data) {
      state.globalErrorData = data;
    },
    setGlobalErrorRedirectUrl(state, data) {
      state.globalErrorRedirectUrl = data;
    },
    setOnlyPopup(state: any, data) {
      state.globalErrorOnlyPopup = data;
    },
    getInChatConversations(state: any, data) {
      state.getInChatConversations = data;
    },
    setLoading(state: any, data) {
      state.loading = data;
    },
    setGlobalErrorCode(state, data) {
      state.globalErrorCode = data;
    }

  },
  actions: {
    openLogin: ({ commit }) => commit('openLogin'),
    closeLogin: ({ commit }) => commit('closeLogin'),
    openRegister: ({ commit }) => commit('openRegister'),
    closeRegister: ({ commit }) => commit('closeRegister'),
    openOnboarding: ({ commit }) => commit('openOnboarding'),
    closeOnboarding: ({ commit }) => commit('closeOnboarding'),
    openGlobalInfo: ({ commit }) => commit('openGlobalInfo'),
    closeGlobalInfo: ({ commit }) => commit('closeGlobalInfo'),
    openGlobalError: ({ commit }) => commit('openGlobalError'),
    closeGlobalError: ({ commit }) => commit('closeGlobalError'),
    setLoading: ({ commit }) => commit('setLoading'),
  },
  modules: {
  }
});
