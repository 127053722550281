<template>
  <div class="flex justify-center mt-6 w-full input-component relative">
    <SvgContainer name="inputPasswordIcon" />
    <input
      class="w-full input-rounded"
      :class="{ 'is-invalid': !isValid, '': true }"
      :readonly="readonly"
      :type="showPassword ? 'text' : 'password'"
      id="password"
      :placeholder="$t('password')"
      v-model="password"
      @input="validatePassword"
    />

    <span class="password-toggle" @click="togglePasswordVisibility">
      <img
        :src="showPassword ? eyeIcon : eyeSlashIcon"
        alt="Toggle Password Visibility"
      />
    </span>
  </div>
  <div class="flex justify-start">
    <p v-if="error" class="error-message max-w-xs whitespace-normal">{{ error }}</p>
  </div>
</template>

<script>
import SvgContainer from "@/views/SvgContainer.vue";
import eyeIcon from "@/assets/images/eye.svg";
import eyeSlashIcon from "@/assets/images/eye-slash.svg";

export default {
  name: "InputPassword",
  components: {
    SvgContainer,
  },
  emits: ["passwordValidated"],
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      password: "",
      showPassword: false,
      eyeIcon,
      eyeSlashIcon,
      error: "",
    };
  },
  computed: {
    isValid() {
      return this.error === "";
    },
  },
  methods: {
    validatePassword() {
      if (this.password === null || this.password === "") {
        this.error = this.$t("the_password_cannot_be_empty");
        this.$emit("passwordValidated", null);
      } else if (this.password.length < 8) {
        this.error = this.$t("the_password_must_have_at_least_8_characters");
        this.$emit("passwordValidated", null);
      } else {
        this.error = "";
        this.$emit("passwordValidated", this.password);
      }
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
  },
};
</script>

<style>
.password-toggle {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
</style>
