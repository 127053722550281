import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d3ddcdda"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-container" }
const _hoisted_2 = { class: "modal-body" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "modal-popup relative md:mt-16",
      style: _normalizeStyle(_ctx.backgroundImage)
    }, [
      (_ctx.$store.state.allowClosePopup || _ctx.showCancelButton)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: "close",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closed()))
          }, "×"))
        : _createCommentVNode("", true),
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.errorMsg != null && _ctx.errorMsg != '')
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "warning",
                innerHTML: _ctx.errorMsg
              }, null, 8, _hoisted_3))
            : _createCommentVNode("", true),
          _createElementVNode("main", null, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ])
        ])
      ]),
      _createElementVNode("div", {
        class: _normalizeClass([
          _ctx.$store.state.loginIsOpen
            ? 'login-modal-footer'
            : 'register-modal-footer'
        , "modal-footer text-center message"])
      }, [
        _createElementVNode("footer", null, [
          _renderSlot(_ctx.$slots, "footer", {}, () => [
            (_ctx.showOkButton)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  href: "#",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.ok())),
                  ref: "okBtn",
                  class: ""
                }, _toDisplayString(_ctx.defaultTextOkButton), 513))
              : _createCommentVNode("", true)
          ], true)
        ])
      ], 2)
    ], 4)
  ]))
}