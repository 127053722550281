<template>
  <div :class="cssClass">
    <div>{{ $t("level") }}</div>
    <div class="flex justify-between mt-6 cursor-pointer">
      <div
        class="flex flex-col items-center p-2 pl-4 pr-4 clueColor br60 br60"
        :class="{ selected: competitive }"
        @click="click($event, true)"
      >
        {{ $t("competitive") }}
      </div>
      <div
        class="flex flex-col items-center p-2 pl-4 pr-4 clueColor br60"
        :class="{ selected: !competitive }"
        @click="click($event, false)"
      >
        {{ $t("friendly") }}
      </div>
    </div>
    <div class="flex justify-center mt-6">
        <ProgressBar
          :firstValue="initLevel"
          :lastValue="endLevel"
          :value="endLevel"
          :showMidLevels="false"
        />
    </div>
  </div>
</template>

<script lang="ts">
import { ADD_LEVEL, Gender, LOW_LEVEL, MAX_LEVEL, MIN_LEVEL } from "@/enum/constants";
import { defineComponent } from "vue";
import ProgressBar from "@/components/form/progressBar.vue";

export default defineComponent({
  name: "InputText",
  components: {
    ProgressBar
  },
  props: {
    modelValue: Boolean,
    cssClass: String,
    level: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      gender: Gender,
      initLevel: 0 as number,
      endLevel: 0 as number,
      competitive: true,
    };
  },
  created() {
    if (this.modelValue !== undefined) {
      this.competitive = this.modelValue;
    }
  },
  mounted(){
    this.setLevelLimits();
  },
  watch: {
    level() {
      this.setLevelLimits();
    },
  },
  methods: {
    click(e: any, competitive: any) {
      this.competitive = competitive;
      this.$emit("update:modelValue", this.competitive);
    },
    setLevelLimits(){
      if (this.level !== undefined) {
      this.initLevel = Number((this.level - LOW_LEVEL).toFixed(2));
      this.endLevel = Number((this.level + ADD_LEVEL).toFixed(2));
      }
      if (this.initLevel < MIN_LEVEL) {
        this.initLevel = MIN_LEVEL;
      }
      if (this.endLevel < MIN_LEVEL) {
        this.endLevel = MIN_LEVEL;
      }
      if (this.endLevel > MAX_LEVEL) {
        this.endLevel = MAX_LEVEL;
      }
      this.$emit("sendLevels", this.initLevel, this.endLevel);
    }
  },
});
</script>
<style>
</style>
