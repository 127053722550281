<template>
    <div
        class="cancelWalletButton pl-6 pr-6 relative cursor-pointer"
        @click="confirmCancelBooking($event)"
      >
        {{ $t("cancel") }}
    </div>
    <Transition>
        <PopUpLight
        v-if="showConfirmCancel"
        :showOkButton="true"
        @closed="showConfirmCancel = false"
        >
        <p class="mt-8">{{ $t("are_u_sure_cancel_booking_question") }}</p>
        <div class="flex p-6 justify-around">
            <BorderButton
            @clicked="cancelBooking"
            :text="$t('confirm')"
            ></BorderButton>
            <BorderButton
            @clicked="closeConfirm"
            :text="$t('cancel')"
            ></BorderButton>
        </div>
        </PopUpLight>
    </Transition>

</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { Format } from "@/helpers/formatHelper";
import { BookingHelper } from "@/helpers/bookingHelper";
import BorderButton from "@/components/buttons/borderButton.vue";
import PopUpLight from "../popup/popupLight.vue";
import { customGlobalError } from "@/helpers/errorHelper";

export default defineComponent({
    name: "CancelBooking",
    emits: ["bookingCancelled"],
    props: {
        bookingId: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            showConfirmCancel: false,
        };
    },
    components: {
        BorderButton,
        PopUpLight
    },
    methods: {
        async cancelBooking(e:MouseEvent) {
            e.preventDefault();
            e.stopPropagation();
            this.showConfirmCancel = false;
            if (!Format.IsNull(this.bookingId)) {
                await BookingHelper.cancelBooking(this.bookingId);
                this.showConfirmCancel = false;
                this.$emit("bookingCancelled");
                return;
            }
            customGlobalError(this.$t("sorry_we_are_having_problems_please_try_again_later"));
        },
        confirmCancelBooking(e: MouseEvent) {
            e.preventDefault();
            e.stopPropagation();
            this.showConfirmCancel = true;
        },
        closeConfirm(e: MouseEvent) {
            e.preventDefault();
            e.stopPropagation();
            this.showConfirmCancel = false;
        },
    },
});
</script>
<style scoped>
.cancelWalletButton {
    align-content: center;
    background: linear-gradient(var(--background-color-button),
      var(--background-color-button)) padding-box,
    linear-gradient(60deg,#f472b6, #f472b6) border-box;
    border: 2px solid transparent;
    border-radius: 36px;
}
</style> 