import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cf186744"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "text mt p-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ActivityBar = _resolveComponent("ActivityBar")!
  const _component_PopUpLight = _resolveComponent("PopUpLight")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: "flex button items-center cursor-pointer",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleActivity && _ctx.toggleActivity(...args)))
    }, [
      _createElementVNode("img", {
        src: _ctx.items[_ctx.optionSelected].icon,
        class: "icon ml-3"
      }, null, 8, _hoisted_1),
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.items[_ctx.optionSelected].text), 1)
    ]),
    _createVNode(_Transition, null, {
      default: _withCtx(() => [
        (_ctx.showActivities)
          ? (_openBlock(), _createBlock(_component_PopUpLight, {
              key: 0,
              onClosed: _ctx.closeActivities
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ActivityBar, {
                  optionDefault: _ctx.optionSelected,
                  onOptionSelected: _ctx.handleActivitySelected
                }, null, 8, ["optionDefault", "onOptionSelected"])
              ]),
              _: 1
            }, 8, ["onClosed"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}