import store from "../store";

export function serviceGlobalError(error: any, redirectUrl: any = null) {
  store.commit("setGlobalErrorData", error);

  if (error.response.status === 409) { //if is 409(Conflict) do nothing, component show message
    return;
  }
  if (error.response.status === 403 || error.response.status === 401) { //if is 403(Forbidden) or Unauthorized(401) global error don't show error popup, only open login
    if (error.response.status === 403) {
      clearUserAndToken();
    }
    store.commit("setOnlyPopup", true);
    return;
  }


  store.commit("setGlobalErrorRedirectUrl", redirectUrl);
  store.commit("openGlobalError");
}

export function customGlobalError(errorStr: string, redirectUrl: any = null, status: any = -1,) {
  const errorData = {
    response: {
      message: errorStr,
      status: status,
    }

  };
  serviceGlobalError(errorData, redirectUrl);
}

function clearUserAndToken() {
  localStorage.removeItem("user");
  localStorage.removeItem("t");
}
