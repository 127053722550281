<template>
  <GoogleLogin :callback="callback" popup-type="TOKEN">
    <a href="#">
      <img class="ml-3.5" src="@/assets/images/google-login.png" alt="Google login"/>
    </a>
  </GoogleLogin>

<!--  <GoogleLogin :callback="callback" />-->

</template>

<script setup lang="ts">
import { AuthorizationApi } from "@/service/AuthorizationApi";
import type { GoogleLogin } from "vue3-google-login";
import { getCurrentInstance } from 'vue';

const { emit } = getCurrentInstance() || {};

const callback = async (data: { credential: any; access_token: any }) => {
  const response = await AuthorizationApi.authenticateWithGoogleAccessToken(
    data.access_token
  );
  const isNew = response.data.new;

  emit && emit('success', isNew);
};
</script>

<script lang="ts">
export default {
  name: "CustomGoogleLogin",
  mounted() {
    // Cargar el script del cliente de autenticación de Google
    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  },
};
</script>
<style scoped>
.g-btn-wrapper{
  display: inherit !important;
  float: right;
}
</style>
