import { AxiosRequestConfig } from 'axios';
import { CustomerApiAxiosParamCreator } from '../../apis/customer-api';
import { BookingApiAxiosParamCreator, CustomerPreferences, Privacy, Token } from '../..';
import store from '@/store';
import { BaseApi } from './BaseApi';
import { AxiosResponse } from "axios/index";

export class CustomerApi extends BaseApi {

  /**
   * 
   * @summary Get the customer data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  public static async get() {
    const axiosArgs = await CustomerApiAxiosParamCreator().getCustomer();

    const response = this.getAuthorizedAxios().request<Token>({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
    const customer = (await response).data;

    localStorage.setItem('user', JSON.stringify(customer));
    return customer;
  }
  /**
 * 
 * @summary Get the customer preferences
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
 */
  public static async getPreferences(options?: AxiosRequestConfig) {

    const axiosArgs = await CustomerApiAxiosParamCreator().getPreferences(options);
    if (store.state.preferences != null) {
      return store.state.preferences;
    }

    const response = this.getAuthorizedAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });

    const preferences = (await response).data;
    store.commit('setPreferences', preferences);


    return preferences;
  }
  /**
   * 
   * @summary Update the customer preferences
   * @param {CustomerPreferences} body 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  public static async updatePreferences(body: CustomerPreferences, options?: AxiosRequestConfig) {

    const axiosArgs = await CustomerApiAxiosParamCreator().updatePreferences(body, options);
    return this.getAuthorizedAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }

  /**
   * 
   * @summary Start recover password process
   * @param {string} email Address where the restore password email will be sent
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  public static async initRestorePassword(email: string, options?: AxiosRequestConfig) {
    const axiosArgs = await CustomerApiAxiosParamCreator().initRestorePassword(email, options);
    return this.getAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }

  /**
  * 
  * @summary Let customers to recover password
  * @param {string} token token send by mail
  * @param {string} newPassword The new password chose by customer
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
  public static async restorePassword(token: string, newPassword: string, options?: AxiosRequestConfig) {
    const axiosArgs = await CustomerApiAxiosParamCreator().restorePassword(token, newPassword, options);
    return this.getAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }

  /**
  * 
  * @summary Search customers filtering by full name
  * @param {string} name The customer full name
  * @param {number} start Zero-based page index
  * @param {number} size The page size
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
  public static async findByNameContaining(name: string, start: number, size: number, options?: AxiosRequestConfig) {
    const axiosArgs = await CustomerApiAxiosParamCreator().findByNameContaining(name, start, size, options);
    return this.getAuthorizedAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }
  /**
  * 
  * @summary Search customers likes from a customer
  * @param {number} start Zero-based page index
  * @param {number} size The page size
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
  public static async findCustomerLike(start: number, size: number, options?: AxiosRequestConfig) {
    const axiosArgs = await CustomerApiAxiosParamCreator().findCustomerLikeById(start, size, options);
    return this.getAuthorizedAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }

  /**
  * 
  * @summary Create a customer like
  * @param {string} id Target id
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
  public static async createCustomerLike(id: string, options?: AxiosRequestConfig) {
    const axiosArgs = await CustomerApiAxiosParamCreator().addCustomerLike(id, options);
    return this.getAuthorizedAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }

  /**
  * 
  * @summary Delete like by id
  * @param {string} id Target id
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
  public static async deleteCustomerLikeByTargetHash(id: string, options?: AxiosRequestConfig) {
    const axiosArgs = await CustomerApiAxiosParamCreator().deleteCustomerLike(id, options);
    return this.getAuthorizedAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }

  /**
   * 
   * @summary Delete customer
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  public static async deleteCustomer(options?: AxiosRequestConfig) {
    const axiosArgs = await CustomerApiAxiosParamCreator().deleteCustomer(options);
    return this.getAuthorizedAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }

  /**
  * 
  * @summary Get the customer results
  * @param {number} sport The sport of the level
  * @param {number} level The value of the level
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
  public static async results(sport: number, level: number, options?: AxiosRequestConfig) {
    const axiosArgs = await CustomerApiAxiosParamCreator().updateCustomerLevel(sport, level, options);
    return this.getAuthorizedAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }

  /**
  * 
  * @summary Get the customer results
  * @param {number} gender The gender of the customer
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
  public static async updateGender(gender: number, options?: AxiosRequestConfig) {
    const axiosArgs = await CustomerApiAxiosParamCreator().updateGender(gender, options);
    return this.getAuthorizedAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }

  /**
  * 
  * @summary Add tenant to customer preferences
  * @param {string} id The tenant id
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
  public static async addTenantToCustomerFavorites(id: string, options?: AxiosRequestConfig) {
    const axiosArgs = await CustomerApiAxiosParamCreator().addTenantToCustomerFavorites(id, options);
    return this.getAuthorizedAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }

  /**
  * 
  * @summary Remove tenant from customer preferences
  * @param {string} id The tenant id
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
  public static async deleteTenantFromCustomerFavorites(id: string, options?: AxiosRequestConfig) {
    const axiosArgs = await CustomerApiAxiosParamCreator().deleteTenantFromCustomerFavorites(id, options);
    return this.getAuthorizedAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }

  /**
  * 
  * @summary Let customers to change their image
  * @param {Blob} image 
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
  public static async uploadImageForm(image: Blob, options?: AxiosRequestConfig) {
    const axiosArgs = await CustomerApiAxiosParamCreator().uploadImageForm(image, options);
    return this.getAuthorizedAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }

  /**
  * 
  * @summary Let customers to change the full name
  * @param {string} fullname The current customer password
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
  public static async updateFullName(fullname: string, options?: AxiosRequestConfig) {
    const axiosArgs = await CustomerApiAxiosParamCreator().updateFullName(fullname, options);
    return this.getAuthorizedAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }

  /**
  * 
  * @summary Let customers to change the nickname
  * @param {string} nickname The current customer password
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
  public static async updateNickName(nickname: string, options?: AxiosRequestConfig) {
    const axiosArgs = await CustomerApiAxiosParamCreator().updateNickName(nickname, options);
    return this.getAuthorizedAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }

  /**
  * 
  * @summary Get the customer results
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
  public static async getCustomerResults(options?: AxiosRequestConfig) {
    const axiosArgs = await CustomerApiAxiosParamCreator().getCustomerResults(options);
    return this.getAuthorizedAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }

  /**
  * 
  * @summary Get customer privacy
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
  public static async getPrivacy(options?: AxiosRequestConfig) {
    const axiosArgs = await CustomerApiAxiosParamCreator().getPrivacy(options);
    return this.getAuthorizedAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }

  /**
  * 
  * @summary Update customer privacy
  * @param {Privacy} body 
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
  public static async updatePrivacy(body: Privacy, options?: AxiosRequestConfig) {
    const axiosArgs = await CustomerApiAxiosParamCreator().updatePrivacy(body);
    return this.getAuthorizedAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }

  /**
  * 
  * @summary Update the sport level of a customer
  * @param {number} sport The sport of the level
  * @param {number} level The value of the level
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
  public static async updateCustomerLevel(sport: number, level: number, options?: AxiosRequestConfig) {
    const axiosArgs = await CustomerApiAxiosParamCreator().updateCustomerLevel(sport, level);
    return this.getAuthorizedAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }

  /**
  * 
  * @summary Service to verify phone process
  * @param {string} token The token received to verify phone
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
  public static async verifyPhone(token: string, options?: AxiosRequestConfig) {
    const axiosArgs = await CustomerApiAxiosParamCreator().verifyPhone(token);
    return this.getAuthorizedAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }

  /**
  *
  * @summary Service create a request to link a tenant to a customer
  * @param {string} id The tenant id
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
  public static async createLinkedTenant(id: string, options?: AxiosRequestConfig) {
    const axiosArgs = await CustomerApiAxiosParamCreator().createLinkedTenant(id);
    return this.getAuthorizedAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }

  /**
  *
  * @summary Get the tenants linked to a customer
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
  public static async getCustomerTenants(options?: AxiosRequestConfig) {
    const axiosArgs = await CustomerApiAxiosParamCreator().findLinkedTenantByCustomer();
    return this.getAuthorizedAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }

  /**
  *
  * @summary Delete a linked tenant from a customer
  * @param {string} id The tenant id
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
  public static async deleteLinkedTenant(id: string, options?: AxiosRequestConfig) {
    const axiosArgs = await CustomerApiAxiosParamCreator().deleteLinkedTenant(id);
    return this.getAuthorizedAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }

  /**
  * 
  * @summary Find if tenant is linked to user
  * @param {string} id The tenantId
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
  public static async customerIsLinked(id: string, options?: AxiosRequestConfig) {
    const axiosArgs = await CustomerApiAxiosParamCreator().customerIsLinked(id, options);
    return this.getAuthorizedAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }

}
