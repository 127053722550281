
<template>
  <div
    :class="css"
    class="walletButton p-4 text-center cursor-pointer text-sm"
    @click="click($event)"
  >
    {{ this.text }}
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "borderButton",
  components: {},
  emits: ["clicked"],
  props: ["text", "css"],
  data() {
    return {};
  },
  methods: {
    click(e: any) {
      this.$emit("clicked", e);
    },
  },
});
</script>
<style scoped>
.buttonBorder {
  border-radius: 32px;
  padding: 14px 0;
  background-image: linear-gradient(to right, var(--color1), var(--color2));
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 767px) {
  .buttonBorder {
    border-radius: 32px;
    width: 327px;
    padding: 14px 0;
    background-image: linear-gradient(to right, var(--color1), var(--color2));
    margin-left: auto;
    margin-right: auto;
  }
}
</style>

