<template>
  <div class="mt-8">
    <div class="flex items-center justify-center">
      <p>{{ $t("my_linked_clubs") }}</p>
    </div>
    <div class="flex items-center justify-center">
      <div class="relative w-60 input-component mt-20">
        <InputWhere
          :placeholder="$t('search_a_club_to_link')"
          :addLocations="false"
          :onlyOneSelect="true"
          :allowNearMe="false"
          :allowFavorites="false"
          :searchTenantEnabled="true"
          @sendTenantToBooking="tenantSelected"
        />
      </div>
    </div>
    <div
      v-if="customerTenants.length > 0"
      class="mt-12 br16 altBackground p-4 overflow-y-auto fix-height"
    >
      <ul>
        <li v-for="tenant in customerTenants" :key="tenant.id">
          <div class="component justify-between">
            <div class="component-info flex justify-center items-center">
              <h3>{{ capitalizeFirstLetter(tenant.name!) }}</h3>
            </div>
          </div>
          <div class="linearSpace"></div>
        </li>
      </ul>
    </div>
    <div
      v-else
      class="flex items-center justify-center mt-12 br16 altBackground p-4"
    >
      <p>{{ $t("dont_have_any_club_linked_yet") }}</p>
    </div>

    <Transition>
      <PopUpLight
        class="popupIframe"
        v-if="showPopLinkAccount"
        @closed="showPopLinkAccount = false"
      >
        <div class="mt-8">
          <iframe class="iframe" title="link account" frameborder="0" :src="urlLink"></iframe>
        </div>
      </PopUpLight>
    </Transition>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { beautifyDate } from "@/helpers/dateHelper";
import { Format } from "@/helpers/formatHelper";
import { CustomerFavoriteTenant, Tenant } from "models";
import InputWhere from "@/components/form/inputWhereSearch.vue";
import store from "@/store";
import { CustomerApi } from "@/service/CustomerApi";
import PopUpLight from "../popup/popupLight.vue";
import { capitalizeFirstLetter } from "@/helpers/dateHelper";

export default defineComponent({
  name: "MyClubs",
  data() {
    return {
      showPopLinkAccount: false,
      start: 0,
      size: 50,
      customerTenants: [] as CustomerFavoriteTenant[],
      urlLink: "",
    };
  },
  props: ["customerId"],
  components: {
    InputWhere,
    PopUpLight,
  },
  async mounted() {
    this.loadData();
    window.addEventListener("message", this.message, false);
  },
  methods: {
    capitalizeFirstLetter,
    async tenantSelected(tenant: Tenant) {
      if (tenant === undefined) {
        return;
      }
      if (tenant.id == undefined) {
        return;
      }

      const linkedData = await CustomerApi.createLinkedTenant(tenant.id);
      this.urlLink = linkedData.data.value;
      this.showPopLinkAccount = true;
    },
    async loadData() {
      store.commit("setLoading", true);
      const response: any = await CustomerApi.getCustomerTenants(
        this.customerId
      );
      store.commit("setLoading", false);
      this.customerTenants = response.data;
    },
    getBeautifyDate(date: any) {
      return beautifyDate(date, true, false);
    },
    getFormatedCurrency(
      grossAmount: number,
      currencyCode: string,
      locale: string
    ) {
      return Format.formatCurrency(grossAmount, currencyCode, locale);
    },
    async message(e: any) {
      if (e.data === true) {
        this.showPopLinkAccount = false;
        this.loadData();
        return;
      }
    },
  },
});
</script>

<style scoped>
.fix-height {
  max-height: calc(100vh - 150px);
}
.linearSpace {
  background: linear-gradient(
    270deg,
    #023b70 3.65%,
    #406297 19.27%,
    #4668a0 83.33%,
    #033768 100%
  );
  height: 1px;
}
.component {
  display: flex;
  margin-bottom: 10px;
  margin-top: 10px;
}
.component-info {
  width: 33%;
  min-height: 80px;
}

.iframe {
  width: 100%; /* Set the width */
  height: 600px; /* Set the height */
  overflow: hidden; /* Hide the scrollbars */
  border-radius: 20px;
}

.iframe::-webkit-scrollbar {
  display: none; /* Hide the scrollbar in WebKit browsers */
}
</style>

