<template>
  <div class="mt-8"></div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { AppsHelper } from "@/helpers/appsHelper";

export default defineComponent({
  name: "linkAccountResponse",
  data() {
    return {};
  },
  components: {},
   async mounted() {
    if (AppsHelper.sendToIOS(window, { "accountLinked": true })) {
      return;
    }
    window.parent.postMessage(true, process.env.VUE_APP_URL);
  },
  methods: {},
});
</script>

