<template>
  <div class="hidden">
    <form id="acsUrlForm" :action="acsUrl" method="POST">
      <input class="hidden" :id="acsParamName" :name="acsParamName" v-model="acsParamValue" />
    </form> 
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { decodeString } from "@/helpers/dataHelper";
import { Format } from "@/helpers/formatHelper";

export default defineComponent({
  name: "PaymentBridge",
  data() {
    return {
      payload:"" as string,
      acsUrl:"" as string,
      acsParamName:"" as string,
      acsParamValue:"" as string
    };
  },
  components: {},
  created() {
    const decodedPayload = decodeString(this.$route.params.payload! as string);
    const jsonPayload = JSON.parse(decodedPayload);
    if(!Format.IsNull(jsonPayload)){
      this.acsUrl = jsonPayload.acsUrl;
      this.acsParamName = jsonPayload.acsParamName;
      this.acsParamValue = jsonPayload.acsParamValue;
    }
  },
  mounted(){
    const acsUrlForm: any = document.getElementById("acsUrlForm");
    acsUrlForm.submit();
  }
});
</script>