/* tslint:disable */
/* eslint-disable */
/**
 * Marketplace Api
 * Marketplace Api description
 *
 * OpenAPI spec version: 1.0
 * Contact: develop@taykus.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { BooleanValueDTO } from '../models';
import { Customer } from '../models';
import { CustomerPreferences } from '../models';
import { CustomerResult } from '../models';
import { Email } from '../models';
import { Phone } from '../models';
import { Privacy } from '../models';
import { SimpleCustomer } from '../models';
import { Tenant } from '../models';
import { Url } from '../models';
/**
 * CustomerApi - axios parameter creator
 * @export
 */
export const CustomerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a customer like
         * @param {string} id Target id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCustomerLike: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling addCustomerLike.');
            }
            const localVarPath = `/api/customer/like/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add a new email
         * @param {string} value The email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEmail: async (value: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'value' is not null or undefined
            if (value === null || value === undefined) {
                throw new RequiredError('value','Required parameter value was null or undefined when calling addEmail.');
            }
            const localVarPath = `/api/customer/email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (value !== undefined) {
                localVarQueryParameter['value'] = value;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add a new phone
         * @param {string} prefix The area code
         * @param {string} value The phone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPhone: async (prefix: string, value: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'prefix' is not null or undefined
            if (prefix === null || prefix === undefined) {
                throw new RequiredError('prefix','Required parameter prefix was null or undefined when calling addPhone.');
            }
            // verify required parameter 'value' is not null or undefined
            if (value === null || value === undefined) {
                throw new RequiredError('value','Required parameter value was null or undefined when calling addPhone.');
            }
            const localVarPath = `/api/customer/phone`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (prefix !== undefined) {
                localVarQueryParameter['prefix'] = prefix;
            }

            if (value !== undefined) {
                localVarQueryParameter['value'] = value;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add tenant to customer preferences
         * @param {string} id The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTenantToCustomerFavorites: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling addTenantToCustomerFavorites.');
            }
            const localVarPath = `/api/customer/preferences/tenant/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Let customers to change password
         * @param {string} currentPassword The current customer password
         * @param {string} newPassword The new password chose by customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword: async (currentPassword: string, newPassword: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'currentPassword' is not null or undefined
            if (currentPassword === null || currentPassword === undefined) {
                throw new RequiredError('currentPassword','Required parameter currentPassword was null or undefined when calling changePassword.');
            }
            // verify required parameter 'newPassword' is not null or undefined
            if (newPassword === null || newPassword === undefined) {
                throw new RequiredError('newPassword','Required parameter newPassword was null or undefined when calling changePassword.');
            }
            const localVarPath = `/api/customer/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (currentPassword !== undefined) {
                localVarQueryParameter['currentPassword'] = currentPassword;
            }

            if (newPassword !== undefined) {
                localVarQueryParameter['newPassword'] = newPassword;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a request link customer to a tenant
         * @param {string} id The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLinkedTenant: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling createLinkedTenant.');
            }
            const localVarPath = `/api/customer/tenant/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find if tenant is linked to user
         * @param {string} id The tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerIsLinked: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling customerIsLinked.');
            }
            const localVarPath = `/api/customer/tenant/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomer: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/customer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete like by id
         * @param {string} id Target id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomerLike: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteCustomerLike.');
            }
            const localVarPath = `/api/customer/like/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an email by id
         * @param {number} id The email id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEmail: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteEmail.');
            }
            const localVarPath = `/api/customer/email/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete tenant customer relation
         * @param {string} id tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLinkedTenant: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteLinkedTenant.');
            }
            const localVarPath = `/api/customer/tenant`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a phone by id
         * @param {number} id The phone id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePhone: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deletePhone.');
            }
            const localVarPath = `/api/customer/phone/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove tenant from customer preferences
         * @param {string} id The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTenantFromCustomerFavorites: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteTenantFromCustomerFavorites.');
            }
            const localVarPath = `/api/customer/preferences/tenant/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark a email as customer default email
         * @param {number} id The email id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doDefaultEmail: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling doDefaultEmail.');
            }
            const localVarPath = `/api/customer/email/{id}/default`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark a phone as customer default phone
         * @param {number} id The phone id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doDefaultPhone: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling doDefaultPhone.');
            }
            const localVarPath = `/api/customer/phone/{id}/default`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search customers filtering by full name
         * @param {string} name The customer full name
         * @param {number} start Zero-based page index
         * @param {number} size The page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByNameContaining: async (name: string, start: number, size: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling findByNameContaining.');
            }
            // verify required parameter 'start' is not null or undefined
            if (start === null || start === undefined) {
                throw new RequiredError('start','Required parameter start was null or undefined when calling findByNameContaining.');
            }
            // verify required parameter 'size' is not null or undefined
            if (size === null || size === undefined) {
                throw new RequiredError('size','Required parameter size was null or undefined when calling findByNameContaining.');
            }
            const localVarPath = `/api/customer/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search customers likes from a customer
         * @param {number} start Zero-based page index
         * @param {number} size The page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCustomerLikeById: async (start: number, size: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'start' is not null or undefined
            if (start === null || start === undefined) {
                throw new RequiredError('start','Required parameter start was null or undefined when calling findCustomerLikeById.');
            }
            // verify required parameter 'size' is not null or undefined
            if (size === null || size === undefined) {
                throw new RequiredError('size','Required parameter size was null or undefined when calling findCustomerLikeById.');
            }
            const localVarPath = `/api/customer/like`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all the customer emails
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findEmailByCustomer: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/customer/email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all the customer tenants
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findLinkedTenantByCustomer: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/customer/tenant`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all the customer phones
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPhoneByCustomer: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/customer/phone`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the customer data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomer: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/customer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the customer results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerResults: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/customer/result`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the customer preferences
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreferences: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/customer/preferences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get customer privacy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrivacy: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/customer/privacy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Start recover password process
         * @param {string} email Address where the restore password email will be sent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initRestorePassword: async (email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            if (email === null || email === undefined) {
                throw new RequiredError('email','Required parameter email was null or undefined when calling initRestorePassword.');
            }
            const localVarPath = `/api/customer/restore/password/email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Register a customer device
         * @param {string} token The FCM token of the device device
         * @param {string} [os] The Operative System of the device
         * @param {string} [version] The Operative System version
         * @param {string} [description] A short description
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerDevice: async (token: string, os?: string, version?: string, description?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling registerDevice.');
            }
            const localVarPath = `/api/customer/device`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (os !== undefined) {
                localVarQueryParameter['os'] = os;
            }

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Let customers to recover password
         * @param {string} token token send by mail
         * @param {string} newPassword The new password chose by customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restorePassword: async (token: string, newPassword: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling restorePassword.');
            }
            // verify required parameter 'newPassword' is not null or undefined
            if (newPassword === null || newPassword === undefined) {
                throw new RequiredError('newPassword','Required parameter newPassword was null or undefined when calling restorePassword.');
            }
            const localVarPath = `/api/customer/restore/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (newPassword !== undefined) {
                localVarQueryParameter['newPassword'] = newPassword;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a notification
         * @param {string} title The title
         * @param {string} body The body
         * @param {Array<string>} tokens The target tokens
         * @param {string} bookingId The booking id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNotification: async (title: string, body: string, tokens: Array<string>, bookingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'title' is not null or undefined
            if (title === null || title === undefined) {
                throw new RequiredError('title','Required parameter title was null or undefined when calling sendNotification.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling sendNotification.');
            }
            // verify required parameter 'tokens' is not null or undefined
            if (tokens === null || tokens === undefined) {
                throw new RequiredError('tokens','Required parameter tokens was null or undefined when calling sendNotification.');
            }
            // verify required parameter 'bookingId' is not null or undefined
            if (bookingId === null || bookingId === undefined) {
                throw new RequiredError('bookingId','Required parameter bookingId was null or undefined when calling sendNotification.');
            }
            const localVarPath = `/api/customer/device/simple`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }

            if (body !== undefined) {
                localVarQueryParameter['body'] = body;
            }

            if (tokens) {
                localVarQueryParameter['tokens'] = tokens;
            }

            if (bookingId !== undefined) {
                localVarQueryParameter['bookingId'] = bookingId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a notification to all customers
         * @param {string} title The title
         * @param {string} body The body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNotificationToAllCustomers: async (title: string, body: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'title' is not null or undefined
            if (title === null || title === undefined) {
                throw new RequiredError('title','Required parameter title was null or undefined when calling sendNotificationToAllCustomers.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling sendNotificationToAllCustomers.');
            }
            const localVarPath = `/api/customer/device/topic`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }

            if (body !== undefined) {
                localVarQueryParameter['body'] = body;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the sport level of a customer
         * @param {number} sport The sport of the level
         * @param {number} level The value of the level
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerLevel: async (sport: number, level: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sport' is not null or undefined
            if (sport === null || sport === undefined) {
                throw new RequiredError('sport','Required parameter sport was null or undefined when calling updateCustomerLevel.');
            }
            // verify required parameter 'level' is not null or undefined
            if (level === null || level === undefined) {
                throw new RequiredError('level','Required parameter level was null or undefined when calling updateCustomerLevel.');
            }
            const localVarPath = `/api/customer/level`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (sport !== undefined) {
                localVarQueryParameter['sport'] = sport;
            }

            if (level !== undefined) {
                localVarQueryParameter['level'] = level;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Let customers to change the full name
         * @param {string} fullname The current customer password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFullName: async (fullname: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fullname' is not null or undefined
            if (fullname === null || fullname === undefined) {
                throw new RequiredError('fullname','Required parameter fullname was null or undefined when calling updateFullName.');
            }
            const localVarPath = `/api/customer/fullname`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (fullname !== undefined) {
                localVarQueryParameter['fullname'] = fullname;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the customer gender
         * @param {number} gender The gender of the customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGender: async (gender: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gender' is not null or undefined
            if (gender === null || gender === undefined) {
                throw new RequiredError('gender','Required parameter gender was null or undefined when calling updateGender.');
            }
            const localVarPath = `/api/customer/gender`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (gender !== undefined) {
                localVarQueryParameter['gender'] = gender;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Let customers to change the nickname
         * @param {string} nickname The current customer password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNickName: async (nickname: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nickname' is not null or undefined
            if (nickname === null || nickname === undefined) {
                throw new RequiredError('nickname','Required parameter nickname was null or undefined when calling updateNickName.');
            }
            const localVarPath = `/api/customer/nickname`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (nickname !== undefined) {
                localVarQueryParameter['nickname'] = nickname;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the customer preferences
         * @param {CustomerPreferences} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePreferences: async (body: CustomerPreferences, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updatePreferences.');
            }
            const localVarPath = `/api/customer/preferences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers!['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update customer privacy
         * @param {Privacy} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePrivacy: async (body: Privacy, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updatePrivacy.');
            }
            const localVarPath = `/api/customer/privacy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers!['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Let customers to change their image
         * @param {Blob} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImageForm: async (image: Blob, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'image' is not null or undefined
            if (image === null || image === undefined) {
                throw new RequiredError('image','Required parameter image was null or undefined when calling uploadImageForm.');
            }
            const localVarPath = `/api/customer/image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


            if (image !== undefined) { 
                localVarFormParams.append('image', image as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Service to verify email process
         * @param {string} token The token received to verify email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyEmail: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling verifyEmail.');
            }
            const localVarPath = `/api/customer/email/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Service to verify phone process
         * @param {string} token The token received to verify phone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyPhone: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling verifyPhone.');
            }
            const localVarPath = `/api/customer/phone/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerApi - functional programming interface
 * @export
 */
export const CustomerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a customer like
         * @param {string} id Target id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addCustomerLike(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).addCustomerLike(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Add a new email
         * @param {string} value The email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addEmail(value: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Email>>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).addEmail(value, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Add a new phone
         * @param {string} prefix The area code
         * @param {string} value The phone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPhone(prefix: string, value: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Phone>>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).addPhone(prefix, value, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Add tenant to customer preferences
         * @param {string} id The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTenantToCustomerFavorites(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<CustomerPreferences>>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).addTenantToCustomerFavorites(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Let customers to change password
         * @param {string} currentPassword The current customer password
         * @param {string} newPassword The new password chose by customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changePassword(currentPassword: string, newPassword: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Customer>>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).changePassword(currentPassword, newPassword, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create a request link customer to a tenant
         * @param {string} id The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLinkedTenant(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Url>>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).createLinkedTenant(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Find if tenant is linked to user
         * @param {string} id The tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerIsLinked(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<BooleanValueDTO>>>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).customerIsLinked(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCustomer(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Customer>>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).deleteCustomer(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete like by id
         * @param {string} id Target id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCustomerLike(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).deleteCustomerLike(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete an email by id
         * @param {number} id The email id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEmail(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).deleteEmail(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete tenant customer relation
         * @param {string} id tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLinkedTenant(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Customer>>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).deleteLinkedTenant(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete a phone by id
         * @param {number} id The phone id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePhone(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).deletePhone(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Remove tenant from customer preferences
         * @param {string} id The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTenantFromCustomerFavorites(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<CustomerPreferences>>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).deleteTenantFromCustomerFavorites(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Mark a email as customer default email
         * @param {number} id The email id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async doDefaultEmail(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).doDefaultEmail(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Mark a phone as customer default phone
         * @param {number} id The phone id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async doDefaultPhone(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).doDefaultPhone(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Search customers filtering by full name
         * @param {string} name The customer full name
         * @param {number} start Zero-based page index
         * @param {number} size The page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByNameContaining(name: string, start: number, size: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<SimpleCustomer>>>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).findByNameContaining(name, start, size, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Search customers likes from a customer
         * @param {number} start Zero-based page index
         * @param {number} size The page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findCustomerLikeById(start: number, size: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<SimpleCustomer>>>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).findCustomerLikeById(start, size, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get all the customer emails
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findEmailByCustomer(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<Email>>>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).findEmailByCustomer(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get all the customer tenants
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findLinkedTenantByCustomer(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<Tenant>>>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).findLinkedTenantByCustomer(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get all the customer phones
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findPhoneByCustomer(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<Phone>>>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).findPhoneByCustomer(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the customer data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomer(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Customer>>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).getCustomer(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the customer results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerResults(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<CustomerResult>>>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).getCustomerResults(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the customer preferences
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPreferences(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<CustomerPreferences>>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).getPreferences(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get customer privacy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPrivacy(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Privacy>>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).getPrivacy(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Start recover password process
         * @param {string} email Address where the restore password email will be sent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initRestorePassword(email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).initRestorePassword(email, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Register a customer device
         * @param {string} token The FCM token of the device device
         * @param {string} [os] The Operative System of the device
         * @param {string} [version] The Operative System version
         * @param {string} [description] A short description
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerDevice(token: string, os?: string, version?: string, description?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).registerDevice(token, os, version, description, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Let customers to recover password
         * @param {string} token token send by mail
         * @param {string} newPassword The new password chose by customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restorePassword(token: string, newPassword: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).restorePassword(token, newPassword, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Send a notification
         * @param {string} title The title
         * @param {string} body The body
         * @param {Array<string>} tokens The target tokens
         * @param {string} bookingId The booking id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendNotification(title: string, body: string, tokens: Array<string>, bookingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).sendNotification(title, body, tokens, bookingId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Send a notification to all customers
         * @param {string} title The title
         * @param {string} body The body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendNotificationToAllCustomers(title: string, body: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).sendNotificationToAllCustomers(title, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update the sport level of a customer
         * @param {number} sport The sport of the level
         * @param {number} level The value of the level
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCustomerLevel(sport: number, level: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).updateCustomerLevel(sport, level, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Let customers to change the full name
         * @param {string} fullname The current customer password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFullName(fullname: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Customer>>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).updateFullName(fullname, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update the customer gender
         * @param {number} gender The gender of the customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGender(gender: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Customer>>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).updateGender(gender, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Let customers to change the nickname
         * @param {string} nickname The current customer password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNickName(nickname: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Customer>>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).updateNickName(nickname, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update the customer preferences
         * @param {CustomerPreferences} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePreferences(body: CustomerPreferences, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).updatePreferences(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update customer privacy
         * @param {Privacy} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePrivacy(body: Privacy, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).updatePrivacy(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Let customers to change their image
         * @param {Blob} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadImageForm(image: Blob, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Customer>>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).uploadImageForm(image, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Service to verify email process
         * @param {string} token The token received to verify email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyEmail(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).verifyEmail(token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Service to verify phone process
         * @param {string} token The token received to verify phone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyPhone(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).verifyPhone(token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CustomerApi - factory interface
 * @export
 */
export const CustomerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Create a customer like
         * @param {string} id Target id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addCustomerLike(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return CustomerApiFp(configuration).addCustomerLike(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add a new email
         * @param {string} value The email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addEmail(value: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Email>> {
            return CustomerApiFp(configuration).addEmail(value, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add a new phone
         * @param {string} prefix The area code
         * @param {string} value The phone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPhone(prefix: string, value: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Phone>> {
            return CustomerApiFp(configuration).addPhone(prefix, value, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add tenant to customer preferences
         * @param {string} id The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTenantToCustomerFavorites(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<CustomerPreferences>> {
            return CustomerApiFp(configuration).addTenantToCustomerFavorites(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Let customers to change password
         * @param {string} currentPassword The current customer password
         * @param {string} newPassword The new password chose by customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changePassword(currentPassword: string, newPassword: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Customer>> {
            return CustomerApiFp(configuration).changePassword(currentPassword, newPassword, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a request link customer to a tenant
         * @param {string} id The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLinkedTenant(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Url>> {
            return CustomerApiFp(configuration).createLinkedTenant(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find if tenant is linked to user
         * @param {string} id The tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerIsLinked(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<BooleanValueDTO>>> {
            return CustomerApiFp(configuration).customerIsLinked(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCustomer(options?: AxiosRequestConfig): Promise<AxiosResponse<Customer>> {
            return CustomerApiFp(configuration).deleteCustomer(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete like by id
         * @param {string} id Target id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCustomerLike(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return CustomerApiFp(configuration).deleteCustomerLike(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete an email by id
         * @param {number} id The email id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEmail(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return CustomerApiFp(configuration).deleteEmail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete tenant customer relation
         * @param {string} id tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLinkedTenant(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Customer>> {
            return CustomerApiFp(configuration).deleteLinkedTenant(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a phone by id
         * @param {number} id The phone id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePhone(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return CustomerApiFp(configuration).deletePhone(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove tenant from customer preferences
         * @param {string} id The tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTenantFromCustomerFavorites(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<CustomerPreferences>> {
            return CustomerApiFp(configuration).deleteTenantFromCustomerFavorites(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark a email as customer default email
         * @param {number} id The email id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async doDefaultEmail(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return CustomerApiFp(configuration).doDefaultEmail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark a phone as customer default phone
         * @param {number} id The phone id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async doDefaultPhone(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return CustomerApiFp(configuration).doDefaultPhone(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search customers filtering by full name
         * @param {string} name The customer full name
         * @param {number} start Zero-based page index
         * @param {number} size The page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByNameContaining(name: string, start: number, size: number, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<SimpleCustomer>>> {
            return CustomerApiFp(configuration).findByNameContaining(name, start, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search customers likes from a customer
         * @param {number} start Zero-based page index
         * @param {number} size The page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findCustomerLikeById(start: number, size: number, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<SimpleCustomer>>> {
            return CustomerApiFp(configuration).findCustomerLikeById(start, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all the customer emails
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findEmailByCustomer(options?: AxiosRequestConfig): Promise<AxiosResponse<Array<Email>>> {
            return CustomerApiFp(configuration).findEmailByCustomer(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all the customer tenants
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findLinkedTenantByCustomer(options?: AxiosRequestConfig): Promise<AxiosResponse<Array<Tenant>>> {
            return CustomerApiFp(configuration).findLinkedTenantByCustomer(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all the customer phones
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findPhoneByCustomer(options?: AxiosRequestConfig): Promise<AxiosResponse<Array<Phone>>> {
            return CustomerApiFp(configuration).findPhoneByCustomer(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the customer data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomer(options?: AxiosRequestConfig): Promise<AxiosResponse<Customer>> {
            return CustomerApiFp(configuration).getCustomer(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the customer results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerResults(options?: AxiosRequestConfig): Promise<AxiosResponse<Array<CustomerResult>>> {
            return CustomerApiFp(configuration).getCustomerResults(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the customer preferences
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPreferences(options?: AxiosRequestConfig): Promise<AxiosResponse<CustomerPreferences>> {
            return CustomerApiFp(configuration).getPreferences(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get customer privacy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPrivacy(options?: AxiosRequestConfig): Promise<AxiosResponse<Privacy>> {
            return CustomerApiFp(configuration).getPrivacy(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Start recover password process
         * @param {string} email Address where the restore password email will be sent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initRestorePassword(email: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return CustomerApiFp(configuration).initRestorePassword(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Register a customer device
         * @param {string} token The FCM token of the device device
         * @param {string} [os] The Operative System of the device
         * @param {string} [version] The Operative System version
         * @param {string} [description] A short description
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerDevice(token: string, os?: string, version?: string, description?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return CustomerApiFp(configuration).registerDevice(token, os, version, description, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Let customers to recover password
         * @param {string} token token send by mail
         * @param {string} newPassword The new password chose by customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restorePassword(token: string, newPassword: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return CustomerApiFp(configuration).restorePassword(token, newPassword, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a notification
         * @param {string} title The title
         * @param {string} body The body
         * @param {Array<string>} tokens The target tokens
         * @param {string} bookingId The booking id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendNotification(title: string, body: string, tokens: Array<string>, bookingId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return CustomerApiFp(configuration).sendNotification(title, body, tokens, bookingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a notification to all customers
         * @param {string} title The title
         * @param {string} body The body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendNotificationToAllCustomers(title: string, body: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return CustomerApiFp(configuration).sendNotificationToAllCustomers(title, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the sport level of a customer
         * @param {number} sport The sport of the level
         * @param {number} level The value of the level
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCustomerLevel(sport: number, level: number, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return CustomerApiFp(configuration).updateCustomerLevel(sport, level, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Let customers to change the full name
         * @param {string} fullname The current customer password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFullName(fullname: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Customer>> {
            return CustomerApiFp(configuration).updateFullName(fullname, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the customer gender
         * @param {number} gender The gender of the customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGender(gender: number, options?: AxiosRequestConfig): Promise<AxiosResponse<Customer>> {
            return CustomerApiFp(configuration).updateGender(gender, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Let customers to change the nickname
         * @param {string} nickname The current customer password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNickName(nickname: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Customer>> {
            return CustomerApiFp(configuration).updateNickName(nickname, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the customer preferences
         * @param {CustomerPreferences} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePreferences(body: CustomerPreferences, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return CustomerApiFp(configuration).updatePreferences(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update customer privacy
         * @param {Privacy} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePrivacy(body: Privacy, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return CustomerApiFp(configuration).updatePrivacy(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Let customers to change their image
         * @param {Blob} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadImageForm(image: Blob, options?: AxiosRequestConfig): Promise<AxiosResponse<Customer>> {
            return CustomerApiFp(configuration).uploadImageForm(image, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Service to verify email process
         * @param {string} token The token received to verify email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyEmail(token: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return CustomerApiFp(configuration).verifyEmail(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Service to verify phone process
         * @param {string} token The token received to verify phone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyPhone(token: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return CustomerApiFp(configuration).verifyPhone(token, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerApi - object-oriented interface
 * @export
 * @class CustomerApi
 * @extends {BaseAPI}
 */
export class CustomerApi extends BaseAPI {
    /**
     * 
     * @summary Create a customer like
     * @param {string} id Target id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public async addCustomerLike(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return CustomerApiFp(this.configuration).addCustomerLike(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Add a new email
     * @param {string} value The email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public async addEmail(value: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Email>> {
        return CustomerApiFp(this.configuration).addEmail(value, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Add a new phone
     * @param {string} prefix The area code
     * @param {string} value The phone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public async addPhone(prefix: string, value: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Phone>> {
        return CustomerApiFp(this.configuration).addPhone(prefix, value, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Add tenant to customer preferences
     * @param {string} id The tenant id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public async addTenantToCustomerFavorites(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<CustomerPreferences>> {
        return CustomerApiFp(this.configuration).addTenantToCustomerFavorites(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Let customers to change password
     * @param {string} currentPassword The current customer password
     * @param {string} newPassword The new password chose by customer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public async changePassword(currentPassword: string, newPassword: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Customer>> {
        return CustomerApiFp(this.configuration).changePassword(currentPassword, newPassword, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Create a request link customer to a tenant
     * @param {string} id The tenant id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public async createLinkedTenant(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Url>> {
        return CustomerApiFp(this.configuration).createLinkedTenant(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Find if tenant is linked to user
     * @param {string} id The tenantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public async customerIsLinked(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<BooleanValueDTO>>> {
        return CustomerApiFp(this.configuration).customerIsLinked(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Delete customer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public async deleteCustomer(options?: AxiosRequestConfig) : Promise<AxiosResponse<Customer>> {
        return CustomerApiFp(this.configuration).deleteCustomer(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Delete like by id
     * @param {string} id Target id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public async deleteCustomerLike(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return CustomerApiFp(this.configuration).deleteCustomerLike(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Delete an email by id
     * @param {number} id The email id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public async deleteEmail(id: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return CustomerApiFp(this.configuration).deleteEmail(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Delete tenant customer relation
     * @param {string} id tenant id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public async deleteLinkedTenant(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Customer>> {
        return CustomerApiFp(this.configuration).deleteLinkedTenant(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Delete a phone by id
     * @param {number} id The phone id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public async deletePhone(id: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return CustomerApiFp(this.configuration).deletePhone(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Remove tenant from customer preferences
     * @param {string} id The tenant id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public async deleteTenantFromCustomerFavorites(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<CustomerPreferences>> {
        return CustomerApiFp(this.configuration).deleteTenantFromCustomerFavorites(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Mark a email as customer default email
     * @param {number} id The email id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public async doDefaultEmail(id: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return CustomerApiFp(this.configuration).doDefaultEmail(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Mark a phone as customer default phone
     * @param {number} id The phone id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public async doDefaultPhone(id: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return CustomerApiFp(this.configuration).doDefaultPhone(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Search customers filtering by full name
     * @param {string} name The customer full name
     * @param {number} start Zero-based page index
     * @param {number} size The page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public async findByNameContaining(name: string, start: number, size: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<SimpleCustomer>>> {
        return CustomerApiFp(this.configuration).findByNameContaining(name, start, size, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Search customers likes from a customer
     * @param {number} start Zero-based page index
     * @param {number} size The page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public async findCustomerLikeById(start: number, size: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<SimpleCustomer>>> {
        return CustomerApiFp(this.configuration).findCustomerLikeById(start, size, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get all the customer emails
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public async findEmailByCustomer(options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<Email>>> {
        return CustomerApiFp(this.configuration).findEmailByCustomer(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get all the customer tenants
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public async findLinkedTenantByCustomer(options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<Tenant>>> {
        return CustomerApiFp(this.configuration).findLinkedTenantByCustomer(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get all the customer phones
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public async findPhoneByCustomer(options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<Phone>>> {
        return CustomerApiFp(this.configuration).findPhoneByCustomer(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get the customer data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public async getCustomer(options?: AxiosRequestConfig) : Promise<AxiosResponse<Customer>> {
        return CustomerApiFp(this.configuration).getCustomer(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get the customer results
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public async getCustomerResults(options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<CustomerResult>>> {
        return CustomerApiFp(this.configuration).getCustomerResults(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get the customer preferences
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public async getPreferences(options?: AxiosRequestConfig) : Promise<AxiosResponse<CustomerPreferences>> {
        return CustomerApiFp(this.configuration).getPreferences(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get customer privacy
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public async getPrivacy(options?: AxiosRequestConfig) : Promise<AxiosResponse<Privacy>> {
        return CustomerApiFp(this.configuration).getPrivacy(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Start recover password process
     * @param {string} email Address where the restore password email will be sent
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public async initRestorePassword(email: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return CustomerApiFp(this.configuration).initRestorePassword(email, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Register a customer device
     * @param {string} token The FCM token of the device device
     * @param {string} [os] The Operative System of the device
     * @param {string} [version] The Operative System version
     * @param {string} [description] A short description
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public async registerDevice(token: string, os?: string, version?: string, description?: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return CustomerApiFp(this.configuration).registerDevice(token, os, version, description, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Let customers to recover password
     * @param {string} token token send by mail
     * @param {string} newPassword The new password chose by customer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public async restorePassword(token: string, newPassword: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return CustomerApiFp(this.configuration).restorePassword(token, newPassword, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Send a notification
     * @param {string} title The title
     * @param {string} body The body
     * @param {Array<string>} tokens The target tokens
     * @param {string} bookingId The booking id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public async sendNotification(title: string, body: string, tokens: Array<string>, bookingId: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return CustomerApiFp(this.configuration).sendNotification(title, body, tokens, bookingId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Send a notification to all customers
     * @param {string} title The title
     * @param {string} body The body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public async sendNotificationToAllCustomers(title: string, body: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return CustomerApiFp(this.configuration).sendNotificationToAllCustomers(title, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Update the sport level of a customer
     * @param {number} sport The sport of the level
     * @param {number} level The value of the level
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public async updateCustomerLevel(sport: number, level: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return CustomerApiFp(this.configuration).updateCustomerLevel(sport, level, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Let customers to change the full name
     * @param {string} fullname The current customer password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public async updateFullName(fullname: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Customer>> {
        return CustomerApiFp(this.configuration).updateFullName(fullname, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Update the customer gender
     * @param {number} gender The gender of the customer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public async updateGender(gender: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<Customer>> {
        return CustomerApiFp(this.configuration).updateGender(gender, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Let customers to change the nickname
     * @param {string} nickname The current customer password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public async updateNickName(nickname: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Customer>> {
        return CustomerApiFp(this.configuration).updateNickName(nickname, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Update the customer preferences
     * @param {CustomerPreferences} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public async updatePreferences(body: CustomerPreferences, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return CustomerApiFp(this.configuration).updatePreferences(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Update customer privacy
     * @param {Privacy} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public async updatePrivacy(body: Privacy, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return CustomerApiFp(this.configuration).updatePrivacy(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Let customers to change their image
     * @param {Blob} image 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public async uploadImageForm(image: Blob, options?: AxiosRequestConfig) : Promise<AxiosResponse<Customer>> {
        return CustomerApiFp(this.configuration).uploadImageForm(image, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Service to verify email process
     * @param {string} token The token received to verify email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public async verifyEmail(token: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return CustomerApiFp(this.configuration).verifyEmail(token, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Service to verify phone process
     * @param {string} token The token received to verify phone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public async verifyPhone(token: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return CustomerApiFp(this.configuration).verifyPhone(token, options).then((request) => request(this.axios, this.basePath));
    }
}
