<template>
  <div class="background">
    <CookiesPrivacy />
    <router-view />
    <GlobalError />
    <Loader />
  </div>
</template>


<script lang="ts">
import { defineComponent } from "vue";
import CookiesPrivacy from "@/components/cookies/cookiesPrivacy.vue";
import Loader from "@/components/loader/loader.vue";
import GlobalError from "@/components/globalError/GlobalError.vue";

export default defineComponent({
  name: "App",
  components: {
    CookiesPrivacy,
    Loader,
    GlobalError
  },
  mounted() {
    // cargar aqui settings y guardar en vuex
    /* settear aqui las variables de css, el nombre del club y el logo
    :root {
    --background-color: #010e21;
    --background-color-box: #01162A;
    --main-color: #ffffff;
    --color1: #1AC0EA;
    --color2: #0458E2;
  }
  */
  },
});
</script>

