/* tslint:disable */
/* eslint-disable */
/**
 * Marketplace Api
 * Marketplace Api description
 *
 * OpenAPI spec version: 1.0
 * Contact: develop@taykus.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { AvailabilityRequest } from '../models';
import { Booking } from '../models';
import { BookingPricesRequest } from '../models';
import { BookingRequest } from '../models';
import { BookingResult } from '../models';
import { Player } from '../models';
import { PreBooking } from '../models';
import { PricesRequest } from '../models';
import { TenantAvailabilityDTO } from '../models';
import { TenantAvailabilityRequest } from '../models';
/**
 * BookingApi - axios parameter creator
 * @export
 */
export const BookingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add a result to the booking
         * @param {BookingResult} body 
         * @param {string} id The booking id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addBookingResult: async (body: BookingResult, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addBookingResult.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling addBookingResult.');
            }
            const localVarPath = `/api/booking/{id}/result`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers!['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add a player
         * @param {Player} body 
         * @param {string} id The booking id
         * @param {number} playerId The player id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPlayer: async (body: Player, id: string, playerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addPlayer.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling addPlayer.');
            }
            // verify required parameter 'playerId' is not null or undefined
            if (playerId === null || playerId === undefined) {
                throw new RequiredError('playerId','Required parameter playerId was null or undefined when calling addPlayer.');
            }
            const localVarPath = `/api/booking/{id}/player/{playerId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"playerId"}}`, encodeURIComponent(String(playerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers!['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get bookings availability
         * @param {AvailabilityRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        availability: async (body: AvailabilityRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling availability.');
            }
            const localVarPath = `/api/booking/availability`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers!['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel Booking
         * @param {string} id booking id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelBooking: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling cancelBooking.');
            }
            const localVarPath = `/api/booking/cancel/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a sale to pay players
         * @param {string} id The booking id
         * @param {Array<number>} playerIds The player id list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSale: async (id: string, playerIds: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling createSale.');
            }
            // verify required parameter 'playerIds' is not null or undefined
            if (playerIds === null || playerIds === undefined) {
                throw new RequiredError('playerIds','Required parameter playerIds was null or undefined when calling createSale.');
            }
            const localVarPath = `/api/booking/{id}/sale`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (playerIds) {
                localVarQueryParameter['playerIds'] = playerIds;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a player
         * @param {string} id The booking id
         * @param {number} playerId The player id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePlayer: async (id: string, playerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deletePlayer.');
            }
            // verify required parameter 'playerId' is not null or undefined
            if (playerId === null || playerId === undefined) {
                throw new RequiredError('playerId','Required parameter playerId was null or undefined when calling deletePlayer.');
            }
            const localVarPath = `/api/booking/{id}/player/{playerId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"playerId"}}`, encodeURIComponent(String(playerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all customer's bookings
         * @param {number} start Zero-based page index
         * @param {number} size The page size
         * @param {boolean} [owner] True if bookings where owner is customer should be found. False if owner is customer or participant
         * @param {string} [include] Parameter to filter by date: all / next / old
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findBookingByCustomer: async (start: number, size: number, owner?: boolean, include?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'start' is not null or undefined
            if (start === null || start === undefined) {
                throw new RequiredError('start','Required parameter start was null or undefined when calling findBookingByCustomer.');
            }
            // verify required parameter 'size' is not null or undefined
            if (size === null || size === undefined) {
                throw new RequiredError('size','Required parameter size was null or undefined when calling findBookingByCustomer.');
            }
            const localVarPath = `/api/booking`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (owner !== undefined) {
                localVarQueryParameter['owner'] = owner;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (include !== undefined) {
                localVarQueryParameter['include'] = include;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a booking by id
         * @param {string} id The booking id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findBookingById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling findBookingById.');
            }
            const localVarPath = `/api/booking/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a booking by crmId and tenantId
         * @param {string} tenantId The tenant id
         * @param {number} id The crm id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByCrmIdAndTenant: async (tenantId: string, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling findByCrmIdAndTenant.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling findByCrmIdAndTenant.');
            }
            const localVarPath = `/api/booking/{tenantId}/{id}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get prices about the participants of a future booking
         * @param {PricesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrices: async (body: PricesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getPrices.');
            }
            const localVarPath = `/api/booking/price`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers!['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Move a player
         * @param {string} id The booking id
         * @param {number} playerId The player id
         * @param {number} targetPlayerId The page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        movePlayer: async (id: string, playerId: number, targetPlayerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling movePlayer.');
            }
            // verify required parameter 'playerId' is not null or undefined
            if (playerId === null || playerId === undefined) {
                throw new RequiredError('playerId','Required parameter playerId was null or undefined when calling movePlayer.');
            }
            // verify required parameter 'targetPlayerId' is not null or undefined
            if (targetPlayerId === null || targetPlayerId === undefined) {
                throw new RequiredError('targetPlayerId','Required parameter targetPlayerId was null or undefined when calling movePlayer.');
            }
            const localVarPath = `/api/booking/{id}/player/{playerId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"playerId"}}`, encodeURIComponent(String(playerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (targetPlayerId !== undefined) {
                localVarQueryParameter['targetPlayerId'] = targetPlayerId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a booking
         * @param {BookingRequest} body 
         * @param {string} [xTenant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prebooking: async (body: BookingRequest, xTenant?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling prebooking.');
            }
            const localVarPath = `/api/booking`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (xTenant !== undefined && xTenant !== null) {
                localVarHeaderParameter['x-tenant'] = String(xTenant);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers!['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get availability and prices about a Tenant
         * @param {TenantAvailabilityRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantAvailability: async (body: TenantAvailabilityRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling tenantAvailability.');
            }
            const localVarPath = `/api/booking/availability/price`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers!['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BookingApi - functional programming interface
 * @export
 */
export const BookingApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add a result to the booking
         * @param {BookingResult} body 
         * @param {string} id The booking id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addBookingResult(body: BookingResult, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Booking>>> {
            const localVarAxiosArgs = await BookingApiAxiosParamCreator(configuration).addBookingResult(body, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Add a player
         * @param {Player} body 
         * @param {string} id The booking id
         * @param {number} playerId The player id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPlayer(body: Player, id: string, playerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Booking>>> {
            const localVarAxiosArgs = await BookingApiAxiosParamCreator(configuration).addPlayer(body, id, playerId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get bookings availability
         * @param {AvailabilityRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async availability(body: AvailabilityRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<string>>> {
            const localVarAxiosArgs = await BookingApiAxiosParamCreator(configuration).availability(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Cancel Booking
         * @param {string} id booking id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelBooking(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await BookingApiAxiosParamCreator(configuration).cancelBooking(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create a sale to pay players
         * @param {string} id The booking id
         * @param {Array<number>} playerIds The player id list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSale(id: string, playerIds: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<PreBooking>>> {
            const localVarAxiosArgs = await BookingApiAxiosParamCreator(configuration).createSale(id, playerIds, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete a player
         * @param {string} id The booking id
         * @param {number} playerId The player id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePlayer(id: string, playerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Booking>>> {
            const localVarAxiosArgs = await BookingApiAxiosParamCreator(configuration).deletePlayer(id, playerId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get all customer's bookings
         * @param {number} start Zero-based page index
         * @param {number} size The page size
         * @param {boolean} [owner] True if bookings where owner is customer should be found. False if owner is customer or participant
         * @param {string} [include] Parameter to filter by date: all / next / old
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findBookingByCustomer(start: number, size: number, owner?: boolean, include?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<Booking>>>> {
            const localVarAxiosArgs = await BookingApiAxiosParamCreator(configuration).findBookingByCustomer(start, size, owner, include, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get a booking by id
         * @param {string} id The booking id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findBookingById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Booking>>> {
            const localVarAxiosArgs = await BookingApiAxiosParamCreator(configuration).findBookingById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get a booking by crmId and tenantId
         * @param {string} tenantId The tenant id
         * @param {number} id The crm id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByCrmIdAndTenant(tenantId: string, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Booking>>> {
            const localVarAxiosArgs = await BookingApiAxiosParamCreator(configuration).findByCrmIdAndTenant(tenantId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get prices about the participants of a future booking
         * @param {PricesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPrices(body: PricesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<BookingPricesRequest>>> {
            const localVarAxiosArgs = await BookingApiAxiosParamCreator(configuration).getPrices(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Move a player
         * @param {string} id The booking id
         * @param {number} playerId The player id
         * @param {number} targetPlayerId The page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async movePlayer(id: string, playerId: number, targetPlayerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Booking>>> {
            const localVarAxiosArgs = await BookingApiAxiosParamCreator(configuration).movePlayer(id, playerId, targetPlayerId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create a booking
         * @param {BookingRequest} body 
         * @param {string} [xTenant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async prebooking(body: BookingRequest, xTenant?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<PreBooking>>> {
            const localVarAxiosArgs = await BookingApiAxiosParamCreator(configuration).prebooking(body, xTenant, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get availability and prices about a Tenant
         * @param {TenantAvailabilityRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantAvailability(body: TenantAvailabilityRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<TenantAvailabilityDTO>>> {
            const localVarAxiosArgs = await BookingApiAxiosParamCreator(configuration).tenantAvailability(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * BookingApi - factory interface
 * @export
 */
export const BookingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Add a result to the booking
         * @param {BookingResult} body 
         * @param {string} id The booking id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addBookingResult(body: BookingResult, id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Booking>> {
            return BookingApiFp(configuration).addBookingResult(body, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add a player
         * @param {Player} body 
         * @param {string} id The booking id
         * @param {number} playerId The player id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPlayer(body: Player, id: string, playerId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<Booking>> {
            return BookingApiFp(configuration).addPlayer(body, id, playerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get bookings availability
         * @param {AvailabilityRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async availability(body: AvailabilityRequest, options?: AxiosRequestConfig): Promise<AxiosResponse<string>> {
            return BookingApiFp(configuration).availability(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancel Booking
         * @param {string} id booking id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelBooking(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return BookingApiFp(configuration).cancelBooking(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a sale to pay players
         * @param {string} id The booking id
         * @param {Array<number>} playerIds The player id list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSale(id: string, playerIds: Array<number>, options?: AxiosRequestConfig): Promise<AxiosResponse<PreBooking>> {
            return BookingApiFp(configuration).createSale(id, playerIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a player
         * @param {string} id The booking id
         * @param {number} playerId The player id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePlayer(id: string, playerId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<Booking>> {
            return BookingApiFp(configuration).deletePlayer(id, playerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all customer's bookings
         * @param {number} start Zero-based page index
         * @param {number} size The page size
         * @param {boolean} [owner] True if bookings where owner is customer should be found. False if owner is customer or participant
         * @param {string} [include] Parameter to filter by date: all / next / old
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findBookingByCustomer(start: number, size: number, owner?: boolean, include?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<Booking>>> {
            return BookingApiFp(configuration).findBookingByCustomer(start, size, owner, include, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a booking by id
         * @param {string} id The booking id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findBookingById(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Booking>> {
            return BookingApiFp(configuration).findBookingById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a booking by crmId and tenantId
         * @param {string} tenantId The tenant id
         * @param {number} id The crm id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByCrmIdAndTenant(tenantId: string, id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<Booking>> {
            return BookingApiFp(configuration).findByCrmIdAndTenant(tenantId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get prices about the participants of a future booking
         * @param {PricesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPrices(body: PricesRequest, options?: AxiosRequestConfig): Promise<AxiosResponse<BookingPricesRequest>> {
            return BookingApiFp(configuration).getPrices(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Move a player
         * @param {string} id The booking id
         * @param {number} playerId The player id
         * @param {number} targetPlayerId The page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async movePlayer(id: string, playerId: number, targetPlayerId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<Booking>> {
            return BookingApiFp(configuration).movePlayer(id, playerId, targetPlayerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a booking
         * @param {BookingRequest} body 
         * @param {string} [xTenant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async prebooking(body: BookingRequest, xTenant?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<PreBooking>> {
            return BookingApiFp(configuration).prebooking(body, xTenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get availability and prices about a Tenant
         * @param {TenantAvailabilityRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantAvailability(body: TenantAvailabilityRequest, options?: AxiosRequestConfig): Promise<AxiosResponse<TenantAvailabilityDTO>> {
            return BookingApiFp(configuration).tenantAvailability(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BookingApi - object-oriented interface
 * @export
 * @class BookingApi
 * @extends {BaseAPI}
 */
export class BookingApi extends BaseAPI {
    /**
     * 
     * @summary Add a result to the booking
     * @param {BookingResult} body 
     * @param {string} id The booking id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public async addBookingResult(body: BookingResult, id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Booking>> {
        return BookingApiFp(this.configuration).addBookingResult(body, id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Add a player
     * @param {Player} body 
     * @param {string} id The booking id
     * @param {number} playerId The player id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public async addPlayer(body: Player, id: string, playerId: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<Booking>> {
        return BookingApiFp(this.configuration).addPlayer(body, id, playerId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get bookings availability
     * @param {AvailabilityRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public async availability(body: AvailabilityRequest, options?: AxiosRequestConfig) : Promise<AxiosResponse<string>> {
        return BookingApiFp(this.configuration).availability(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Cancel Booking
     * @param {string} id booking id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public async cancelBooking(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return BookingApiFp(this.configuration).cancelBooking(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Create a sale to pay players
     * @param {string} id The booking id
     * @param {Array<number>} playerIds The player id list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public async createSale(id: string, playerIds: Array<number>, options?: AxiosRequestConfig) : Promise<AxiosResponse<PreBooking>> {
        return BookingApiFp(this.configuration).createSale(id, playerIds, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Delete a player
     * @param {string} id The booking id
     * @param {number} playerId The player id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public async deletePlayer(id: string, playerId: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<Booking>> {
        return BookingApiFp(this.configuration).deletePlayer(id, playerId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get all customer's bookings
     * @param {number} start Zero-based page index
     * @param {number} size The page size
     * @param {boolean} [owner] True if bookings where owner is customer should be found. False if owner is customer or participant
     * @param {string} [include] Parameter to filter by date: all / next / old
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public async findBookingByCustomer(start: number, size: number, owner?: boolean, include?: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<Booking>>> {
        return BookingApiFp(this.configuration).findBookingByCustomer(start, size, owner, include, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get a booking by id
     * @param {string} id The booking id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public async findBookingById(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Booking>> {
        return BookingApiFp(this.configuration).findBookingById(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get a booking by crmId and tenantId
     * @param {string} tenantId The tenant id
     * @param {number} id The crm id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public async findByCrmIdAndTenant(tenantId: string, id: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<Booking>> {
        return BookingApiFp(this.configuration).findByCrmIdAndTenant(tenantId, id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get prices about the participants of a future booking
     * @param {PricesRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public async getPrices(body: PricesRequest, options?: AxiosRequestConfig) : Promise<AxiosResponse<BookingPricesRequest>> {
        return BookingApiFp(this.configuration).getPrices(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Move a player
     * @param {string} id The booking id
     * @param {number} playerId The player id
     * @param {number} targetPlayerId The page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public async movePlayer(id: string, playerId: number, targetPlayerId: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<Booking>> {
        return BookingApiFp(this.configuration).movePlayer(id, playerId, targetPlayerId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Create a booking
     * @param {BookingRequest} body 
     * @param {string} [xTenant] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public async prebooking(body: BookingRequest, xTenant?: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<PreBooking>> {
        return BookingApiFp(this.configuration).prebooking(body, xTenant, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get availability and prices about a Tenant
     * @param {TenantAvailabilityRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public async tenantAvailability(body: TenantAvailabilityRequest, options?: AxiosRequestConfig) : Promise<AxiosResponse<TenantAvailabilityDTO>> {
        return BookingApiFp(this.configuration).tenantAvailability(body, options).then((request) => request(this.axios, this.basePath));
    }
}
