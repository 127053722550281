import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelCheckbox as _vModelCheckbox, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "max-w-md" }
const _hoisted_2 = { class: "text-center" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "flex justify-center mt-6 w-full input-component relative" }
const _hoisted_5 = ["placeholder"]
const _hoisted_6 = { class: "flex justify-start" }
const _hoisted_7 = {
  key: 0,
  class: "validation-error max-w-xs whitespace-normal"
}
const _hoisted_8 = { class: "flex justify-center w-full mt-4" }
const _hoisted_9 = { class: "relative w-full input-component policy" }
const _hoisted_10 = { class: "input-table flex w-6/6" }
const _hoisted_11 = ["disabled"]
const _hoisted_12 = {
  key: 0,
  class: "flex justify-center w-full mt-8 input-component"
}
const _hoisted_13 = ["value", "disabled"]
const _hoisted_14 = {
  key: 1,
  id: "sendMailMessage",
  class: "flex justify-center w-full"
}
const _hoisted_15 = { class: "send-mail-message" }
const _hoisted_16 = {
  id: "registerError",
  class: "flex justify-center w-full"
}
const _hoisted_17 = { class: "form-error max-w-xs whitespace-normal" }
const _hoisted_18 = { class: "flex justify-center w-full create-account" }
const _hoisted_19 = { class: "relative w-full account-text" }
const _hoisted_20 = { class: "flex justify-center w-full" }
const _hoisted_21 = { class: "relative w-3/6 float-right" }
const _hoisted_22 = { class: "relative w-3/6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SvgContainer = _resolveComponent("SvgContainer")!
  const _component_InputPhone = _resolveComponent("InputPhone")!
  const _component_InputEmail = _resolveComponent("InputEmail")!
  const _component_InputPassword = _resolveComponent("InputPassword")!
  const _component_CustomGoogleLogin = _resolveComponent("CustomGoogleLogin")!
  const _component_CustomAppleLogin = _resolveComponent("CustomAppleLogin")!
  const _component_Popup = _resolveComponent("Popup")!

  return (_openBlock(), _createBlock(_Transition, null, {
    default: _withCtx(() => [
      (_ctx.openRegister || _ctx.$store.state.registerIsOpen)
        ? (_openBlock(), _createBlock(_component_Popup, {
            key: 0,
            showOkButton: false,
            onClosed: _cache[9] || (_cache[9] = ($event: any) => (_ctx.closeRegister()))
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", null, [
                  _createElementVNode("h4", _hoisted_2, [
                    _createElementVNode("header", null, [
                      _createElementVNode("img", {
                        class: "inline-block logo",
                        alt: "Taykus logo",
                        src: _ctx.clubData.logo
                      }, null, 8, _hoisted_3)
                    ])
                  ])
                ]),
                _createElementVNode("h2", null, _toDisplayString(_ctx.$t("register")), 1),
                _createElementVNode("p", null, _toDisplayString(_ctx.$t("discover_your_places_in_the_world")), 1),
                _createElementVNode("form", {
                  onSubmit: _cache[7] || (_cache[7] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"]))
                }, [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_SvgContainer, { name: "registerFullNameIcon" }),
                    _withDirectives(_createElementVNode("input", {
                      class: _normalizeClass(["w-full input-rounded", {
              'is-invalid': !_ctx.isFullNameValid && _ctx.isFullNameTouched,
            }]),
                      type: "text",
                      placeholder: _ctx.$t('full_name'),
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.fullName) = $event)),
                      onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.validateFullName && _ctx.validateFullName(...args)))
                    }, null, 42, _hoisted_5), [
                      [_vModelText, _ctx.fullName]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_6, [
                    (!_ctx.isFullNameValid && _ctx.isFullNameTouched)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.$t("full_name_cant_be_null")), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _createVNode(_component_InputPhone, {
                    ref: "inputPhone",
                    onPhoneValidated: _ctx.handlePhoneValidated
                  }, null, 8, ["onPhoneValidated"]),
                  _createVNode(_component_InputEmail, {
                    ref: "inputEmail",
                    modelValue: _ctx.email,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.email) = $event))
                  }, null, 8, ["modelValue"]),
                  _createVNode(_component_InputPassword, {
                    ref: "inputPsw",
                    onPasswordValidated: _ctx.handlePasswordValidated
                  }, null, 8, ["onPasswordValidated"]),
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("div", _hoisted_10, [
                        _withDirectives(_createElementVNode("input", {
                          type: "checkbox",
                          class: _normalizeClass(["cursor-pointer", { seleccionado: _ctx.isChecked, 'is-invalid': !_ctx.isChecked }]),
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isChecked) = $event)),
                          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.isChecked = !_ctx.isChecked)),
                          disabled: _ctx.sendMailMessage != ''
                        }, null, 10, _hoisted_11), [
                          [_vModelCheckbox, _ctx.isChecked]
                        ]),
                        _createElementVNode("label", null, [
                          _createElementVNode("span", {
                            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.isChecked = !_ctx.isChecked)),
                            style: {"cursor":"pointer"}
                          }, _toDisplayString(_ctx.$t("i_have_and_agree")) + " ", 1),
                          _createElementVNode("a", {
                            id: "legalLink",
                            href: "#",
                            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.openLegal()))
                          }, _toDisplayString(_ctx.$t("private_policy")), 1)
                        ])
                      ])
                    ])
                  ]),
                  (_ctx.sendMailMessage == '')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                        _createElementVNode("input", {
                          type: "submit",
                          value: _ctx.$t('register'),
                          disabled: _ctx.hasErrors,
                          class: _normalizeClass({ 'button-disabled': _ctx.hasErrors, '': true })
                        }, null, 10, _hoisted_13)
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.sendMailMessage)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                        _createElementVNode("h2", _hoisted_15, _toDisplayString(_ctx.sendMailMessage), 1)
                      ]))
                    : _createCommentVNode("", true)
                ], 32),
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("p", _hoisted_17, _toDisplayString(_ctx.registerError), 1)
                ]),
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("div", _hoisted_19, [
                    _createVNode(_component_SvgContainer, { name: "registerLeftBar" }),
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t("or_create_account_with")), 1),
                    _createVNode(_component_SvgContainer, { name: "registerRightBar" })
                  ])
                ]),
                _createElementVNode("div", _hoisted_20, [
                  _createElementVNode("div", _hoisted_21, [
                    _createVNode(_component_CustomGoogleLogin, { onSuccess: _ctx.handleAppLoginSuccess }, null, 8, ["onSuccess"])
                  ]),
                  _createElementVNode("div", _hoisted_22, [
                    _createVNode(_component_CustomAppleLogin, { onSuccess: _ctx.handleAppLoginSuccess }, null, 8, ["onSuccess"])
                  ])
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("div", {
                    class: "walletButton mt-6 p-4 text-center cursor-pointer sm:text-sm",
                    onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.showLogin()))
                  }, _toDisplayString(_ctx.$t("login")), 1)
                ])
              ])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}