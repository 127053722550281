<template>
    <div>
      <label v-if="!isEditing" @click="startEditing" class="flex items-end cursor-pointer">{{ label }}
        <div class="mt-auto">
          <SvgContainer name="pencilProfile" />
        </div>
      </label>
      <div v-else class="flex items-center cursor-pointer">
        <input
          ref="inputField"
          v-model="label"
          class="input"
          @keydown.enter="saveChanges"
        />
        <div class="ml-2" @click="saveChanges">
          <div class="bg-blue-500 rounded-full w-6 h-6 flex items-center justify-center text-white">
            <i class="fas fa-check text-xs"></i><!-- Font Awesome Save Icon -->
          </div>
        </div>
        <div @click="notSaveChanges">
          <div class="bg-red-500 rounded-full w-6 h-6 flex items-center justify-center text-white ml-1">
            <i class="fas fa-times text-xs"></i><!-- Font Awesome Cancel Icon -->
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import CustomerHelper from "@/helpers/customerHelper";
  import SvgContainer from "@/views/SvgContainer.vue";
  import { Format } from "@/helpers/formatHelper";
  
  export default {
    name: "ProfileNickName",
    data() {
      return {
        isEditing: false,
        label: "",
        originalLabel: "",
      };
    },
    components: {
      SvgContainer,
    },
    async mounted() {
        this.label = await CustomerHelper.getUserNickName();
        if(Format.IsNull(this.label)){
          this.label = this.$t("your_alias_here")
        }
        this.originalLabel = this.label;
    },
    
    methods: {
      startEditing() {
        this.isEditing = true;
        this.$nextTick(() => {
          this.$refs.inputField.focus();
        });
      },
      notSaveChanges() {
        this.isEditing = false;
        this.label = this.originalLabel;
      },
      async saveChanges() {
        if(Format.IsNull(this.label)){
          this.label = this.$t("nickname_cant_be_null")
          return;
        }
        this.isEditing = false;
        this.originalLabel = this.label;
        await CustomerHelper.updateNickName(this.label);
      }
    },
  };
  </script>
  
  <style scoped>
  .input {
    background-color: black;
    color: white;
  }
  </style>
  