import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5a927b55"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mt-8" }
const _hoisted_2 = { class: "mt-5 text-center text-base" }
const _hoisted_3 = { class: "OpeningOpacityText mt-5 text-center font-normal text-sm" }
const _hoisted_4 = { class: "flex justify-center" }
const _hoisted_5 = { class: "mt-8" }
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PopUpLight = _resolveComponent("PopUpLight")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("do_you_have_an_account_at_this_club")), 1),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("link_your_account_and_get_club_benefits")), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          class: "user-icon mt-5 text-center search-btn cursor-pointer",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openClubLink && _ctx.openClubLink(...args)))
        }, _toDisplayString(_ctx.$t("link_account")), 1)
      ])
    ]),
    _createVNode(_Transition, null, {
      default: _withCtx(() => [
        (_ctx.showPopLinkAccount)
          ? (_openBlock(), _createBlock(_component_PopUpLight, {
              key: 0,
              onClosed: _ctx.closePopupLink
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("iframe", {
                    title: "LinkAccount",
                    class: "iframe",
                    frameborder: "0",
                    src: _ctx.urlLink
                  }, null, 8, _hoisted_6)
                ])
              ]),
              _: 1
            }, 8, ["onClosed"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}