<template>
  <div>
    <div class="flex items-center justify-center">
      <img
        v-if="showImage"
        class="mb-6 sm:hidden image"
        :src="imageUrl"
        alt=""
      />
    </div>
    <div v-if="parentName == 'checkoutView'" class="custom-gradient text-center text-2xl not-italic font-semibold md:pb-2 sm:pb-2 md:mb-2 sm:mb-2 sm:text-base">
      <span>{{ $t("booking_details") }}</span>
    </div>
    <div class="containerPaymentText flex justify-between">
      <div class="flex items-center mr-2">
        <img class="mr-2" src="../../assets/images/calendar.svg" alt="" />
        <p class="sm:text-xs">{{ $t("date_colon") }}</p>
      </div>
      <p class="playerSubtitle sm:text-xs">
        {{ getDateStr() }}
      </p>
    </div>
    <div class="containerPaymentText flex justify-between">
      <div class="flex items-center mr-2">
        <img class="mr-2" src="../../assets/images/Location.svg" alt="" />
        <p class="sm:text-xs">{{ $t("location_colon") }}</p>
      </div>
      <div class="flex">
      <router-link to="#" @click="goToMaps()" class="text-blue-500">
        <p class="sm:text-xs">{{ capitalizeFirstLetter(activity.tenant.name) }}</p>
      </router-link>
      </div>
    </div>
    <div class="containerPaymentText flex justify-between">
      <div class="flex items-center mr-2">
        <div class="flex items-center">
          <div class="mr-2">
            <SvgContainer name="dollarIcon"/>
          </div>
        </div>
        <p class="sm:text-xs">{{ $t("total_amount_colon") }}</p>
      </div>
      <p class="playerSubtitle sm:text-xs">{{ activity.placeAmount.grossAmountStr }}</p>
    </div>
    <div class="containerPaymentText flex justify-between" v-if="showShare">
      <div class="flex items-baseline">
        <img class="mr-2" src="../../assets/images/Location.svg" alt="" />
        <p class="sm:text-xs">{{ $t("share_with_friends") }}</p>
      </div>
      <div class="tooltip" v-if="!isDeviceVeryOld()">
        <div class="relative mb-2 cursor-pointer">
          <input
            readonly
            class="relative lastInput w-full items-center pr-4"
            type="text"
            :placeholder="getBookingUrl(activity.id)"
            id="bookingUrl"
            @click="copyToClipBoard()"
          />
          <div @click="copyToClipBoard()" class="inputImage">
            <SvgContainer name="copyIcon" />
          </div>
        </div>
        <span class="tooltiptext-hidden" :class="{ tooltiptext: copied }">{{
          $t("copied_exclamation")
        }}</span>
      </div>
    </div>
  </div>
 </template>
  
<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { beautifyDate, capitalizeFirstLetter } from "@/helpers/dateHelper";
import { MAP_URL, DEFAULT_TENANT_IMAGE, Sport, Gender, GenderLabel } from "@/enum/constants";
import { Activity } from "models";
import { AppsHelper, isDeviceVeryOld } from "@/helpers/appsHelper";
import { Format } from "@/helpers/formatHelper";
import { getGender } from "@/helpers/dataHelper";
import { BookingHelper, getBookingUrl } from "@/helpers/bookingHelper";
import SvgContainer from "@/views/SvgContainer.vue";

export default defineComponent({
  name: "activityDetail",
  props: {
    showImage: {
      type: Boolean,
      default: true,
    },
    activity: {
      type: Object as PropType<Activity>,
      required: true,
    },
    showShare: {
      type: Boolean,
      default: true,
    },
    class:{
      type: String
    }
  },
  components: {
    SvgContainer,
  },
  computed: {
    parentName() {
      return this.$parent!.$options.name!;
    }
  },
  data() {
    return {
      activityId: '' as string,
      imageUrl: '' as string,
      copied: false,
      sport: Sport,
      formattedPrice: null,
    };
  },
  created() {
    this.activityId = this.activity.id!;
    if (!this.activity!.image) {
      this.imageUrl = this.activity.image!;
    }
    this.fixIphoneHeight();
  },
  methods: {
    capitalizeFirstLetter,
    isDeviceVeryOld,
    getBookingUrl,
    async copyToClipBoard() {
      this.copied = await BookingHelper.copyBoookingUrlToClipBoard(this.activity.id as string);
    },
    getDateStr() {
      return beautifyDate(this.activity.startDate!.toString(), true, false);
    },
    fixIphoneHeight() {
      if (AppsHelper.isSafariOrIosWebView()) {
        const wrapperCheckout = document.querySelector(".wrapperCheckout");
        if (wrapperCheckout) {
          wrapperCheckout.classList.add("pb-150");
        }
      }
    },
  },
});
</script>
<style scoped>
/* Tooltip text */
.tooltip .tooltiptext {
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}
.tooltip .tooltiptext-hidden {
  visibility: hidden;
  opacity: 0;
}
.tooltip .tooltiptext {
  visibility: visible !important;
  opacity: 1 !important;
}
.inputImage {
  position: absolute;
  right: -10px;
  top: 50%;
  transform: translate(-20px, -50%);
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lastInput {
  border-radius: 24px;
  opacity: 0.800000011920929;
  background: linear-gradient(
      270deg,
      rgba(12, 31, 50, 0.2) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    rgba(255, 255, 255, 0.02);
  box-shadow: 0px 0px 12px 0px rgba(255, 255, 255, 0.15) inset;
  backdrop-filter: blur(16px);
  padding: 12px 40px 12px 24px;
  gap: 12px;
}

.image {
  max-width: 548px !important;
  max-height: 424px !important;
  border-radius: 16px;
}
.containerPaymentText {
  padding: 21px 0;
}

@media (max-width: 767px) {
  .containerPaymentText {
    padding: 8px 0;
  }
}
</style>