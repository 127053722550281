<template>
  <div class="mt-8">
    <label>
      <input type="radio" v-model="selectedOption" value="APPROVED" />
      APPROVED
    </label>
    <label>
      <input type="radio" v-model="selectedOption" value="DECLINED" />
      DECLINED
    </label>
    <label>
      <input type="radio" v-model="selectedOption" value="CANCEL" />
      CANCEL
    </label>
    <button @click="submitForm">Submit</button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "AzulPayment",
  data() {
    return {
      selectedOption: null,
      actionURL: '',
      queryParams: {
        MerchantId: '',
        MerchantName: '',
        MerchantType: '',
        CurrencyCode: '',
        OrderNumber: '',
        Amount: '',
        ITBIS: '',
        ApprovedUrl: '',
        DeclinedUrl: '',
        CancelUrl: '',
        UseCustomField1: '',
        UseCustomField2: '',
        AuthHash: '',
        ShowTransactionResult: '',
        Locale: '',
        SaveToDataVault: ''
      }
    };
  },
  created() {
    this.extractQueryParams();
  },
  methods: {
    submitForm() {
      if (this.selectedOption) {
        switch (this.selectedOption) {
          case "APPROVED":
            this.actionURL = this.queryParams.ApprovedUrl;
            break;
          case "DECLINED":
            this.actionURL = this.queryParams.DeclinedUrl;
            break;
          case "CANCEL":
            this.actionURL = this.queryParams.CancelUrl;
            break;
        }

        const form = document.createElement('form');
        form.action = this.actionURL;
        form.method = 'post';
        form.id = 'paymentForm';
        form.name = 'paymentForm';
        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);
      } else {
        alert('Please select an option');
      }
    },
    extractQueryParams() {
      const urlParams = new URLSearchParams(window.location.search);
      this.queryParams.MerchantId = urlParams.get('MerchantId') || '';
      this.queryParams.MerchantName = urlParams.get('MerchantName') || '';
      this.queryParams.MerchantType = urlParams.get('MerchantType') || '';
      this.queryParams.CurrencyCode = urlParams.get('CurrencyCode') || '';
      this.queryParams.OrderNumber = urlParams.get('OrderNumber') || '';
      this.queryParams.Amount = urlParams.get('Amount') || '';
      this.queryParams.ITBIS = urlParams.get('ITBIS') || '';
      this.queryParams.ApprovedUrl = urlParams.get('ApprovedUrl') || '';
      this.queryParams.DeclinedUrl = urlParams.get('DeclinedUrl') || '';
      this.queryParams.CancelUrl = urlParams.get('CancelUrl') || '';
      this.queryParams.UseCustomField1 = urlParams.get('UseCustomField1') || '';
      this.queryParams.UseCustomField2 = urlParams.get('UseCustomField2') || '';
      this.queryParams.AuthHash = urlParams.get('AuthHash') || '';
      this.queryParams.ShowTransactionResult = urlParams.get('ShowTransactionResult') || '';
      this.queryParams.Locale = urlParams.get('Locale') || '';
      this.queryParams.SaveToDataVault = urlParams.get('SaveToDataVault') || '';
    }
  }
});
</script>
