<template>
  <div class="flex justify-center cursor-pointer">
    <div class="icon-selector">
      <div
        v-for="(item, index) in items"
        :key="index"
        class="item"
        @click="selectItem(index, true)"
      >
        <div class="flex flex-col items-center">
          <img :src="item.selected ? item.altIcon : item.icon" class="icon" />
          <p class="text" :class="{ selected: selectedIndex === index }">
            {{ addThreeDotsText(item.text) }}
          </p>
          <div
            class="indicator h-1 w-full"
            :class="{ selectIndicator: selectedIndex === index }"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import bookingIconSelected from "@/assets/images/activityBar/bookingIconSelected.svg";
import matchIconSelected from "@/assets/images/activityBar/matchIconSelected.svg";
import activityIconSelected from "@/assets/images/activityBar/activityIconSelected.svg";
import { BookingType, Category } from "@/enum/constants";
import { addThreeDotsText } from "@/helpers/dataHelper";
import { Store, useStore } from "vuex";

export default defineComponent({
  name: "ActivityBar",
  props: {
    optionDefault: {
      type: Number,
      default: 0,
      required: false,
    },
  },
  emits: ["optionSelected"],
  data() {
    return {
      selectedIndex: this.optionDefault,
      items: [
        {
          icon: bookingIconSelected,
          altIcon: bookingIconSelected,
          text: this.$t("booking"),
          selected: this.optionDefault === BookingType.BOOKING,
        },
        {
          icon: matchIconSelected,
          altIcon: matchIconSelected,
          text: this.$t("matches"),
          selected: this.optionDefault === BookingType.MATCH,
        },
        /*{ icon: activityIconSelected, altIcon: activityIconSelected, text: this.$t("activities"), selected: this.optionDefault === BookingType.ACTIVITY },*/
        /*{ icon: classIconSelected, altIcon: classIconSelected, text: "Class", selected: this.optionDefault === BookingType.CLASS }*/
      ],
      store: {} as Store<any>,
    };
  },
  mounted() {
    this.store = useStore();
    let action = this.store.state.preferences.action //get category from user config
      ? this.store.state.preferences.action
      : Category.BOOKING;
    if(this.$route.query.category){
      action = this.$route.query.category; //set preference if category in query
    }
    this.selectItem(Number(action), false);
  },
  methods: {
    addThreeDotsText,
    selectItem(index: number, emit:boolean) {
      // Deselect all items
      this.items.forEach((item) => (item.selected = false));
      // Select the clicked item
      this.items[index].selected = true;
      this.selectedIndex = index;
      if(emit){
        this.$emit("optionSelected", this.selectedIndex);
      }
    },
  },
});
</script>
<style scoped>
.icon-selector {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
@media (max-width: 375px) {
  .item {
    width: 80px !important;
  }
}
.item {
  text-align: center;
  width: 140px;
}

.icon,
.line-icon {
  width: 23px;
  height: 23px;
}

.text {
  color: #999999;
}

.icon-selector :hover .icon,
.icon-selector :hover .text {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%)
    hue-rotate(0deg) brightness(100%) contrast(100%);
}

.indicator {
  position: relative;
  background: #2b4164;
  border-radius: 30px;
  overflow: hidden; /* Ensure pseudo-element stays within bounds */
}

.indicator::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    103.18deg,
    var(--color1) 3.29%,
    var(--color2) 97.77%
  );
  transition: opacity 0.8s ease-in-out;
  opacity: 0; /* Start with invisible gradient */
}

.selectIndicator::before {
  opacity: 1; /* Make gradient visible on hover or when active */
}
</style>