import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "custom-gradient mb-1 text-lg" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isBooking)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.name), 1)
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("p", null, _toDisplayString(_ctx.typeName +" | "+ _ctx.subTypeName +" | "+_ctx.sizeName), 1)
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_3, [
        (_ctx.writeName)
          ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.name +" | "+ _ctx.typeName +" | "+ _ctx.subTypeName +" | "+_ctx.sizeName), 1))
          : (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.typeName +" | "+ _ctx.subTypeName +" | "+_ctx.sizeName), 1))
      ]))
}