<template>
  <span></span>
</template>
<script lang="ts">
import { defineComponent, ref } from "vue";
import { PaymentApi } from "@/service/PaymentApi";
import { StartTransbankRequest } from "models/start-transbank-request";
import { PaymentProvider} from "@/enum/constants";

export default defineComponent({
  name: "TransbankForm",
  props: ["data", "cardId", "user"],
  async mounted() {
    let orderId = "";
    if(this.data.paymentProviderData.properties.orderId){
      orderId = this.data.paymentProviderData.properties.orderId;
    }
    const payload:StartTransbankRequest = {
      orderId: orderId,
      returnURL: process.env.VUE_APP_URL + "payment-redirect/" + PaymentProvider.TRANSBANK + "/" + this.data.id, 
      amount: this.data.amountData.grossAmount
    }
    const response = await PaymentApi.startTransaction(payload);
    window.location.href = response.data.redirectUrl;
  },
});
</script>