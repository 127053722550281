<template>
  <div v-if="userlogged"
    class="absolute fav-icon p-2 cursor-pointer"
    @click="onClick"
  >
    <SvgContainer name="imageSelectButtonRed" v-if="isLiked" />
    <SvgContainer name="imageSelectButton" v-else />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import SvgContainer from "@/views/SvgContainer.vue";
import CustomerHelper from "@/helpers/customerHelper";

export default defineComponent({
  name: 'ILikeButton',
  components:{
    SvgContainer
  },
  props: {
    tenantId: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      isLiked: false,
      userlogged: false
    }
  },
  created() {
    this.userlogged = CustomerHelper.isUserLogged();
  },
  mounted() {
    if (this.userlogged) {
      const preferences = CustomerHelper.getStoragePreferences();
      if (preferences && preferences.tenants) {
        let favoriteTenants = preferences.tenants;
        this.isLiked = favoriteTenants.some(
          (tenant: { tenantId: string; }) => tenant.tenantId === this.tenantId
        );
      }
    }
  },
  methods: {
    async onClick(e: Event) {
      e.preventDefault();
      e.stopPropagation();
      let preferences = null;
      if (this.isLiked) {
        preferences = await CustomerHelper.deleteTenantFromCustomerFavorites(this.tenantId);
        this.isLiked = false;
      } else {
        preferences = await CustomerHelper.addTenantToCustomerFavorites(this.tenantId);
        this.isLiked = true;
      }
      CustomerHelper.updateStoragePreferences(preferences);
    },
    
  }
});
</script>
<style scoped>
  .fav-icon {
    top: 14px;
    right: 30px;
    border-radius: 50%;
    background: rgba(20, 40, 74, 0.2);
    backdrop-filter: blur(4px);
  }
</style>