<template>
    <div class="Wrapper404 flex justify-center flex-col items-center sm:w-full sm:p-6">
        <div class="relative">
            <svg class="cactusL absolute" width="32" height="23" viewBox="0 0 32 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 10.8609C0 8.39095 2.00228 6.38867 4.47222 6.38867C6.94216 6.38867 8.94444 8.39095 8.94444 10.8609V22.9998H0V10.8609Z" fill="#6BC77C"/>
                <path d="M11.5 4.47222C11.5 2.00228 13.5023 0 15.9722 0V0C18.4422 0 20.4444 2.00228 20.4444 4.47222V23H11.5V4.47222Z" fill="#6BC77C"/>
                <path d="M23 13.4168C23 10.9469 25.0023 8.94458 27.4722 8.94458C29.9422 8.94458 31.9444 10.9469 31.9444 13.4168V23.0001H23V13.4168Z" fill="#6BC77C"/>
            </svg>
            <img src="../assets/images/Logo404.svg" alt="">
            <svg class="cactusR absolute" width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.4445 6.61114C19.4445 5.1077 18.2257 3.88892 16.7222 3.88892C15.2188 3.88892 14 5.1077 14 6.61114V14H19.4445V6.61114Z" fill="#6BC77C"/>
                <path d="M12.4445 2.72222C12.4445 1.21878 11.2257 0 9.72224 0C8.21879 0 7.00001 1.21878 7.00001 2.72222V14H12.4445V2.72222Z" fill="#6BC77C"/>
                <path d="M5.44446 8.16656C5.44446 6.66312 4.22568 5.44434 2.72224 5.44434C1.21879 5.44434 1.33514e-05 6.66312 1.33514e-05 8.16656V13.9999H5.44446V8.16656Z" fill="#6BC77C"/>
            </svg>
        </div>
        <p class="mt-6 text-center text-3xl">{{ $t("something_gone_wrong") }}</p>
        <p class="mt-6 text-center opacity md:w-6/12">{{ $t("page_does_not_exist") }}</p>
        <router-link to="/" class=" w-full flex justify-center buttomPayMode mt-8  cursor-pointer">
            <div>{{ $t("back_to_home") }}</div>
        </router-link>
    </div>
</template>

<style scoped>
.Wrapper404{
    align-items: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-60%);
}

.cactusR {
    bottom: 1px;
    right: 30px;
}
.cactusL {
    bottom: 1px;
    left: 30px;
}
.buttomPayMode {
    border-radius: 32px;
    padding: 14px 0;
    background-image: linear-gradient(to right, var(--color1), var(--color2));
    margin-left: auto;
    margin-right: auto;
}
.Wrapper404 {
    max-width: 420px;
}
.opacity {
    opacity: 0.6;
}
</style>