<template>
  <div class="flex flex-wrap paddig-responsive">
    <div class="flex w-full text-left center-responsive">
      <h2>{{ $t("matchs_played_last_six_month_per_week_question", { sport: getSportLabel(Sport.PADBOL)}) }}</h2>
    </div>
    <div class="flex w-full mt-8">
      <div class="flex flex-col">
        <template v-for="option in options" :key="option.id">
          <label class="flex items-center mb-2">
            <input type="radio" :value="option.value" v-model="matchPlayed" @change="showValue()" class="mr-2">
            <span>{{ option.label }}</span>
          </label>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { SelectOption } from "@/custom-models/levelingSelector";
import { getSportLabel } from "@/helpers/dataHelper";
import { Sport } from "@/enum/constants";
  
export default defineComponent({
  name: 'MatchSelector',
  props: {
    propMatchPlayed:{ 
      type: Number,
      default: 0.1 
    }
  },
  created (){
    this.matchPlayed = this.propMatchPlayed;
  },
  data() {
    return {
      matchPlayed: 0.1,
      Sport:Sport,
      options: [
        { id: 1, label: this.$t("1"), value: 0.1 },
        { id: 2, label: this.$t("2"), value: 0.2 },
        { id: 3, label: this.$t("3_or_more"), value: 0.3 },
      ] as SelectOption[]
    };
  },
  methods: {
    getSportLabel,
    showValue() {
      this.$emit("selectedMatchPlayed", this.matchPlayed);
    }
  }
});
</script>