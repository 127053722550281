<template>
  <span> </span>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "preloadView",
  components: {},
  data() {
    return {};
  },

  async mounted() {
    setTimeout(() => {
      this.$router.push(
        "/checkout-payment/" +
          this.$route.params.saleId +
          "/" +
          this.$route.params.cancellationLimitDate
      );
    }, 200);
  },
});
</script>
