<template>
  <div class="flex flex-col items-center">
    <div class="flex flex-wrap items-center justify-center">
      <div v-for="activity in activities" :key="activity.id" class="max-w-sm m-2 flex flex-col justify-between overflow-hidden">
        <activityCard 
          :activity="activity"
          :isDetail=false
          @click="activitySelected(activity, $event)"
        />
      </div>
    </div>
  </div>
  <Transition>
    <PopUpLight
      v-if="showActivityPopup"
      :showOkButton="true"
      @closed="showActivityPopup = false"
    >
      <div class="flex p-6 justify-around">
        <activityCard
          :activity="activity"
          :isDetail="true"
          @selfClose="handleActivityDetailClose"
        />
      </div>
    </PopUpLight>
  </Transition>
</template>


<script lang="ts">
import { defineComponent } from "vue";
import { formatDate, beautifyDateWithOutTime, formatDateWithLocale } from "@/helpers/dateHelper";
import PopUpLight from "@/components/popup/popupLight.vue";
import { Price, Tenant } from "models";
import { addThreeDotsText } from "@/helpers/dataHelper";
import  activityCard  from "@/components/booking/activityCard.vue"
import { Activity } from "models";

export default defineComponent({
  name: "activityAvailability",
  props: {
    activities: {
      type: Array as () => Activity[],
      default: () => [],
      required: true,
    }
  },
  emits: ["matchSelected", "createMatch", "bookingCancelled"],
  components: {
    PopUpLight,
    activityCard
  },
  computed: {
    isActivityValid(): boolean {
      // Check if activity is not null and not an empty object
      return this.activity && Object.keys(this.activity).length > 0;
    },
  },
  data() {
    return {
      showActivityPopup: false,
      activity: {} as Activity,
    };
  },
  methods: {
    addThreeDotsText,
    beautifyDateWithOutTime,
    formatDate,
    formatDateWithLocale,
    handleActivityDetailClose(){
      this.showActivityPopup = false;
    },
    activitySelected(activity:Activity, e: MouseEvent): void {
      e.preventDefault();
      e.stopPropagation();
      this.activity = activity;
      this.showActivityPopup = true;
    },
  },
});
</script>
<style scoped>
</style>
