import { Format } from "@/helpers/formatHelper";
import { decodeString } from "@/helpers/dataHelper";
export class AppsHelper {

    static isSafari() {
        return /^((?!chrome|android).)*safari/i.test(
            navigator.userAgent
        );
    }
    static isAndroidWebView() {
        return /Android/.test(navigator.userAgent) && /Version\/[\d.]+/.test(navigator.userAgent);
    }

    static isIOS(){
        const userAgent = window.navigator.userAgent;
        const iOS = /iPad|iPhone|iPod/.test(userAgent);
        return iOS;
    }

    static isIOSWebView() {
        const webview = localStorage.getItem("webview");
        if(webview) {
            return true;
        }
        return false;
    }

    static isSafariOrIosWebView() {
        return AppsHelper.isSafari() || AppsHelper.isIOSWebView();
    }

    static isWhiteTheme(route:any) {
        const whiteQueryParam = route.white;
        const isWhiteTheme = whiteQueryParam === '1';
        if(isWhiteTheme){
            return true;
        }
        return false;
    }

    static sendToIOS(window: any, jsonData: any) {
        if(this.isIOSWebView()){
            const fakewindow: any = window;
            if ("webkit" in window && "messageHandlers" in fakewindow.webkit) {
                fakewindow.webkit.messageHandlers.jsHandler.postMessage(JSON.stringify(jsonData), '*');//todo: para produccion deberiamos poner el origen 'https://taykus.com' en vez de * para segurar de que es nuestro dominio
                return true;
            } else {
                console.log("webkit.messageHandlers not supported in this browser.");
            }
        }
    }

    static isWebView() {
        return AppsHelper.isAndroidWebView() || AppsHelper.isIOSWebView();
    }
}

export function isDeviceVeryOld() {
    return Format.IsNull(navigator) || Format.IsNull(navigator.clipboard);
}

export function callWebviewPaymentCompleteMethod(result: boolean, errorMsg:string) {
    if (AppsHelper.isWebView()) {
      if(AppsHelper.isIOSWebView()){
        const jsonData = {
            paymentSuccess: result,
            errorMsg: decodeString(errorMsg),
        };
        return AppsHelper.sendToIOS(window, jsonData);
      }
      const w = window as any;
      w.Android.onPaymentCompleted(result, decodeString(errorMsg));
      return;
    }
  }