<template>
  <div class="WrapperPrivacy mx-auto max-w-2xl overflow-auto flex justify-center flex-col items-center sm:w-full sm:p-6" :class="{ whiteTheme: isWhiteTheme }">
    <p class="mt-6 text-center text-3xl">{{ $t("private_policy") }}</p>
    <p class="mt-6 text-justify tp-4" v-html="$t('privacy_policy_full_text')"></p>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { AppsHelper } from "@/helpers/appsHelper";
import { WHITE_THEME_BACKGROUND_COLOR } from "@/enum/constants";
export default defineComponent({
  name: "legalView",
  data() {
    return {
      isWhiteTheme: false as boolean
    };
  },
  mounted(){
    this.isWhiteTheme = AppsHelper.isWhiteTheme(this.$route.query);
    if(this.isWhiteTheme){
      const bodyElement = document.getElementsByTagName("body")[0];
      bodyElement.style.backgroundImage = 'none';
      bodyElement.style.backgroundColor = WHITE_THEME_BACKGROUND_COLOR;
    }
  }
})
</script>
<style scoped>
.WrapperPrivacy {
    max-width: 620px;
}
.opacity {
    opacity: 0.6;
}
</style>